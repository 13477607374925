import { Box, Fade, Modal, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import React, { useState } from 'react'
import styles from '../styles/AddPlayersModal.module.css';
import axios from 'axios';
import { useContext } from 'react';
import { LoadingContext } from '../App';

const AddPlayersModal = ({getAllScheduledGames,row,getGamePlayers,setCsvData,csvData,openAddPlayersModal,setOpenAddPlayersModal}) => {


    const {setIsLoading} = useContext(LoadingContext);
    const handleCloseAddPlayersModal = () => {
        setCsvData([]);
        document.getElementById('csvfpl').value = '';
        setOpenAddPlayersModal(false);
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };

    const boxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "70%",
        backgroundColor: "#FFFFFF",
        border: "2px solid #000",
        boxShadow: 24,
        outline: "none",
        p: 4,
    };

    const columns = [{
        id: 'Name', label: 'Name'
    },
    {
        id: 'Email', label: 'Email'
    },
    {
        id: 'Mobile', label: 'Mobile'
    }]

    const addPlayers = async () => {
    
      let temp = [];
      csvData.forEach((item,i)=>{
        let obj = {
          game: row.id,
          name: item.Name,
          email: item.Email,
          mobile: item.Mobile
        }
        temp.push(obj);
      })

      setIsLoading(true);
      await axios.post(`${process.env.REACT_APP_API_URL}players/create`,temp)
      .then((response)=>{
        handleCloseAddPlayersModal();
        getGamePlayers();
        getAllScheduledGames();
      })
      .catch((err)=>{
        console.log(err)
      });
      setIsLoading(false);
  }

  return (
    <Modal
        open={openAddPlayersModal}
        onClose={handleCloseAddPlayersModal}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAddPlayersModal}>
          <Box sx={boxStyle}>
            <Typography
              variant="h4"
              component="h4"
              textAlign={"center"}
              fontWeight={"bold"}
              letterSpacing={"3px"}
              fontFamily={"Fjalla"}
              sx={{ marginBottom: "5px" }}
            >
              ADD PLAYERS
            </Typography>
            <div className={styles.belowInfo}>Verify all the players below and then add them to the game.</div>
            <Paper sx={{ width: "90%",marginLeft:'5%',marginTop: '30px',marginBottom: '30px', overflow: "hidden" }}>
              <TableContainer >
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column, i) => (
                        <TableCell
                          key={i}
                          style={{
                            fontFamily: "Poppins",
                            letterSpacing: "1px",
                            fontWeight: "bold",
                            textAlign: "center",
                            backgroundColor: "#4C4CB7",
                            color: "white",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  {csvData
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, i) => {
                      return (
                        <TableRow key={i} hover tabIndex={-1}>
                        {columns.map((column, j) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={j}
                              align={"center"}
                              style={{
                                fontFamily: "Poppins",
                                textAlign: "center",
                                position: "relative",
                              }}
                            >{value}</TableCell>
                          )})}
                          </TableRow>
                      );
                    })}
                </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={csvData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <div onClick={addPlayers} className={styles.butts}>Add</div>
            <div onClick={handleCloseAddPlayersModal} className={styles.butts}>Cancel</div>
          </Box>
        </Fade>
      </Modal>
  )
}

export default AddPlayersModal