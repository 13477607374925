import { COMPANY_NAME_HEADING_FONTSIZE, COMPANY_NAME_HEADING_FONTSIZE2, GAME_NAME_HEADING_FONTSIZE, LIGHTSOUT_HEIGHT, LIGHTSOUT_WIDTH } from "../helpers/dimensions";
import { BaseScene } from "./BaseScene";
import { getRoomStartTime } from "../apis/get";
import { Dice } from "../models/Dice";
import eventsCenter from "../helpers/eventsCenter";

export class WaitingScene extends BaseScene
{
    constructor (config)
    {
        super('WaitingScene',config);
        this.pulsating = false;
    }

    async create() {
        this.menu = await getRoomStartTime();
        // console.log(this.menu)

        this.timeDiff = this.menu?.data.start_timer * 1000;
        this.add.image(this.config.width / 2,this.config.height / 2, 'menuBackground').setOrigin(0.5,0.5).setDisplaySize(this.config.width,this.config.height);
        this.darkness = this.add.image(0,0,'darkness').setDisplaySize(this.config.width,this.config.height).setOrigin(0,0).setAlpha(0).setDepth(100).setInteractive({cursor: 'default'});
        this.createInputField();
        this.createTitle();
        this.createLoadingDice();
        this.countDown = setInterval(() => this.timeRemaining(this),1000);

        if(this.menu.data.started) {
            this.startAThon();
        } else {
            if(this.menu.data.start_timer <= 0) {
                this.projectDice.destroy(true);
                this.uncertaintyDice.destroy(true);
                this.inputLabel.setText("STARTING...");
                this.createLights()
                clearInterval(this.countDown);
            }
        }
        

        eventsCenter.on("start",()=>{
            this.projectDice.destroy(true);
            this.uncertaintyDice.destroy(true);
            this.inputLabel.setText("STARTING...");
            this.createLights()
            clearInterval(this.countDown);
        });

        eventsCenter.on("countdown",(data)=>{
            if(data === 1) {
                this.finalSound.play();
            } else {
                this.normalSound.play();
            }
            this.startLights.setFrame(5 - data);
        });

        eventsCenter.on("start-a-thon",()=>{
            this.startAThon();
        });

        
    }

    update(time,delta) {
        if(this.projectDice?.isRolling) {
            let f = (delta  / (1000 / 60)) / 10;
            this.projectDice.diceMesh.modelRotation.x -= f;
            this.projectDice.diceMesh.modelRotation.y -= f;
        }
        if(this.uncertaintyDice?.isRolling) {
            let f = (delta  / (1000 / 60)) / 10;
            this.uncertaintyDice.diceMesh.modelRotation.x += f;
            this.uncertaintyDice.diceMesh.modelRotation.y += f;
        }
    }

    createLights() {
        this.startLights = this.add.image(this.config.width / 2, this.config.height / 2,'lights',this.lightNum).setDepth(102).setAlpha(1).setDisplaySize(LIGHTSOUT_WIDTH,LIGHTSOUT_HEIGHT);
        this.darkness.setAlpha(0.6);
        this.normalSound = this.sound.add('normal');
        this.finalSound = this.sound.add('final');  
    }

    createTitle() {
        this.title = this.add.text(this.config.width / 2, this.config.height / 2 + 10 * devicePixelRatio,this.menu?.data.title.toUpperCase(),{padding:{right: 4},shadow:{offsetX: 2,offsetY: 2,fill:true,blur:3},fontStyle: '1000',fontFamily: 'UEFA',fontSize: `${COMPANY_NAME_HEADING_FONTSIZE2}px`}).setOrigin(0.5,0);
        this.nameTitle = this.add.text(this.config.width / 2, this.config.height / 2 - 90*devicePixelRatio,`WELCOME , ${this.menu?.data.name.toUpperCase()} !`,{color:  '#313e56',padding:{right: 4},shadow:{offsetX: 2,offsetY: 2,fill:true,blur:1},fontStyle: '1000',fontFamily: 'UEFA',fontSize: `${GAME_NAME_HEADING_FONTSIZE}px`}).setOrigin(0.5,0);
    }

    createInputField() {
        this.inputLabel = this.add.text(this.config.width / 2, this.config.height / 2 + 120*devicePixelRatio,'LOADING...',{padding:{right: 4},shadow:{offsetX: 2,offsetY: 2,fill:true,blur:3},fontStyle: '1000',fontFamily: 'Night',fontSize: `${COMPANY_NAME_HEADING_FONTSIZE}px`}).setOrigin(0.5,0);
    }

    createLoadingDice() {
        this.projectDice = new Dice({playSceneConfig: this.config,scene: this,diceName: 'projectDice'});
        this.uncertaintyDice = new Dice({playSceneConfig: this.config,scene: this,diceName: 'marOppHurDice'});
        this.projectDice.throwDice();
        this.uncertaintyDice.throwDice();
    }



    timeRemaining(self) {
        // Calculate the difference in milliseconds
        this.timeDiff -= 1000;
        let diff = this.timeDiff;
        // If the time difference is negative, return "00:00:00"
        if (diff <= 0) {
            clearInterval(this.countDown)
        }
        
        else {
            // Calculate hours, minutes and seconds
            const hours = Math.floor(diff / (1000 * 60 * 60));
            diff -= hours * (1000 * 60 * 60);
            const minutes = Math.floor(diff / (1000 * 60));
            diff -= minutes * (1000 * 60);
            const seconds = Math.floor(diff / 1000);
        
            // Format the time components to two digits
            const formattedHours = String(hours).padStart(2, '0');
            const formattedMinutes = String(minutes).padStart(2, '0');
            const formattedSeconds = String(seconds).padStart(2, '0');
        
            // Return the formatted time string
            self.inputLabel.setText(`GAME STARTS IN - ${formattedHours}:${formattedMinutes}:${formattedSeconds}`);
        }

    }

    startAThon() {
        this.scene.start('PlayScene',this.menu.data)
    }
}