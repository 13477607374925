import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Back from "../components/Back";
import {
  Box,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Typography,
} from "@mui/material";
import styles from "../styles/AdminFunctions.module.css";
import plus from "../assets/plus.png";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import AdminFunctionsModal from "../components/AdminFunctionModal";
import { LoadingContext } from "../App";
import { Delete, Edit, LockReset } from "@mui/icons-material";

const boxStyle = {
  width: "80%",
  backgroundColor: "white",
  boxShadow: 24,
  marginTop: "50px",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, 10%)",
  borderRadius: "10px",
  p: 4,
};

const boxStyle2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  backgroundColor: "#FFFFFF",
  border: "2px solid #000",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const AdminFunctions = () => {

  const {setIsLoading} = useContext(LoadingContext);

  const columns = [
    { id: "id", label: "ID" },
    { id: "role", label: "Role" },
    {
      id: "name",
      label: "Name",
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "mobile",
      label: "Mobile",
    },
    {
      id: "edit",
      label: "Edit",
    },
    {
      id: "resetPassword",
      label: "Password",
    },
    {
      id: "delete",
      label: "Delete",
    },
  ];

  const [sortOption, setSortOption] = useState("Role");
  const [allUsers, setAllUsers] = useState([]);

  const getUsers = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}users/all`)
      .then((response) => {

        setAllUsers(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  const deleteAdmin = async () => {

    let obj = {id: selectedAdminDetails.id};
    handleCloseDeleteModal();
    setIsLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_API_URL}users/delete`,{
        data: obj
      })
      .then((response) => {

        getUsers();
        
      })
      .catch((err) => {
        console.log(err);
      });
      setIsLoading(false);
  };

  const sendResetLink = async () => {
    let obj = {id : selectedAdminDetails.id, name: selectedAdminDetails.name, email: selectedAdminDetails.email};
    handleCloseResetModal();
    setIsLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}users/sendResetLink`,obj)
      .then((response) => {

      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getUsers();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openAdminModal, setOpenAdminModal] = useState(false);
  const [adding, setAdding] = useState();
  const [selectedAdminDetails, setSelectedAdminDetails] = useState({
    id: "",
    name: "",
    email: "",
    mobile: "",
    role: "",
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const sorts = ["Name", "Role", "Email", "Mobile"];

  const handleSorting = (event) => {
    let key = event.target.value.toLowerCase();

    allUsers.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      if (x < y) {
        return -1;
      }
      if (x > y) {
        return 1;
      }
      return 0;
    });

    setSortOption(event.target.value);
  };

  const handleModalOpening = (add, deets) => {
    setSelectedAdminDetails(deets);
    setAdding(add);
    setOpenAdminModal(true);
  };

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpenDeleteModal = (deets) => {
    setSelectedAdminDetails(deets);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const [openResetModal, setOpenResetModal] = useState(false);

  const handleOpenResetModal = (deets) => {
    setSelectedAdminDetails(deets);
    setOpenResetModal(true);
  };

  const handleCloseResetModal = () => {
    setOpenResetModal(false);
  };

  return (
    <>
      <Navbar />
      <AdminFunctionsModal
        openAdminModal={openAdminModal}
        setOpenAdminModal={setOpenAdminModal}
        details={selectedAdminDetails}
        adding={adding}
        getUsers={getUsers}
      />
      <Modal
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openDeleteModal}>
          <Box sx={boxStyle2}>
            <Typography
              variant="h5"
              component="h5"
              textAlign={"center"}
              fontWeight={"bold"}
              letterSpacing={"2px"}
              fontFamily={"Fjalla"}
              sx={{ marginBottom: "5px" }}
            >
              Are you sure you want to remove admin privileges of{" "}
              <span style={{ color: "red" }}>{selectedAdminDetails.name}</span>{" "}
              ?
            </Typography>
            <div onClick={deleteAdmin} className={styles.butts}>
              Confirm
            </div>
            <div onClick={handleCloseDeleteModal} className={styles.butts}>
              Cancel
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        open={openResetModal}
        onClose={handleCloseResetModal}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openResetModal}>
          <Box sx={boxStyle2}>
            <Typography
              variant="h5"
              component="h5"
              textAlign={"center"}
              fontWeight={"bold"}
              letterSpacing={"2px"}
              fontFamily={"Fjalla"}
              sx={{ marginBottom: "5px" }}
            >
              Reset Password of{" "}
              <span style={{ color: "red" }}>{selectedAdminDetails.name}</span>{" "}
              ?
            </Typography>
            <div onClick={sendResetLink} className={styles.butts}>
              Send Reset Password Link
            </div>
            <div onClick={handleCloseResetModal} className={styles.butts}>
              Cancel
            </div>
          </Box>
        </Fade>
      </Modal>
      <div className={styles.mainAdminFunctions}>
        <Back />
        <Box sx={boxStyle}>
          <Typography
            variant="h4"
            component="h4"
            textAlign={"center"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
            fontFamily={"Fjalla"}
            sx={{ marginBottom: "5px" }}
          >
            ADMIN FUNCTIONS
          </Typography>
          <div className={styles.belowInfo}>
            Only Super Admins have privileges for the actions below.
          </div>
          <div className={styles.topBar}>
            <FormControl sx={{ width: "20%", marginRight: "5%" }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  transition: "all 0.2s",
                  letterSpacing: "2px",
                }}
              >
                Sort By
              </InputLabel>
              <Select
                value={sortOption}
                label="Sort By"
                onChange={handleSorting}
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: 600,
                  transition: "all 0.2s",
                  letterSpacing: "1px",
                  textAlign: "center",
                }}
              >
                {sorts.map((item, i) => {
                  return (
                    <MenuItem
                      key={i}
                      value={item}
                      align="center"
                      sx={{
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        transition: "all 0.2s",
                        letterSpacing: "1px",
                      }}
                    >
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div
              className={styles.mainAdd}
              onClick={() =>
                handleModalOpening(true, {
                  id: "",
                  name: "",
                  email: "",
                  mobile: "",
                  role: "",
                })
              }
            >
              <img className={styles.addImg} src={plus} alt="New" />
              <div className={styles.addText}>ADD ADMIN</div>
            </div>
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={i}
                        style={{
                          fontFamily: "Poppins",
                          letterSpacing: "1px",
                          fontWeight: "bold",
                          textAlign: "center",
                          backgroundColor: "#B33951",
                          color: "white",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allUsers
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, i) => {
                      return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={i}>
                          {columns.map((column, j) => {
                            const value = row[column.id];
                            return (
                              <TableCell
                                key={j}
                                align={"center"}
                                style={{
                                  fontFamily: "Poppins",
                                  textAlign: "center",
                                  position: "relative",
                                }}
                              >
                                {column.id === "edit" ? (
                                  <IconButton onClick={() => handleModalOpening(false, row)} aria-label="delete">
                                    <Edit/>
                                  </IconButton>
                                ) : column.id === "delete" ? (
                                  <IconButton onClick={() => handleOpenDeleteModal(row)} aria-label="delete">
                                    <Delete />
                                  </IconButton>
                                ) : column.id === "resetPassword" ? (
                                  <IconButton onClick={() => handleOpenResetModal(row)} aria-label="delete">
                                    <LockReset/>
                                  </IconButton>
                                ) : (
                                  value
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={allUsers?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </>
  );
};

export default AdminFunctions;
