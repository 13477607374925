import React from "react";
import Slider from "react-slick";
import styles from "../styles/Tutorial.module.css";
import { Box, IconButton } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArrowBackIos } from "@mui/icons-material";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import imgs from "../assets/logo2.png";
import Back2 from "./Back2";

const Tutorial = ({setChosenOption}) => {
  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    backgroundColor: "#FFFFFF",
    border: "2px solid #000",
    boxShadow: 24,
    outline: "none",
    p: 5,
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };
  const slider = React.useRef(null);

  return (
    <>
      <Box sx={boxStyle}>
        <Back2 setChosenOption={setChosenOption}/>
        <Slider ref={slider} {...settings}>
          {[1, 2, 3, 4].map((points, i) => {
            return (
              <div key={i}>
                <div className={styles.topHead}>Title {i + 1}</div>
                <div className={styles.allCards}>
                  <IconButton
                    sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    onClick={() => slider?.current?.slickPrev()}
                    className={styles.prev}
                  >
                    <ArrowBackIos sx={{ width: "50px", height: "90px" }} />
                  </IconButton>
                  <div className={styles.mainImgContainer}>
                    <img
                      className={styles.mainImg}
                      src={imgs}
                      alt="Tutorial Slide"
                    />
                  </div>
                  <IconButton
                    sx={{ "&:hover": { backgroundColor: "transparent" } }}
                    onClick={() => slider?.current?.slickNext()}
                    className={styles.prev}
                  >
                    <ArrowForwardIos sx={{ width: "50px", height: "90px" }} />
                  </IconButton>
                </div>
              </div>
            );
          })}
        </Slider>
        <div className={styles.belowSlider}>Sample Bottom Text</div>
        <div className={styles.belowSliderBelow}>Sample Bottom Sub Text</div>
      </Box>
    </>
  );
};

export default Tutorial;
