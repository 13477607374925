import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import styles from "../styles/ScheduledRooms.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { LoadingContext, SocketContext } from "../App";
import CollapsibleScheduledRow from "./CollapsibleScheduledRow";

const boxStyle = {
  width: "95%",
  marginLeft: "2.5%",
  backgroundColor: "white",
  boxShadow: 24,
  marginTop: "50px",
  marginBottom: "50px",
  borderRadius: "10px",
  boxSizing: "border-box",
  p: 4,
};

const ScheduledRooms = () => {

  const {socket} = useContext(SocketContext);

  const { setIsLoading } = useContext(LoadingContext);
  const [open, setOpen] = useState("");

  const columns = [
    {id: "#", label: "#"},
    { id: "title", label: "Title" },
    {
      id: "start_time",
      label: "Date",
    },
    {
      id: "players",
      label: "Players",
    },
    {
      id: "creator_name",
      label: "Created By",
    },
    {
      id: "progress",
      label: "Progress",
    },
    {
      id: 'delete',
      label: 'Delete'
    }
  ];

  const [allScheduledGames, setAllScheduledGames] = useState([]);

  const getAllScheduledGames = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}games/scheduled`)
      .then((response) => {
        setAllScheduledGames(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    socket.on("adminUpdate",()=>{
      getAllScheduledGames();
    })
    getAllScheduledGames();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [openAdminModal, setOpenAdminModal] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <>
      <div className={styles.mainAdminFunctions}>
        <Box sx={boxStyle}>
          <Typography
            variant="h4"
            component="h4"
            textAlign={"center"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
            fontFamily={"Fjalla"}
            sx={{ marginBottom: "5px" }}
          >
            SCHEDULED GAMES
          </Typography>
          <div className={styles.belowInfo}>
            The games in the table below are yet to start.
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={i}
                        style={{
                          fontFamily: "Poppins",
                          letterSpacing: "1px",
                          fontWeight: "bold",
                          textAlign: "center",
                          backgroundColor: "#B33951",
                          color: "white",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allScheduledGames
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, i) => {
                      return (
                          <CollapsibleScheduledRow open={open} setOpen={setOpen} getAllScheduledGames={getAllScheduledGames} key={i} columns={columns} row={row}/>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={allScheduledGames?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </>
  );
};

export default ScheduledRooms;
