import React from 'react'
import { useNavigate } from 'react-router-dom'
import styles from '../styles/RoundedBox.module.css';

const RoundedBox = ({image,text,path}) => {

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(path)
    }

  return (
    <div onClick={handleClick} className={styles.mainBox}>
        <img className={styles.imgBox} src={image} alt={text}/>
        <div className={styles.textBox}>
            {text}
        </div>
    </div>
  )
}

export default RoundedBox