import { Box, Fade, Modal, Typography } from '@mui/material'
import React from 'react';
import styles from '../styles/ConfirmationModal.module.css';

const ConfirmationModal = ({text1,text2,highlight, openFn, closeFn, action}) => {

    const boxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        backgroundColor: "#FFFFFF",
        border: "2px solid #000",
        boxShadow: 24,
        outline: "none",
        p: 4,
        };

  return (
    <Modal
        open={openFn}
        onClose={closeFn}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openFn}>
          <Box sx={boxStyle}>
            <Typography
              variant="h5"
              component="h5"
              textAlign={"center"}
              fontWeight={"bold"}
              letterSpacing={"2px"}
              fontFamily={"Fjalla"}
              sx={{ marginBottom: "5px" }}
            >
              {text1}
              <span style={{ color: "red" }}>{highlight}</span>
              {text2}
            </Typography>
            <div onClick={action} className={styles.cnfrm}>
              Confirm
            </div>
            <div onClick={closeFn} className={styles.cnfrm}>
              Cancel
            </div>
          </Box>
        </Fade>
      </Modal>
  )
}

export default ConfirmationModal