export const calculateDenomination = (amt) => {
    return amt >= 100 ? `${amt / 100} CR` : `${amt} L`;
}

const computeNoteCount = (amt,denom,arr) => {
    let num = Math.floor(amt / denom);
    for(let i = 0; i < num;i++) {
        arr.push(denom)
    }
    return amt % denom;
}

export const getMoney = (amount) => {
    let amt = amount;
    let arr = [];
    if(amt >= 50) {
        amt = computeNoteCount(amt,50,arr);
    }
    if(amt >= 10) {
        amt = computeNoteCount(amt,10,arr);
    }
    if(amt >= 2) {
        amt = computeNoteCount(amt,2,arr);
    }
    if(amt >= 1) {
        amt = computeNoteCount(amt,1,arr);
    }
    if(amt >= 0.5) {
        amt = computeNoteCount(amt,0.5,arr);
    }

    return arr;
}

export const moneyFrameMap = {
    0.5 : 0,
    1 : 1,
    2 : 2,
    10 : 3,
    50 : 4,
}
