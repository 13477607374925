import styles from "../styles/NotFound.module.css";
import Letters from "../components/Letters";

const NotFound = ({text,subText}) => {

    return (
      <>
        <Letters />
        <div className={styles.main404Box}>
            <div className={styles.sub404Box}>
              <div className={styles.text404}>{text}</div>
              <div className={styles.belowInfo}>
                {subText}
              </div>
            </div>
        </div>
      </>
    );
};

export default NotFound;
