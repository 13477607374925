import Particles, { initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import React, { useEffect, useState } from 'react';
import config from "../particleConfig.json";

const Letters = React.memo(() => {

    const [init, setInit] = useState(false);

    useEffect(() => {
      initParticlesEngine(async (engine) => {
        await loadSlim(engine);
      }).then(() => {
        setInit(true);
      });
    }, []);

  return (
    <>
        {init && (
            <Particles
                id="tsparticles"
                options={config}
            />
        )}
    </>
    
  )
})

export default Letters