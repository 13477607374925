import { Box, Fade, Modal, TextField, Typography } from '@mui/material';
import React, { useState } from 'react'
import styles from '../styles/AddSinglePlayerModal.module.css';
import axios from 'axios';
import { useContext } from 'react';
import { LoadingContext } from '../App';
import validator from 'validator';

const AddSinglePlayerModal = ({getAllScheduledGames,row,getGamePlayers,openSPModal,setOpenSPModal}) => {

    const {setIsLoading} = useContext(LoadingContext);
    const [playerDetails,setPlayerDetails] = useState({
        name: '',
        email: '',
        mobile: '',
        game: row.id
    });

    const [emailError,setEmailError] = useState();
    const [nameError,setNameError] = useState();
    const [mobileError,setMobileError] = useState();
    
    const handleCloseSPModal = () => {
        setPlayerDetails({
            name: '',
            email: '',
            mobile: '',
            game: row.id
        })
        setOpenSPModal(false);
    };

    const handleChange = (e) => {
        let temp = { ...playerDetails };
        temp[e.target.id] = e.target.value;
        setPlayerDetails(temp);

        if(e.target.id === "mobile") {
          setMobileError(false);
        } else if(e.target.id === "name") {
          setNameError(false);
        } else if(e.target.id === "email") {
          setEmailError(false);
        }
    };

    const boxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        backgroundColor: "#FFFFFF",
        border: "2px solid #000",
        boxShadow: 24,
        outline: "none",
        p: 4,
    };

    const validateNums = () => {
      let ver = playerDetails.mobile.length === 10 ? true : false;
      setMobileError(!ver);
      return ver
    } 

    const validateEmail = () => {
      let ver = validator.isEmail(playerDetails.email);
      setEmailError(!ver)
      return ver;
    }

    const validateName = () => {
      let ver = !validator.isEmpty(playerDetails.name);
      setNameError(!ver)
      return ver;
    }

    const addPlayer = async () => {

      let mb = validateNums();
      let em = validateEmail();
      let nm = validateName();

      if(mb && em && nm) {
        const temp = [];
      const obj = {...playerDetails};
      temp.push(obj);
      
      handleCloseSPModal();
      setIsLoading(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}players/create`,temp)
        .then((response) => {
          getAllScheduledGames();
          getGamePlayers(); 
        })
        .catch((err) => {
          console.log(err);
        });
      setIsLoading(true);
      }
    };

  return (
    <Modal
        open={openSPModal}
        onClose={handleCloseSPModal}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openSPModal}>
          <Box sx={boxStyle}>
            <Typography
              variant="h4"
              component="h4"
              textAlign={"center"}
              fontWeight={"bold"}
              letterSpacing={"3px"}
              fontFamily={"Fjalla"}
              sx={{ marginBottom: "5px" }}
            >
              ADD PLAYER
            </Typography>
            <div className={styles.belowInfo}>Please fill all the details for the new player.</div>
            <div className={styles.txtflds}>
                <TextField
                inputProps={{ style: { height: "20px" } }}
                value={playerDetails.name}
                onChange={handleChange}
                id="name"
                error={nameError}
                helperText={nameError && "Please enter a name"}
                margin="dense"
                type="text"
                label="Name"
                variant="outlined"
                sx={{marginBottom:"10px"}}
                />
                <TextField
                inputProps={{ style: { height: "20px" } }}
                value={playerDetails.email}
                onChange={handleChange}
                error={emailError}
                helperText={emailError && "Please enter a valid email address"}
                id="email"
                margin="dense"
                type="text"
                label="Email"
                variant="outlined"
                sx={{marginBottom:"10px"}}
                />
                <TextField
                    inputProps={{ style: { height: "20px" } }}
                    value={playerDetails.mobile}
                    error={mobileError}
                    helperText={mobileError && "Please enter a valid mobile number"}
                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                    onPaste={(e)=> { e.preventDefault();return false;}}
                    onChange={handleChange}
                    id="mobile"
                    margin="dense"
                    type="number"
                    label="Mobile"
                    variant="outlined"
                    sx={{marginBottom:"10px"}}
                />
            </div>
            <div onClick={addPlayer} className={styles.butts}>Add</div>
            <div onClick={handleCloseSPModal} className={styles.butts}>Cancel</div>
          </Box>
        </Fade>
      </Modal>
  )
}

export default AddSinglePlayerModal;