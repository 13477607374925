import axios from "axios";

export const updateOpportunity = (cardId) => {
  
  let temp = {id: cardId};
   axios
    .put(`${process.env.REACT_APP_API_URL}cards/updateOpportunity`,temp)
    .then((response) => {

    })
    .catch((err) => {
      console.log(err);
    });
};

export const receivedOpportunity = (cardId) => {
  
  let temp = {id: cardId};
   axios
    .put(`${process.env.REACT_APP_API_URL}cards/receivedOpportunity`,temp)
    .then((response) => {

    })
    .catch((err) => {
      console.log(err);
    });
};

export const abandonOpportunity = (cardId) => {
  
  let temp = {id: cardId};

   axios
    .put(`${process.env.REACT_APP_API_URL}cards/abandonOpportunity`,temp)
    .then((response) => {

    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateHurdle = (cardId) => {
  
    let temp = {id: cardId};
     axios
      .put(`${process.env.REACT_APP_API_URL}cards/updateHurdle`,temp)
      .then((response) => {

      })
      .catch((err) => {
        console.log(err);
      });
  };

  export const abandonHurdle = (cardId) => {
  
    let temp = {id: cardId};
     axios
      .put(`${process.env.REACT_APP_API_URL}cards/abandonHurdle`,temp)
      .then((response) => {

      })
      .catch((err) => {
        console.log(err);
      });
  };

  export const startProject = (cardId, type,cnt) => {
    
    let params = new URLSearchParams(window.location.search);

    let pt;

    switch(type) {
      case "express":
        pt = 1;
        break;

      case "flash":
        pt = 2;
        break;

      case "relaxed":
        pt = 3;
        break;
      
      default:
        pt = 0;
    }

    let temp = {card_id: cardId, game: params.get("game"),
        player: params.get("pl"), ptype: pt, count: cnt };
     axios
      .put(`${process.env.REACT_APP_API_URL}cards/startProject`,temp)
      .then((response) => {

      })
      .catch((err) => {
        console.log(err);
      });
  };

  export const alterProjects = async (timeVal) => {
    
    let params = new URLSearchParams(window.location.search);

    let temp = {game: params.get("game"),
        player: params.get("pl"), val: timeVal };
     await axios
      .put(`${process.env.REACT_APP_API_URL}cards/alterProjects`,temp)
      .then((response) => {

      })
      .catch((err) => {
        console.log(err);
      });
  };

  
  export const updateProject = (cardId) => {
    let params = new URLSearchParams(window.location.search);

    let temp = {id: cardId, game: params.get("game"),
        player: params.get("pl") };
     axios
      .put(`${process.env.REACT_APP_API_URL}cards/updateProject`,temp)
      .then((response) => {

      })
      .catch((err) => {
        console.log(err);
      });
  };

  export const abandonProject = (cardId) => {
  
    let temp = {id: cardId};

     axios
      .put(`${process.env.REACT_APP_API_URL}cards/abandonProject`,temp)
      .then((response) => {

      })
      .catch((err) => {
        console.log(err);
      });
  };

export const updateTokens = (id, player, ppl, conn) => {

  let temp = {game: id, pid: player, people: ppl, connections: conn};
    axios
    .put(`${process.env.REACT_APP_API_URL}resources/updateTokens`,temp)
    .then((response) => {

    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateCash = (id, player, csh) => {

  let temp = {game: id, pid: player, cash: csh};
    axios
    .put(`${process.env.REACT_APP_API_URL}resources/updateCash`,temp)
    .then((response) => {

    })
    .catch((err) => {
      console.log(err);
    });
};