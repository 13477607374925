import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import styles from "../styles/Ongoing.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { LoadingContext, SocketContext } from "../App";
import Navbar from "../components/Navbar";
import Back from "../components/Back";
import CollapsibleOngoingRow from "../components/CollapsibleOngoingRow";
import Refresh from "../components/Refresh";

const boxStyle = {
  width: "90%",
  backgroundColor: "white",
  boxShadow: 24,
  marginLeft: "5%",
  marginTop: "100px",
  position: "absolute",
  top: "0%",
  boxSizing: "border-box",
  borderRadius: "10px",
  p: 4,
};

const Ongoing = () => {

  const [open, setOpen] = useState("");

  const {socket} = useContext(SocketContext);
  const { setIsLoading } = useContext(LoadingContext);

  const columns = [
    {id: "#", label: "#"},
    { id: "title", label: "Title" },
    {
      id: "start_time",
      label: "Date",
    },
    {
      id: "players",
      label: "Players",
    },
    {
      id: "creator_name",
      label: "Created By",
    },
    {
      id: "status",
      label: "Status",
    },
  ];

  const [allOngoingGames, setAllOngoingGames] = useState([]);

  const getAllOngoingGames = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}games/ongoing`)
      .then((response) => {

        setAllOngoingGames(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  const [allGamePlayers, setAllGamePlayers] = useState([]);

  const getGamePlayers = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}players/allOfAGame`,{
        params: {
          game: id,
        },
      })
      .then((response) => {

        setAllGamePlayers(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    socket.on("adminUpdate",()=>{
      getAllOngoingGames();
    })
    
    getAllOngoingGames();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [openAdminModal, setOpenAdminModal] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <>
    <Navbar/>
      <div className={styles.mainOngoing}>
        <Back/>
        <Box sx={boxStyle}>
          <Refresh getGamePlayers={getGamePlayers} open={open} getAllOngoingGames={getAllOngoingGames}/>
          <Typography
            variant="h4"
            component="h4"
            textAlign={"center"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
            fontFamily={"Fjalla"}
            sx={{ marginBottom: "5px" }}
          >
            ONGOING GAMES
          </Typography>
          <div className={styles.belowInfo}>
            Below are the games which are currently live.
          </div>
          <Paper sx={{ width: "100%",height: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={i}
                        style={{
                          fontFamily: "Poppins",
                          letterSpacing: "1px",
                          fontWeight: "bold",
                          textAlign: "center",
                          backgroundColor: "#B33951",
                          color: "white",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allOngoingGames
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, i) => {
                      return (
                          <CollapsibleOngoingRow allGamePlayers={allGamePlayers} getGamePlayers={getGamePlayers} open={open} setOpen={setOpen} key={i} columns={columns} row={row}/>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={allOngoingGames?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </>
  );
};

export default Ongoing;
