import styles from "../styles/Navbar.module.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo2.png";
import { useContext, useState } from "react";
import { UserContext } from "../App";
import { IconButton } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useSpring, animated } from "@react-spring/web";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";

const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};


const Navbar = (props) => {
  
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    setMenuOpen(!menuOpen);
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const navigate = useNavigate();

  // eslint-disable-next-line no-unused-vars
  const { user,setUser } = useContext(UserContext);

  const logOut = () => {
    setUser('');
    localStorage.removeItem("tkn");
    navigate("/sat-administrator/login");
  };

  return (
    <nav className={styles.navbar}>
      <img
        onClick={() => navigate("/sat-administrator/dashboard")}
        className={styles["nav-logo"]}
        src={logo}
        alt="logo"
      />

      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ position: "absolute", top: 8, right: 8 }}
            >
              <CloseIcon />
            </IconButton>
            <div style={{ textAlign: "center" }} className={styles["nav-item1"]}>
              <Link
                to="/sat-administrator/dashboard"
                style={
                  props.selected === "dashboard"
                    ? { color: "black", borderBottom: "3px solid black" }
                    : {}
                }
                className={styles["nav-link"]}
              >
                DASHBOARD
              </Link>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ul className={styles["navbar-nav"]}>
        <li className={styles["nav-item"]}>
          <Link
            to="/sat-administrator/dashboard"
            style={
              props.selected === "dashboard"
                ? { color: "black", borderBottom: "3px solid black" }
                : {}
            }
            className={styles["nav-link"]}
          >
            DASHBOARD
          </Link>
        </li>
      </ul>
      <div className={styles.menu}> <IconButton 
       
          color="inherit"
          onClick={handleOpen}
          aria-label="close"
        
        >
          <MenuIcon fontSize="large" />
        </IconButton></div>
     
      <button onClick={logOut} className={styles["nav-logout"]}>
        LOGOUT
      </button>
    </nav>
  );
};

export default Navbar;
