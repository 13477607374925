import { calculateDenomination } from "../helpers/calculations";
import {
  CASH_PANEL_AMOUNT_FONTSIZE,
  CASH_PANEL_AMOUNT_POSX,
  CASH_PANEL_AMOUNT_POSY,
  CASH_PANEL_TEXT_POSX,
  CASH_PANEL_TEXT_POSY,
  CHOOSETEXT_FONTSIZE,
  CONNECTION_TEXT_POSX,
  CONNECTION_TEXT_POSY,
  DEV_DPI_RATIO,
  ERROR_BLOB_HEIGHT,
  ERROR_BLOB_WIDTH,
  ERROR_FONTSIZE,
  EXTRA_TIME_POSY,
  EXTRA_TIME_TEXT,
  FONTSIZE_FIXED,
  GAME_OVER_TEXT,
  GLOBAL_TIMER_FONTSIZE,
  GLOBAL_TIMER_POSY,
  LONG_BOX_HEIGHT,
  LONG_BOX_WIDTH,
  NO_PLAY_FONTSIZE,
  NO_REVENUE_TEXT,
  OPPORTUNITY_PANEL_TEXT_POSX,
  OPPORTUNITY_PANEL_TEXT_POSY,
  OUTSIDE_SCREEN,
  PANEL_HEADING_FONTSIZE,
  PANEL_POS,
  PEOPLE_TEXT_POSX,
  PEOPLE_TEXT_POSY,
  PIPELINE_HEIGHT,
  PIPELINE_PLACEMENT_POSX,
  PIPELINE_PLACEMENT_POSY,
  PIPELINE_POSY,
  PIPELINE_WIDTH,
  PROJECT_PANEL_TEXT_POSX,
  PROJECT_PANEL_TEXT_POSY,
  PROJECT_PIPELINE_TEXT_POSY,
  SMALL_BOX_HEIGHT,
  SMALL_BOX_WIDTH,
  TITLE_SECTION_FONTSIZE,
  TOKEN_PANEL_COUNT_FONTSIZE,
  TOKEN_PANEL_TEXT_POSX,
  TOKEN_PANEL_TEXT_POSY,
  UPCOMING_CARD_HEIGHT,
  UPCOMING_CARD_WIDTH,
  UPCOMING_CARDS_B_POSX,
  UPCOMING_CARDS_CARDS_POSY,
  UPCOMING_CARDS_G_POSX,
  UPCOMING_CARDS_H_POSX,
  UPCOMING_CARDS_O_POSX,
  UPCOMING_CARDS_S_POSX,
  UPCOMING_CARDS_TEXT_POSY,
} from "../helpers/dimensions";
import {
  bronzeProjects,
  goldProjects,
  hurdles,
  opportunities,
  silverProjects,
} from "../helpers/cards";
import { Hurdle } from "../models/Hurdle";
import { Opportunity } from "../models/Opportunity";
import { Project } from "../models/Project";
import { BaseScene } from "./BaseScene";
import eventsCenter from "../helpers/eventsCenter";
import Phaser from "phaser";
import { Loader } from "../models/Loader";
import {
  getAllInProgressProjects,
  getAllOpportunities,
  getAllProjects,
  getGameStatus,
  getResources,
  ifNoPlayActive,
  ifNoRevenueActive,
} from "../apis/get";
import { Button } from "../models/Button";
import {
  abandonHurdle,
  abandonOpportunity,
  receivedOpportunity,
} from "../apis/put";
import { dynamicPath, fromCurrPath, mainPath } from "../helpers/path";

export class PlayScene extends BaseScene {
  constructor(config) {
    super("PlayScene", config);
  }

  async create(data) {
    this.properties = data;

    this.viewingSomething = false;
    this.latePopper = [];
    this.canView = true;
    this.discardingProject = false;
    this.discardingContainer = false;
    this.disableProjects = false;
    this.hiddenDeletedPipeline = true;
    this.gotPipelineAfterVisible = true;
    this.add
      .image(this.config.width / 2, this.config.height / 2, "background")
      .setOrigin(0.5, 0.5)
      .setDisplaySize(this.config.width, this.config.height);
    this.darkness = this.add
      .image(0, 0, "darkness")
      .setDisplaySize(this.config.width, this.config.height)
      .setOrigin(0, 0)
      .setAlpha(0)
      .setDepth(100)
      .setInteractive({ cursor: "default" });
    this.createProjectPanel();
    this.createOpportunityPanel();
    this.createCashPanel();
    this.createTokenPanel();
    this.createGlobalTimer();
    this.createUpcomingCards();
    this.createGameOver();

    await this.gameInit();

    // Spawn Socket Events
    eventsCenter.on("BSpawn", (data) => {
      this.spawnProject(
        "B",
        data.card,
        this.bronzeUpcomingLoader.posX,
        this.bronzeUpcomingLoader.posY,
        data.id
      );
    });
    eventsCenter.on("SSpawn", (data) => {
      this.spawnProject(
        "S",
        data.card,
        this.silverUpcomingLoader.posX,
        this.silverUpcomingLoader.posY,
        data.id
      );
    });
    eventsCenter.on("GSpawn", (data) => {
      this.spawnProject(
        "G",
        data.card,
        this.goldUpcomingLoader.posX,
        this.goldUpcomingLoader.posY,
        data.id
      );
    });

    eventsCenter.on("HSpawn", (data) => {
      if (!document.hidden) {
        this.latePopper.push(data);
        if (this.latePopper.length === 1) {
          this.spawnHurdle(data.card1, data.card2, data.id1, data.id2);
        }
      }
    });

    eventsCenter.on("OSpawn", (data) => {
      if (!document.hidden) {
        this.latePopper.push(data);
        if (this.latePopper.length === 1) {
          this.spawnOpportunity(data.card1, data.card2, data.id1, data.id2);
        }
      }
    });

    // Timer Socket Events

    // Spawn End Socket Events
    eventsCenter.on("BEmpty", () => {
      this.bronzeUpcomingLoader.crossOutLoader();
    });
    eventsCenter.on("SEmpty", () => {
      this.silverUpcomingLoader.crossOutLoader();
    });
    eventsCenter.on("GEmpty", () => {
      this.goldUpcomingLoader.crossOutLoader();
    });
    eventsCenter.on("OEmpty", () => {
      this.opportunityUpcomingLoader.crossOutLoader();
    });
    eventsCenter.on("HEmpty", () => {
      this.hurdleUpcomingLoader.crossOutLoader();
    });

    eventsCenter.on("noRevenueCountDownEnd", () => {
      this.noRevenueCountDown.setAlpha(0);
      this.pipes.setTexture("pipeline");
      this.pipes.play("arrows");
      this.noRevenueActive = false;
    });

    eventsCenter.on("noPlayCountDownEnd", () => {
      this.noPlayText.setAlpha(0);
      this.crossedOpp.setAlpha(0).setScale(4);
      this.crossedProj.setAlpha(0).setScale(4);
      this.noPlayActive = false;
    });

    eventsCenter.on("ghostProject", (data) => {
      const path = mainPath;
      // const graphics = this.parentScene.add.graphics();
      // graphics.lineStyle(1, 0xffffff, 1);
      // path.draw(graphics, 128);

      const lemming = this.add
        .follower(
          path,
          this.config.width / 2 - PIPELINE_PLACEMENT_POSX,
          this.config.height / 2 - PIPELINE_PLACEMENT_POSY,
          "projectCard"
        )
        .setTint(0xFF7F7F)
        .setScale(0.1)
        .setAlpha(0.3);

      lemming.startFollow({
        duration: data.speed * 1000,
        rotateToPath: false,
        verticalAdjust: true,

        onComplete: () => {
          lemming.destroy();
        },
      });
    });

    this.extraTimeStarted = false;
    this.timeRemaining(this);
    this.countDown = setInterval(() => this.timeRemaining(this), 1000);

    this.extraTimeCountDown = setInterval(() => {
      if (this.extraTimeStarted) {
        this.extraTime(this);
      }
    }, 1000);
  }

  update(time, delta) {

    if (this.status) {
      this.cashAmountText.width < 96 * devicePixelRatio &&
        this.cashAmountText.setFontSize(`${CASH_PANEL_AMOUNT_FONTSIZE}px`);
      this.cashAmountText.width > 96 * devicePixelRatio &&
        this.cashAmountText.setStyle({ fontSize: `${FONTSIZE_FIXED}px` });
      this.peopleText.width < 40 * devicePixelRatio &&
        this.peopleText.setFontSize(`${TOKEN_PANEL_COUNT_FONTSIZE}px`);
      this.peopleText.width > 40 * devicePixelRatio &&
        this.peopleText.setStyle({ fontSize: `${FONTSIZE_FIXED}px` });
      this.connectionsText.width < 40 * devicePixelRatio &&
        this.connectionsText.setFontSize(`${TOKEN_PANEL_COUNT_FONTSIZE}px`);
      this.connectionsText.width > 40 * devicePixelRatio &&
        this.connectionsText.setStyle({ fontSize: `${FONTSIZE_FIXED}px` });

      if (this.discardingContainer) {
        this.curve.getPoint(this.path.t, this.path.vec);
        let f = delta / (1000 / 60) / 10;
        this.discarded.rotation += f;
        this.discarded.scale -= this.discarded.scale > 0 ? f / 10 : 0;

        this.discarded.x = this.path.vec.x;
        this.discarded.y = this.path.vec.y;
      }
    }
  }

  async gameInit() {
    this.status = await getGameStatus();
    this.timeDiff = this.status.data.end_timer * 1000;

    this.resources = await getResources();
    this.cash = this.resources.cash;
    this.cashAmountText.setText(calculateDenomination(this.cash));
    this.connections = this.resources.connections;
    this.connectionsText.setText(this.connections);
    this.people = this.resources.people;
    this.peopleText.setText(this.people);

    this.people = this.resources.people;
    this.connections = this.resources.connections;

    // console.log(this.status);
    this.createLoaders();
    this.receivedOpportunities = await getAllOpportunities();
    this.receivedOpportunities.forEach((opp) => {
      let oppCard = new Opportunity({
        code: opp.code,
        ...opportunities[opp.code],
        id: opp.id,
        playSceneConfig: this.config,
        scene: this,
        x: this.opportunityUpcomingLoader.posX,
        y: this.opportunityUpcomingLoader.posY,
        texture: "opportunityCard",
        flipTexture: "opportunityCard",
        flipFrame: opp.frame,
        frame: opp.frame,
      });
      oppCard.placeOnPanel();
    });
    this.receivedProjects = await getAllProjects();
    this.receivedProjects.forEach((proj) => {
      switch (proj.code[0]) {
        case "B":
          this.spawnProject(
            proj.code[0],
            proj,
            this.bronzeUpcomingLoader.posX,
            this.bronzeUpcomingLoader.posY,
            proj.id
          );
          break;
        case "S":
          this.spawnProject(
            proj.code[0],
            proj,
            this.silverUpcomingLoader.posX,
            this.silverUpcomingLoader.posY,
            proj.id
          );
          break;
        case "G":
          this.spawnProject(
            proj.code[0],
            proj,
            this.goldUpcomingLoader.posX,
            this.goldUpcomingLoader.posY,
            proj.id
          );
          break;
        default:
      }
    });

    await this.inProgress();

    eventsCenter.on("BTimer", (data) => {
      this.bronzeUpcomingLoader.updateLoader(data);
    });
    eventsCenter.on("STimer", (data) => {
      this.silverUpcomingLoader.updateLoader(data);
    });
    eventsCenter.on("GTimer", (data) => {
      this.goldUpcomingLoader.updateLoader(data);
    });
    eventsCenter.on("OTimer", (data) => {
      this.opportunityUpcomingLoader.updateLoader(data);
    });
    eventsCenter.on("HTimer", (data) => {
      this.hurdleUpcomingLoader.updateLoader(data);
    });

    this.noRevenueActive = await ifNoRevenueActive();
    this.noPlayActive = await ifNoPlayActive();

    this.createPipeline();

    if (this.noRevenueActive) {
      this.pipes.play("caution");
      this.pipes.setTexture("pipelineNoRevenue");
      this.noRevenueCountDown.setText("- ");
      this.noRevenueCountDown.setAlpha(1);
    } else {
      this.pipes.play("arrows");
    }

    if (this.noPlayActive) {
      this.noPlay();
    }

    eventsCenter.on("noRevenueCountDown", (data) => {
      this.noRevenueCountDown.setText(`- ${data}`);
    });

    eventsCenter.on("noPlayCountDown", (data) => {
      this.noPlayText.setText(`NO PLAY - ${data}`);
    });
  }

  createGameOver() {
    this.gameOverText = this.add
      .text(this.config.width / 2, OUTSIDE_SCREEN, "GAME OVER", {
        padding: { right: 4 },
        shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
        fontStyle: "1000",
        fontFamily: "Night",
        fontSize: `${GAME_OVER_TEXT}px`,
        color: "white",
        align: "center",
        lineSpacing: -10,
      })
      .setOrigin(0.5, 0.5)
      .setDepth(110);
  }

  createGlobalTimer() {
    this.globalTimerText = this.add
      .text(this.config.width / 2, GLOBAL_TIMER_POSY, "", {
        padding: { right: 4 },
        shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
        fontStyle: "1000",
        fontFamily: "Night",
        fontSize: `${GLOBAL_TIMER_FONTSIZE}px`,
        color: "white",
        align: "center",
        lineSpacing: -10,
      })
      .setOrigin(0.5, 0);
    this.extraTimeText = this.add
      .text(
        this.config.width / 2 - 100 * devicePixelRatio,
        EXTRA_TIME_POSY,
        "EXTRA TIME",
        {
          padding: { right: 4 },
          shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
          fontStyle: "1000",
          fontFamily: "Fjalla",
          fontSize: `${EXTRA_TIME_TEXT}px`,
          color: "red",
          align: "center",
          lineSpacing: -10,
        }
      )
      .setOrigin(1, 0)
      .setAlpha(0);
  }

  timeRemaining(self) {

    this.timeDiff -= 1000;

    let diff = this.timeDiff - 60000;

    if (diff <= 0) {
      this.extraTimeText.setAlpha(1);
      this.extraTime(this);
      this.extraTimeStarted = true;
      this.disableProjects = true;
      this.lastMinuteActions();
      clearInterval(this.countDown);
    } else {
      // Calculate hours, minutes and seconds
      const hours = Math.floor(diff / (1000 * 60 * 60));
      diff -= hours * (1000 * 60 * 60);
      const minutes = Math.floor(diff / (1000 * 60));
      diff -= minutes * (1000 * 60);
      const seconds = Math.floor(diff / 1000);

      // Format the time components to two digits
      // const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      // Return the formatted time string
      self.globalTimerText.setText(`${formattedMinutes}:${formattedSeconds}`);

      return `${formattedMinutes}:${formattedSeconds}`;
    }
  }

  extraTime(self) {
    this.timeDiff -= 1000;

    let diff = this.timeDiff;

    if (diff <= 0) {
      this.projects.getChildren().forEach((proj) => {
        proj.lemming.stopFollow();
      });
      this.projects.getChildren().forEach((proj) => {
        proj.destroy();
      });
      this.add.tween({
        targets: this.gameOverText,
        y: this.config.height / 2,
        duration: 750,
        ease: Phaser.Math.Easing.Back.Out,
      });
      this.add.tween({
        targets: this.darkness,
        alpha: 0.8,
      });
      this.resultsBtn = new Button({
        playSceneConfig: this.config,
        scene: this,
        texture: "buttons",
        frame: 0,
        frameActive: 1,
        text: "RESULTS",
        gameOverBtn: true,
      });
      this.resultsBtn.appearGameOver();
      self.globalTimerText.setText(`--:--`);
      clearInterval(this.extraTimeCountDown);
    } else {
      // Calculate hours, minutes and seconds
      const hours = Math.floor(diff / (1000 * 60 * 60));
      diff -= hours * (1000 * 60 * 60);
      const minutes = Math.floor(diff / (1000 * 60));
      diff -= minutes * (1000 * 60);
      const seconds = Math.floor(diff / 1000);

      // Format the time components to two digits
      // const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");

      // Return the formatted time string
      self.globalTimerText.setText(`+${formattedMinutes}:${formattedSeconds}`);

      return `${formattedMinutes}:${formattedSeconds}`;
    }
  }

  createUpcomingCards() {
    this.upcomingCardsText = this.add
      .text(
        this.config.width / 2,
        this.config.height / 2 + UPCOMING_CARDS_TEXT_POSY,
        "UPCOMING CARDS",
        {
          padding: { right: 4 },
          shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
          fontStyle: "1000",
          fontFamily: "Night",
          fontSize: `${TITLE_SECTION_FONTSIZE}px`,
          color: "white",
          align: "center",
          lineSpacing: -10,
        }
      )
      .setOrigin(0.5, 0);
    this.bronzeUpcoming = this.add
      .image(
        this.config.width / 2 + UPCOMING_CARDS_B_POSX,
        this.config.height + UPCOMING_CARDS_CARDS_POSY,
        "cardBackSmall",
        0
      )
      .setDisplaySize(UPCOMING_CARD_WIDTH, UPCOMING_CARD_HEIGHT)
      .setOrigin(0.5);
    this.silverUpcoming = this.add
      .image(
        this.config.width / 2 + UPCOMING_CARDS_S_POSX,
        this.config.height + UPCOMING_CARDS_CARDS_POSY,
        "cardBackSmall",
        1
      )
      .setDisplaySize(UPCOMING_CARD_WIDTH, UPCOMING_CARD_HEIGHT)
      .setOrigin(0.5);
    this.goldUpcoming = this.add
      .image(
        this.config.width / 2 + UPCOMING_CARDS_G_POSX,
        this.config.height + UPCOMING_CARDS_CARDS_POSY,
        "cardBackSmall",
        2
      )
      .setDisplaySize(UPCOMING_CARD_WIDTH, UPCOMING_CARD_HEIGHT)
      .setOrigin(0.5);
    this.hurdleUpcoming = this.add
      .image(
        this.config.width / 2 + UPCOMING_CARDS_H_POSX,
        this.config.height + UPCOMING_CARDS_CARDS_POSY,
        "cardBackSmall",
        4
      )
      .setDisplaySize(UPCOMING_CARD_WIDTH, UPCOMING_CARD_HEIGHT)
      .setOrigin(0.5);
    this.opportunityUpcoming = this.add
      .image(
        this.config.width / 2 + UPCOMING_CARDS_O_POSX,
        this.config.height + UPCOMING_CARDS_CARDS_POSY,
        "cardBackSmall",
        3
      )
      .setDisplaySize(UPCOMING_CARD_WIDTH, UPCOMING_CARD_HEIGHT)
      .setOrigin(0.5);
  }

  createLoaders() {
    this.bronzeUpcomingLoader = new Loader({
      ended: this.status?.data.b_end,
      type: "B",
      scene: this,
      x: this.bronzeUpcoming.x,
      y: this.bronzeUpcoming.y,
    });
    this.silverUpcomingLoader = new Loader({
      ended: this.status?.data.s_end,
      type: "S",
      scene: this,
      x: this.silverUpcoming.x,
      y: this.silverUpcoming.y,
    });
    this.goldUpcomingLoader = new Loader({
      ended: this.status?.data.g_end,
      type: "G",
      scene: this,
      x: this.goldUpcoming.x,
      y: this.goldUpcoming.y,
    });
    this.hurdleUpcomingLoader = new Loader({
      ended: this.status?.data.h_end,
      type: "H",
      scene: this,
      x: this.hurdleUpcoming.x,
      y: this.hurdleUpcoming.y,
    }).setAlpha(0);
    this.opportunityUpcomingLoader = new Loader({
      ended: this.status?.data.o_end,
      type: "O",
      scene: this,
      x: this.opportunityUpcoming.x,
      y: this.opportunityUpcoming.y,
    }).setAlpha(0);
  }

  createPipeline() {
    this.pipelineText = this.add
      .text(
        this.config.width / 2,
        this.config.height / 2 - PROJECT_PIPELINE_TEXT_POSY,
        "PROJECT PIPELINE",
        {
          padding: { right: 4 },
          shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
          fontStyle: "1000",
          fontFamily: "Night",
          fontSize: `${TITLE_SECTION_FONTSIZE}px`,
          color: "white",
          align: "center",
          lineSpacing: -10,
        }
      )
      .setOrigin(0.5, 0);

    this.pipes = this.add
      .sprite(
        this.config.width / 2,
        this.config.height / 2 - PIPELINE_POSY,
        "pipeline",
        0
      )
      .setDisplaySize(PIPELINE_WIDTH, PIPELINE_HEIGHT);

    this.noRevenueCountDown = this.add
      .text(
        this.config.width / 2 + 150 * devicePixelRatio,
        this.config.height / 2 - 35 * devicePixelRatio,
        "-",
        {
          padding: { right: 4 },
          shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
          fontStyle: "1000",
          fontFamily: "Night",
          fontSize: `${NO_REVENUE_TEXT}px`,
          color: "red",
          align: "center",
          lineSpacing: -10,
        }
      )
      .setOrigin(0.5, 0.5)
      .setAlpha(0);

    this.noPlayText = this.add
      .text(
        this.config.width / 2 + 200 * devicePixelRatio,
        GLOBAL_TIMER_POSY + 15 * devicePixelRatio,
        "NO PLAY - ",
        {
          padding: { right: 4 },
          shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
          fontStyle: "1000",
          fontFamily: "Night",
          fontSize: `${NO_PLAY_FONTSIZE}px`,
          color: "red",
          align: "center",
          lineSpacing: -10,
        }
      )
      .setOrigin(0.5, 0)
      .setAlpha(0);

    this.crossedProj = this.add
      .image(this.projectPanel.x, this.projectPanel.y, "longBoxCross")
      .setDisplaySize(LONG_BOX_WIDTH, LONG_BOX_HEIGHT)
      .setOrigin(0, 0)
      .setAlpha(0)
      .setScale(4)
      .setInteractive({ cursor: "default" })
      .setDepth(99);
    this.crossedOpp = this.add
      .image(this.opportunityPanel.x, this.opportunityPanel.y, "longBoxCross")
      .setDisplaySize(LONG_BOX_WIDTH, LONG_BOX_HEIGHT)
      .setOrigin(1, 0)
      .setAlpha(0)
      .setScale(4)
      .setInteractive({ cursor: "default" })
      .setDepth(99);

    this.anims.create({
      key: "arrows",
      frameRate: 30,
      frames: this.anims.generateFrameNumbers("pipeline", {
        start: 0,
        end: 45,
      }),
      repeat: -1,
      repeatDelay: 5000,
    });

    this.anims.create({
      key: "caution",
      frameRate: 30,
      frames: this.anims.generateFrameNumbers("pipelineNoRevenue", {
        start: 0,
        end: 33,
      }),
      repeat: -1,
      repeatDelay: 5000,
    });
  }

  validateProjectCount() {
    if (!this.errorMsgActive) {
      this.errorMsgActive = true;
      this.errorBlob = this.add
        .image(
          this.config.width - PANEL_POS - ERROR_BLOB_WIDTH / 2,
          this.config.height * 2,
          "errorBlob"
        )
        .setDisplaySize(ERROR_BLOB_WIDTH, ERROR_BLOB_HEIGHT)
        .setDepth(5)
        .setOrigin(0.5, 0.5);
      this.errorBlobText = this.add
        .text(
          this.errorBlob.x,
          this.errorBlob.y,
          `CANNOT HOLD MORE THAN 2 PROJECTS. ${
            this.skipTurnActive ? "PLEASE" : "USE OR"
          } DISCARD 1 PROJECT ${this.skipTurnActive ? "" : "TO END YOUR TURN"}`,
          {
            align: "center",
            wordWrap: {
              width: ERROR_BLOB_WIDTH - 50 * devicePixelRatio,
              useAdvancedWrap: true,
            },
            padding: { right: 2 },
            shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 1 },
            lineSpacing: 5,
            fontStyle: "1000",
            fontFamily: "NIGHT",
            fontSize: `${ERROR_FONTSIZE}px`,
            color: "white",
          }
        )
        .setOrigin(0.5, 0.5)
        .setDepth(5);
      this.add.tween({
        targets: [this.errorBlob, this.errorBlobText],
        y: this.config.height - PANEL_POS - ERROR_BLOB_HEIGHT / 2,
        duration: 700,
        yoyo: this.skipTurnActive ? false : true,
        hold: this.skipTurnActive ? 0 : 3000,
        ease: Phaser.Math.Easing.Back.InOut,
        onComplete: () => {
          if (!this.skipTurnActive) {
            this.errorBlob.destroy();
            this.errorBlobText.destroy();
            this.errorMsgActive = false;
          } else {
            this.canView = true;
          }
        },
      });
    }
  }

  createCashPanel() {
    this.cashBox = this.add
      .image(0, 0, "cashPanel")
      .setOrigin(0, 1)
      .setDisplaySize(SMALL_BOX_WIDTH, SMALL_BOX_HEIGHT);
    this.cashText = this.add
      .text(CASH_PANEL_TEXT_POSX, CASH_PANEL_TEXT_POSY, "Cash", {
        padding: { right: 4 },
        shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
        fontStyle: "1000",
        fontFamily: "Night",
        fontSize: `${PANEL_HEADING_FONTSIZE}px`,
      })
      .setOrigin(0.5, 0);
    this.cashAmountText = this.add
      .text(CASH_PANEL_AMOUNT_POSX, CASH_PANEL_AMOUNT_POSY, "", {
        padding: { right: 4 },
        shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
        fontStyle: "1000",
        fontFamily: "Night",
        fontSize: `${CASH_PANEL_AMOUNT_FONTSIZE}px`,
        color: "white",
        align: "center",
        lineSpacing: -10,
      })
      .setOrigin(0, 0.5);
    this.cashPanel = this.add
      .container(PANEL_POS, this.config.height - PANEL_POS, [
        this.cashBox,
        this.cashText,
        this.cashAmountText,
      ])
      .setDepth(30);
  }

  createTokenPanel() {
    this.tokenBox = this.add
      .image(0, 0, "tokenPanel")
      .setOrigin(1, 1)
      .setDisplaySize(SMALL_BOX_WIDTH, SMALL_BOX_HEIGHT);
    this.tokenText = this.add
      .text(TOKEN_PANEL_TEXT_POSX, TOKEN_PANEL_TEXT_POSY, "TOKENS", {
        padding: { right: 4 },
        shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
        fontStyle: "1000",
        fontFamily: "Night",
        fontSize: `${PANEL_HEADING_FONTSIZE}px`,
      })
      .setOrigin(0.5, 0);
    this.peopleText = this.add
      .text(PEOPLE_TEXT_POSX, PEOPLE_TEXT_POSY, "", {
        padding: { right: 4 },
        shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
        fontStyle: "1000",
        fontFamily: "Night",
        fontSize: `${TOKEN_PANEL_COUNT_FONTSIZE}px`,
        color: "white",
      })
      .setOrigin(0, 0.5);
    this.connectionsText = this.add
      .text(CONNECTION_TEXT_POSX, CONNECTION_TEXT_POSY, "", {
        padding: { right: 4 },
        shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
        fontStyle: "1000",
        fontFamily: "Night",
        fontSize: `${TOKEN_PANEL_COUNT_FONTSIZE}px`,
        color: "white",
      })
      .setOrigin(0, 0.5);
    this.tokenPanel = this.add
      .container(
        this.config.width - PANEL_POS,
        this.config.height - PANEL_POS,
        [this.tokenBox, this.tokenText, this.peopleText, this.connectionsText]
      )
      .setDepth(30);
  }

  createProjectPanel() {
    this.projects = this.add.group();
    this.panelProjects = this.add.group();
    this.projectBox = this.add
      .image(0, 0, "projectPanel")
      .setOrigin(0, 0)
      .setDisplaySize(LONG_BOX_WIDTH, LONG_BOX_HEIGHT);
    this.projectText = this.add
      .text(PROJECT_PANEL_TEXT_POSX, PROJECT_PANEL_TEXT_POSY, "Projects", {
        padding: { right: 4 },
        shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
        fontStyle: "1000",
        fontFamily: "Night",
        fontSize: `${PANEL_HEADING_FONTSIZE}px`,
      })
      .setOrigin(0.5, 0);
    this.projectPanel = this.add.container(PANEL_POS, PANEL_POS, [
      this.projectBox,
      this.projectText,
      ...this.projects.getChildren(),
    ]);
  }

  createOpportunityPanel() {
    this.opportunities = this.add.group();
    this.opportunityBox = this.add
      .image(0, 0, "opportunityPanel")
      .setOrigin(1, 0)
      .setDisplaySize(LONG_BOX_WIDTH, LONG_BOX_HEIGHT);
    this.opportunityText = this.add
      .text(
        OPPORTUNITY_PANEL_TEXT_POSX,
        OPPORTUNITY_PANEL_TEXT_POSY,
        "Opportunity",
        {
          padding: { right: 4 },
          shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
          fontStyle: "1000",
          fontFamily: "Night",
          fontSize: `${PANEL_HEADING_FONTSIZE}px`,
        }
      )
      .setOrigin(0.5, 0);
    this.opportunityPanel = this.add.container(
      this.config.width - PANEL_POS,
      PANEL_POS,
      [
        this.opportunityBox,
        this.opportunityText,
        ...this.opportunities.getChildren(),
      ]
    );
  }

  lastMinuteActions() {
    if (this.viewingSomething) {
      let { type, card, metaData, btn } = this.viewing;

      if (type === "P") {
        card.endViewProject(metaData, btn);
      }
    }

    this.panelProjects.getChildren().forEach((proj) => {
      proj.removeInteractive();
    });

    this.add.tween({
      targets: [this.crossedProj],
      scale: 1 * DEV_DPI_RATIO,
      alpha: 1,
      duration: 200,
    });
  }

  noPlay() {
    this.noPlayActive = true;
    this.noPlayText.setText("NO PLAY - ");
    this.noPlayText.setAlpha(1);
    
    if (this.viewingSomething) {
      let { type, card, metaData, btn } = this.viewing;

      if (type === "P") {
        card.endViewProject(metaData, btn);
      } else {
        card.endViewOpportunity(metaData, btn);
      }
    }

    this.add.tween({
      targets: [this.crossedProj, this.crossedOpp],
      scale: 1 * DEV_DPI_RATIO,
      alpha: 1,
      duration: 200,
    });
  }

  spawnProject(type, card, posX, posY, pid) {
    let project;
    switch (type) {
      case "G":
        project = new Project({
          id: pid,
          flipTexture: "projectCard",
          turn: this.currentPlayerTurn,
          type: type,
          playSceneConfig: this.config,
          scene: this,
          x: posX,
          y: posY,
          texture: "cardBack",
          flipFrame: card.frame,
          frame: 2,
          details: goldProjects[card.code],
        });
        break;
      case "S":
        project = new Project({
          id: pid,
          flipTexture: "projectCard",
          turn: this.currentPlayerTurn,
          type: type,
          playSceneConfig: this.config,
          scene: this,
          x: posX,
          y: posY,
          texture: "cardBack",
          flipFrame: card.frame,
          frame: 1,
          details: silverProjects[card.code],
        });
        break;
      case "B":
        project = new Project({
          id: pid,
          flipTexture: "projectCard",
          turn: this.currentPlayerTurn,
          type: type,
          playSceneConfig: this.config,
          scene: this,
          x: posX,
          y: posY,
          texture: "cardBack",
          flipFrame: card.frame,
          frame: 0,
          details: bronzeProjects[card.code],
        });
        break;
      default:
    }
    project.spawn();
  }

  nextUncertainty(uncertainty) {
    if (this.latePopper.length > 0) {
      let data = this.latePopper[0];
      if (uncertainty === "Hurdle") {
        this.spawnHurdle(data.card1, data.card2, data.id1, data.id2);
      } else {
        this.spawnOpportunity(data.card1, data.card2, data.id1, data.id2);
      }
    }
  }

  spawnHurdle(card1, card2, hid1, hid2) {
    this.canView = false;
    let hurdleDarkness = this.add
      .image(0, 0, "darkness")
      .setDisplaySize(this.config.width, this.config.height)
      .setOrigin(0, 0)
      .setAlpha(0)
      .setDepth(105)
      .setInteractive({ cursor: "default" });

    this.add.tween({
      targets: hurdleDarkness,
      alpha: 0.8,
      duration: 200,
    });

    let chooseText = this.add
      .text(this.config.width / 2, 100 * devicePixelRatio, "CHOOSE A HURDLE", {
        fontFamily: "Night",
        fontStyle: "1000",
        fontSize: `${CHOOSETEXT_FONTSIZE}px`,
        color: "white",
      })
      .setOrigin(0.5, 0.5)
      .setDepth(106)
      .setAlpha(0);
    let chooseTimer = this.add
      .text(
        this.config.width / 2,
        this.config.height - 100 * devicePixelRatio,
        "10",
        {
          fontFamily: "Night",
          fontStyle: "1000",
          fontSize: `${CHOOSETEXT_FONTSIZE}px`,
          color: "white",
        }
      )
      .setOrigin(0.5, 0.5)
      .setDepth(106)
      .setAlpha(0);

    let hurTimer = 9;
    let hurdleAppearInterval = setInterval(() => {
      if (hurTimer === -1) {
        clearInterval(hurdleAppearInterval);
        hur1.removeAllListeners();
        hur2.removeAllListeners();
        this.add.tween({
          targets: [chooseText, chooseTimer],
          scale: 0,
          duration: 300,
          ease: Phaser.Math.Easing.Back.In,
          onComplete: () => {
            chooseText.destroy();
            chooseTimer.destroy();
            this.canView = true;
            this.add.tween({
              targets: hurdleDarkness,
              alpha: 0,
              duration: 200,
            });
            this.playBothCards(hur1, hur2, hid1, hid2, hurdleDarkness);
          },
        });
        this.latePopper.shift();
        this.nextUncertainty("Opportunity");
      } else {
        chooseTimer.setText(hurTimer);
        hurTimer--;
      }
    }, 1000);
    let hur1 = new Hurdle({
      ...hurdles[card1.code],
      id: hid1,
      playSceneConfig: this.config,
      scene: this,
      x: this.config.width * 0.25 + 25 * devicePixelRatio,
      y: this.config.height / 2 + 10 * devicePixelRatio,
      texture: "cardBack",
      flipTexture: "hurdleCard",
      flipFrame: card1.frame,
      frame: 4,
    }).setDepth(106);
    hur1.spawn();
    let hur2 = new Hurdle({
      ...hurdles[card2.code],
      id: hid2,
      playSceneConfig: this.config,
      scene: this,
      x: this.config.width * 0.75 - 25 * devicePixelRatio,
      y: this.config.height / 2 + 10 * devicePixelRatio,
      texture: "cardBack",
      flipTexture: "hurdleCard",
      flipFrame: card2.frame,
      frame: 4,
    }).setDepth(106);
    hur2.spawn();

    this.add.tween({
      targets: [chooseText, chooseTimer, hur1, hur2],
      alpha: 1,
      duration: 300,
    });

    hur1.on("pointerover", () => {
      this.add.tween({
        targets: hur1,
        scale: 0.85 * DEV_DPI_RATIO,
        duration: 50,
      });
    });
    hur1.on("pointerout", () => {
      this.add.tween({
        targets: hur1,
        scale: 0.8 * DEV_DPI_RATIO,
        duration: 50,
      });
    });
    hur2.on("pointerover", () => {
      this.add.tween({
        targets: hur2,
        scale: 0.85 * DEV_DPI_RATIO,
        duration: 50,
      });
    });
    hur2.on("pointerout", () => {
      this.add.tween({
        targets: hur2,
        scale: 0.8 * DEV_DPI_RATIO,
        duration: 50,
      });
    });

    hur1.on("pointerup", () => {
      clearInterval(hurdleAppearInterval);
      abandonHurdle(hid2);
      hur1.removeAllListeners();
      hur2.removeAllListeners();
      this.add.tween({
        targets: hur2,
        scale: 0,
        duration: 300,
        onComplete: () => {
          hur2.destroy();
          this.add.tween({
            targets: hur1,
            x: this.config.width / 2,
            duration: 300,
            onComplete: () => {
              hur1.executeHurdle(hurdleDarkness);
              this.add.tween({
                targets: [chooseText, chooseTimer],
                scale: 0,
                duration: 600,
                ease: Phaser.Math.Easing.Back.In,
                onComplete: () => {
                  chooseText.destroy();
                  chooseTimer.destroy();
                  this.canView = true;
                  this.latePopper.shift();
                  this.nextUncertainty("Opportunity");
                },
              });
            },
          });
        },
      });
    });

    hur2.on("pointerup", () => {
      clearInterval(hurdleAppearInterval);
      abandonHurdle(hid1);
      hur1.removeAllListeners();
      hur2.removeAllListeners();
      this.add.tween({
        targets: hur1,
        scale: 0,
        duration: 300,
        onComplete: () => {
          hur1.destroy();
          this.add.tween({
            targets: hur2,
            x: this.config.width / 2,
            duration: 300,
            onComplete: () => {
              hur2.executeHurdle(hurdleDarkness);
              this.add.tween({
                targets: [chooseText, chooseTimer],
                scale: 0,
                duration: 600,
                ease: Phaser.Math.Easing.Back.In,
                onComplete: () => {
                  chooseText.destroy();
                  chooseTimer.destroy();
                  this.canView = true;
                  this.latePopper.shift();
                  this.nextUncertainty("Opportunity");
                },
              });
            },
          });
        },
      });
    });
  }

  spawnOpportunity(card1, card2, oid1, oid2) {
    this.canView = false;

    let opportunityDarkness = this.add
      .image(0, 0, "darkness")
      .setDisplaySize(this.config.width, this.config.height)
      .setOrigin(0, 0)
      .setAlpha(0)
      .setDepth(102)
      .setInteractive({ cursor: "default" });

    this.add.tween({
      targets: opportunityDarkness,
      alpha: 0.8,
      duration: 200,
    });

    let chooseText = this.add
      .text(
        this.config.width / 2,
        100 * devicePixelRatio,
        "CHOOSE AN OPPORTUNITY",
        {
          fontFamily: "Night",
          fontStyle: "1000",
          fontSize: `${CHOOSETEXT_FONTSIZE}px`,
          color: "white",
        }
      )
      .setOrigin(0.5, 0.5)
      .setDepth(103)
      .setAlpha(0);
    let chooseTimer = this.add
      .text(
        this.config.width / 2,
        this.config.height - 100 * devicePixelRatio,
        "10",
        {
          fontFamily: "Night",
          fontStyle: "1000",
          fontSize: `${CHOOSETEXT_FONTSIZE}px`,
          color: "white",
        }
      )
      .setOrigin(0.5, 0.5)
      .setDepth(103)
      .setAlpha(0);

    let oppTimer = 9;
    let opportunityAppearInterval = setInterval(() => {
      if (oppTimer === -1) {
        clearInterval(opportunityAppearInterval);
        opp1.removeAllListeners();
        opp2.removeAllListeners();
        this.add.tween({
          targets: [chooseText, chooseTimer],
          scale: 0,
          duration: 300,
          ease: Phaser.Math.Easing.Back.In,
          onComplete: () => {
            chooseText.destroy();
            chooseTimer.destroy();
            this.canView = true;
            this.add.tween({
              targets: opportunityDarkness,
              alpha: 0,
              duration: 200,
            });
            this.abandonBothCards(opp1, opp2, oid1, oid2);
          },
        });
        this.latePopper.shift();
        this.nextUncertainty("Hurdle");
      } else {
        chooseTimer.setText(oppTimer);
        oppTimer--;
      }
    }, 1000);

    let opp1 = new Opportunity({
      code: card1.code,
      ...opportunities[card1.code],
      id: oid1,
      playSceneConfig: this.config,
      scene: this,
      x: this.config.width * 0.25 + 25 * devicePixelRatio,
      y: this.config.height / 2 + 10 * devicePixelRatio,
      texture: "cardBack",
      flipTexture: "opportunityCard",
      flipFrame: card1.frame,
      frame: 3,
    }).setDepth(103);
    opp1.spawn();
    let opp2 = new Opportunity({
      code: card2.code,
      ...opportunities[card2.code],
      id: oid2,
      playSceneConfig: this.config,
      scene: this,
      x: this.config.width * 0.75 - 25 * devicePixelRatio,
      y: this.config.height / 2 + 10 * devicePixelRatio,
      texture: "cardBack",
      flipTexture: "opportunityCard",
      flipFrame: card2.frame,
      frame: 3,
    }).setDepth(103);
    opp2.spawn();

    this.add.tween({
      targets: [chooseText, chooseTimer, opp1, opp2],
      alpha: 1,
      duration: 300,
    });

    opp1.on("pointerover", () => {
      this.add.tween({
        targets: opp1,
        scale: 0.85 * DEV_DPI_RATIO,
        duration: 50,
      });
    });
    opp1.on("pointerout", () => {
      this.add.tween({
        targets: opp1,
        scale: 0.8 * DEV_DPI_RATIO,
        duration: 50,
      });
    });
    opp2.on("pointerover", () => {
      this.add.tween({
        targets: opp2,
        scale: 0.85 * DEV_DPI_RATIO,
        duration: 50,
      });
    });
    opp2.on("pointerout", () => {
      this.add.tween({
        targets: opp2,
        scale: 0.8 * DEV_DPI_RATIO,
        duration: 50,
      });
    });

    opp1.on("pointerup", () => {
      clearInterval(opportunityAppearInterval);
      opp1.removeAllListeners();
      opp2.removeAllListeners();
      receivedOpportunity(oid1);
      abandonOpportunity(oid2);

      this.add.tween({
        targets: opp2,
        scale: 0,
        duration: 300,
        onComplete: () => {
          opp2.destroy();
          this.add.tween({
            targets: opp1,
            x: this.config.width / 2,
            duration: 300,
            onComplete: () => {
              opp1.placeOnPanel(opportunityDarkness);
              this.add.tween({
                targets: [chooseText, chooseTimer],
                scale: 0,
                duration: 600,
                ease: Phaser.Math.Easing.Back.In,
                onComplete: () => {
                  chooseText.destroy();
                  chooseTimer.destroy();
                  this.canView = true;
                  this.latePopper.shift();
                  this.nextUncertainty("Hurdle");
                },
              });
            },
          });
        },
      });
    });

    opp2.on("pointerup", () => {
      clearInterval(opportunityAppearInterval);
      receivedOpportunity(oid2);
      abandonOpportunity(oid1);
      opp1.removeAllListeners();
      opp2.removeAllListeners();
      this.add.tween({
        targets: opp1,
        scale: 0,
        duration: 300,
        onComplete: () => {
          opp1.destroy();
          this.add.tween({
            targets: opp2,
            x: this.config.width / 2,
            duration: 300,
            onComplete: () => {
              opp2.placeOnPanel(opportunityDarkness);
              this.add.tween({
                targets: [chooseText, chooseTimer],
                scale: 0,
                duration: 600,
                ease: Phaser.Math.Easing.Back.In,
                onComplete: () => {
                  chooseText.destroy();
                  chooseTimer.destroy();
                  this.canView = true;
                  this.latePopper.shift();
                  this.nextUncertainty("Hurdle");
                },
              });
            },
          });
        },
      });
    });
  }

  abandonBothCards(opp1, opp2, oid1, oid2) {
    abandonOpportunity(oid1);
    abandonOpportunity(oid2);
    this.add.tween({
      targets: [opp1, opp2],
      scale: 0,
      duration: 200,
      onComplete: () => {
        opp1.destroy();
        opp2.destroy();
      },
    });
  }

  playBothCards(hur1, hur2, hid1, hid2, dness) {
    this.add.tween({
      targets: [hur1, hur2],
      x: this.config.width / 2,
      duration: 200,
      onComplete: () => {
        hur1.executeHurdle(dness);
        hur2.executeHurdle(dness);
      },
    });
  }

  async allProjectsAlterPathTime(time) {
    let projs = [...this.projects.getChildren()];

    const ongoing = await getAllInProgressProjects();

    let ongoingMap = {};

    ongoing.forEach((item) => {
      ongoingMap[item.id] = {
        id: item.id,
        code: item.code,
        frame: item.frame,
        ptype: item.ptype,
        remaining: item.remaining,
      };
    });

    projs.forEach((proj) => {
      if (ongoingMap[proj.id] !== undefined) {

        let onProj = ongoingMap[proj.id];
        proj.lemming.stopFollow();
        let pX = proj.lemming.x;
        let pY = proj.lemming.y;
        let path = fromCurrPath(pX, pY);
        proj.lemming.destroy(true);
        proj.destroy(true);
        if (onProj.remaining > 0) {
          const project = this.doneProject(onProj);
          project.lemming = this.add
            .follower(path, pX, pY, "projectCard", onProj.frame)
            .setScale(0.16)
            .setDepth(2);
          if (time > 0) {
            const snail = this.add
              .follower(path,pX, pY, "snail")
              .setDepth(2);
            snail.startFollow({
              duration: onProj.remaining * 1000,
              rotateToPath: false,
              verticalAdjust: true,
            });

            this.add.tween({
              targets: snail,
              alpha: 0,
              delay: 2000,
              duration: 400,
              onComplete: () => {
                snail.destroy(true);
              },
            });
          } else {
            const boot = this.add.follower(path,pX, pY, "boot").setDepth(2);
            boot.startFollow({
              duration: onProj.remaining * 1000,
              rotateToPath: false,
              verticalAdjust: true,
            });

            this.add.tween({
              targets: boot,
              alpha: 0,
              delay: 2000,
              duration: 400,
              onComplete: () => {
                boot.destroy(true);
              },
            });
          }
          project.lemming.startFollow({
            duration: onProj.remaining * 1000,
            rotateToPath: false,
            verticalAdjust: true,

            onComplete: () => {
              project.lemming.destroy();
              this.endLemming(project);
            },
          });
        } else {
          let project = this.doneProject(onProj);
          this.endLemming(project);
        }
      }
    });
  }

  async inProgress() {
    this.inProgressProjects = await getAllInProgressProjects();
    this.inProgressProjects.forEach((proj) => {
      const pathObj = dynamicPath(proj.remaining);
      if (!pathObj.done) {
        const path = pathObj.path;
        // const graphics = this.add.graphics();
        // graphics.lineStyle(1, 0xffffff, 1);
        // path.draw(graphics, 128);
        const project = this.doneProject(proj);
        project.lemming = this.add
          .follower(path, pathObj.x, pathObj.y, "projectCard", proj.frame)
          .setScale(0.16)
          .setDepth(2);

        project.lemming.startFollow({
          duration: proj.remaining * 1000,
          rotateToPath: false,
          verticalAdjust: true,

          onComplete: () => {
            project.lemming.destroy();
            this.endLemming(project);
          },
        });
      } else {
        let project = this.doneProject(proj);
        this.endLemming(project);
      }
    });
  }

  doneProject(proj) {
    let project;
    let projType;
    
    switch (proj.ptype) {
      case 0:
        projType = "normal";
        break;
      case 1:
        projType = "express";
        break;
      case 2:
        projType = "flash";
        break;
      default:
        projType = "relaxed";
    }

    switch (proj.code[0]) {
      case "G":
        project = new Project({
          id: proj.id,
          type: proj.code[0],
          playSceneConfig: this.config,
          scene: this,
          texture: "projectCard",
          frame: proj.frame,
          details: goldProjects[proj.code],
          selectedType: projType
        });
        break;
      case "S":
        project = new Project({
          id: proj.id,
          type: proj.code[0],
          playSceneConfig: this.config,
          scene: this,
          texture: "projectCard",
          frame: proj.frame,
          details: silverProjects[proj.code],
          selectedType: projType
        });
        break;
      case "B":
        project = new Project({
          id: proj.id,
          turn: this.currentPlayerTurn,
          type: proj.code[0],
          playSceneConfig: this.config,
          scene: this,
          texture: "projectCard",
          frame: proj.frame,
          details: bronzeProjects[proj.code],
          selectedType: projType
        });
        break;
      default:
    }

    project.setAlpha(0);
    this.projects.add(project);
    return project;
  }

  endLemming(project) {
    project.setAlpha(1);
    project.setDepth(2);
    project.setPosition(
      this.config.width / 2 + PIPELINE_PLACEMENT_POSX,
      this.config.height / 2 + 117 * devicePixelRatio
    );
    project.cashIn();
  }
}
