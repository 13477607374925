import { calculateDenomination, getMoney, moneyFrameMap } from "../helpers/calculations";
import {
  BUTTON_VIEW_PROJ_FONTSIZE,
  CARD_BOX_HEIGHT,
  CARD_BOX_WIDTH,
  CASHIN_TEXT,
  CIRCLE_POSX,
  CIRCLE_POSY,
  CIRCLE_RADIUS,
  DEV_DPI_RATIO,
  EXPRESS_BOX_POSX,
  EXPRESS_BOX_POSY,
  FLASH_BOX_POSX,
  FLASH_BOX_POSY,
  LONG_BOX_WIDTH,
  NORMAL_BOX_POSX,
  NORMAL_BOX_POSY,
  PANEL_POS,
  PIPELINE_PLACEMENT_POSX,
  PIPELINE_PLACEMENT_POSY,
  RELAXED_BOX_POSX,
  RELAXED_BOX_POSY,
  SMALL_BOX_HEIGHT,
  SMALL_BOX_WIDTH,
  UPCOMING_CARD_HEIGHT,
  UPCOMING_CARD_WIDTH,
  USABLE_OPP_TEXT,
} from "../helpers/dimensions";
import { Button } from "./Button";
import { Money } from "./Money";
import { Token } from "./Token";
import Phaser from "phaser";
import { abandonProject, startProject, updateCash, updateOpportunity, updateProject, updateTokens } from "../apis/put";
import { mainPath } from "../helpers/path";

export class Project extends Phaser.GameObjects.Sprite {
  constructor(settings) {
    // Constructor of Phaser.GameObjects.Sprite
    super(settings.scene, settings.x, settings.y, settings.texture,settings.frame);

    // Adding Project to the Parent Scene and setting some of its properties
    settings.scene.add.existing(this);
    this.setDisplaySize(UPCOMING_CARD_WIDTH, UPCOMING_CARD_HEIGHT)
      .setOrigin(0.5, 0.5)
      .setDepth(101);

    // Data Members
    let { normal, express, flash, relaxed, cost, revenue } = settings.details;

    this.id = settings.id;
    this.parentScene = settings.scene;
    this.config = settings.playSceneConfig;
    this.started = false;
    this.type = settings.type;
    this.id = settings.id;
    this.revenue = revenue;
    this.cost = cost;
    this.retainerActive = false;
    this.projectFrame = settings.flipFrame;
    this.flipTexture = settings.flipTexture;
    this.normal = normal;
    this.express = express;
    this.flash = flash;
    this.relaxed = relaxed;
    
    this.selectedType = settings.selectedType || "normal";
    switch(this.selectedType) {
      case "express" : 
      this.selected = express;
      break;
      case "flash":
      this.selected = flash;
      break;
      case "relaxed":
      this.selected = relaxed;
      break;
      default:
      this.selected = normal;
  }
    this.usableOpp = false;
  }

  spawn() {
    let projs = [...this.parentScene.panelProjects.getChildren()];
    this.parentScene.panelProjects.add(this);
    let slotY;

    slotY =
      PANEL_POS + 120 * devicePixelRatio + projs.length * 25 * devicePixelRatio;
    this.setDepth(projs.length + 1);

    let projectSpawnTween = this.parentScene.add.tweenchain({
      targets: this,
      tweens: [
        {
          x: LONG_BOX_WIDTH / 2 + PANEL_POS,
          y: slotY,
          scale: 0.38 * DEV_DPI_RATIO,
          duration: 500,
        },
        {
          scaleY: 0.0001,
          delay: 1000,
          duration: 200,
          onComplete: () => {
            this.setTexture(this.flipTexture);
            this.setFrame(this.projectFrame);
          },
        },
        {
          scale: 0.38 * DEV_DPI_RATIO,
          duration: 200,
          onComplete: () => {
            this.hoverStartY = this.y - 40 * devicePixelRatio;
            this.hoverEndY = this.y;
            if (!this.parentScene.disableProjects) {
              this.setInteractive({ cursor: "pointer" });
            }
            this.on("pointerup", () => {
              if (this.parentScene.canView) {
                this.viewProject();
              }
            });
            this.on("pointerover", () => {
              this.parentScene.add.tween({
                targets: this,
                scale: 0.39 * DEV_DPI_RATIO,
                duration: 50,
              });
            });
            this.on("pointerout", () => {
              this.parentScene.add.tween({
                targets: this,
                scale: 0.38 * DEV_DPI_RATIO,
                duration: 50,
              });
            });
          },
        },
      ],
      onComplete: () => {
        projectSpawnTween.destroy();
      },
    });
  }

  adjustCards() {
    let projs = [
      ...this.parentScene.panelProjects
        .getChildren()
        .filter((proj) => proj !== this),
    ];
    let slotY;

    projs.forEach((proj, i) => {
      if (i === 0) {
        slotY = PANEL_POS + 120 * devicePixelRatio;
      } else {
        slotY = PANEL_POS + 120 * devicePixelRatio + 25 * devicePixelRatio * i;
      }
      proj.setDepth(i + 1);
      this.parentScene.add.tween({
        targets: proj,
        y: slotY,
        duration: 200,
      });
    });
  }

  viewProject() {
    this.parentScene.canView = false;
    
    this.parentScene.cashPanel.setDepth(101);
    this.parentScene.tokenPanel.setDepth(101);
    this.removeInteractive();
    this.parentScene.darkness.setAlpha(0.8);
    

    let obj = {
      originalX: this.x,
      originalY: this.y,
      originalScale: 0.38 * DEV_DPI_RATIO,
      originalDepth: this.depth,
    };

    this.backBtn = new Button({
      playSceneConfig: this.config,
      scene: this.parentScene,
      texture: "buttons",
      frame: 0,
      frameActive: 1,
      text: "RESERVE",
      item: this,
      metaData: obj,
      action: this.endViewProject,
      x: this.config.width / 2 - 200 * devicePixelRatio,
      fontSize: `${BUTTON_VIEW_PROJ_FONTSIZE}px`,
      width : 160 * devicePixelRatio,
    });
    this.useBtn = new Button({
      disabled: this.disableUsing(),
      projectBtn: true,
      playSceneConfig: this.config,
      scene: this.parentScene,
      texture: "buttons",
      frame: 2,
      frameActive: 3,
      text: "USE",
      item: this,
      action: this.useProject,
      x: this.config.width / 2,
      fontSize: `${BUTTON_VIEW_PROJ_FONTSIZE}px`,
      width : 160 * devicePixelRatio,
    });
    this.discardBtn = new Button({
      playSceneConfig: this.config,
      scene: this.parentScene,
      texture: "buttons",
      frame: 4,
      frameActive: 5,
      text: "DISCARD",
      item: this,
      action: this.discardProject,
      x: this.config.width / 2 + 200 * devicePixelRatio,
      fontSize: `${BUTTON_VIEW_PROJ_FONTSIZE}px`,
      width : 160 * devicePixelRatio,
    });

    this.parentScene.viewingSomething = true;
    this.parentScene.viewing = {
      type: "P",
      card: this,
      metaData: obj,
      btn: this.backBtn,
    };

    this.setDepth(101);

    let opps = [...this.parentScene.opportunities.getChildren()];

    for(let i = 0; i < opps.length; i++) {
      if(opps[i].code === "O34") {
        this.usableOpp = opps[i];
        break;
      }
    }

    if(this.usableOpp) {

      this.usableOppCard = this.parentScene.add.image(this.config.width - 200 * devicePixelRatio,this.config.height / 2 - 20 * devicePixelRatio, this.usableOpp.texture,this.usableOpp.frame).setScale(0.5 * DEV_DPI_RATIO).setDepth(101).setAlpha(0).setInteractive({cursor: 'pointer'});
      this.usableTickActive = false;
      this.usableOppTick = this.parentScene.add.image(this.config.width - 200 * devicePixelRatio,this.config.height / 2 - 20 * devicePixelRatio, 'selected').setScale(0.5 * DEV_DPI_RATIO).setDepth(102).setAlpha(0);
      
      this.usableOppCard.on("pointerover", () => {
        this.parentScene.add.tween({
          targets: [this.usableOppCard,this.usableOppTick],
          scale: 0.55 * DEV_DPI_RATIO,
          duration: 50,
        });
      });
      this.usableOppCard.on("pointerup", () => {
        if(this.usableTickActive) {
          this.parentScene.add.tween({
            targets: this.usableOppTick,
            alpha: 0,
            duration: 100,
          });
          this.usableTickActive = false;
        } else {
          this.parentScene.add.tween({
            targets: this.usableOppTick,
            alpha: 1,
            duration: 100,
          });
          this.usableTickActive = true;
        }
        if(this.disableUsing()) {
          this.useBtn.disable();
        } else {
          this.useBtn.enable();
        }
      });

      this.usableOppCard.on("pointerout", () => {
        this.parentScene.add.tween({
          targets: [this.usableOppCard,this.usableOppTick],
          scale: 0.5 * DEV_DPI_RATIO,
          duration: 50,
        });
      });
      
      this.usableOppCardText = this.parentScene.add.text(this.config.width - 200 * devicePixelRatio,this.config.height / 2 + 120 * devicePixelRatio,"CLICK TO ADD / REMOVE",{padding:{right: 4},shadow:{offsetX: 2,offsetY: 2,fill:true,blur:3},fontStyle: '1000',fontFamily: 'Night',fontSize: `${USABLE_OPP_TEXT}px`}).setOrigin(0.5,0.5).setDepth(101).setAlpha(0);
    }

    this.box1 = this.parentScene.add
          .rectangle(
            this.usableOpp ? this.config.width / 2 - NORMAL_BOX_POSX - 120 *devicePixelRatio : this.config.width / 2 - NORMAL_BOX_POSX,
            this.config.height / 2 - NORMAL_BOX_POSY,
            CARD_BOX_WIDTH,
            CARD_BOX_HEIGHT
          )
          .setDepth(102)
          .setScale(2)
          .setInteractive({ cursor: "pointer" });
        this.box2 = this.parentScene.add
          .rectangle(
            this.usableOpp ? this.config.width / 2 + EXPRESS_BOX_POSX - 120 *devicePixelRatio : this.config.width / 2 + EXPRESS_BOX_POSX,
            this.config.height / 2 - EXPRESS_BOX_POSY,
            CARD_BOX_WIDTH,
            CARD_BOX_HEIGHT
          )
          .setDepth(102)
          .setScale(2)
          .setInteractive({ cursor: "pointer" });
        this.box3 = this.parentScene.add
          .rectangle(
            this.usableOpp ? this.config.width / 2 - FLASH_BOX_POSX - 120 *devicePixelRatio : this.config.width / 2 - FLASH_BOX_POSX,
            this.config.height / 2 + FLASH_BOX_POSY,
            CARD_BOX_WIDTH,
            CARD_BOX_HEIGHT
          )
          .setDepth(102)
          .setScale(2)
          .setInteractive({ cursor: "pointer" });
        this.box4 = this.parentScene.add
          .rectangle(
            this.usableOpp ? this.config.width / 2 + RELAXED_BOX_POSX - 120 *devicePixelRatio : this.config.width / 2 + RELAXED_BOX_POSX,
            this.config.height / 2 + RELAXED_BOX_POSY,
            CARD_BOX_WIDTH,
            CARD_BOX_HEIGHT
          )
          .setDepth(102)
          .setScale(2)
          .setInteractive({ cursor: "pointer" });
        this.radio = this.parentScene.add
          .image(this.box1.x - CIRCLE_POSX, this.box1.y + CIRCLE_POSY, "radio")
          .setDisplaySize(CIRCLE_RADIUS, CIRCLE_RADIUS)
          .setDepth(102)
          .setAlpha(0);
        this.selectedBox = this.box1;

    this.parentScene.add.tween({
      targets: [this.usableOppCard,this.usableOppCardText],
      alpha: 1,
      duration: 400,
      ease: Phaser.Math.Easing.Sine.Out,
    });

    this.parentScene.add.tween({
      targets: this,
      scale: 1.12 * DEV_DPI_RATIO,
      duration: 400,
      x: this.usableOpp ? this.config.width / 2 - 120 * devicePixelRatio : this.config.width / 2,
      y: this.config.height / 2 - 20 * devicePixelRatio,
      ease: Phaser.Math.Easing.Sine.Out,
      onComplete: () => {
        this.useBtn.appear();
        this.discardBtn.appear();
        this.backBtn.appear();
        this.radio.setAlpha(1);

        this.box1.on("pointerover", () => {
          if (this.selected !== "normal")
            this.box1.setStrokeStyle(2 * devicePixelRatio, 0x000000);
        });
        this.box1.on("pointerup", () => {
          this.selected = this.normal;
          this.selectedType = "normal";
          this.uncheckAll();
          this.selectedBox = this.box1;
          this.radio.setPosition(
            this.box1.x - CIRCLE_POSX,
            this.box1.y + CIRCLE_POSY
          );
        });
        this.box1.on("pointerout", () => {
          this.box1.setStrokeStyle(0, 0x000000);
        });
        this.box2.on("pointerover", () => {
          if (this.selectedType !== "express")
            this.box2.setStrokeStyle(2 * devicePixelRatio, 0x000000);
        });
        this.box2.on("pointerup", () => {
          this.selected = this.express;
          this.selectedType = "express";
          this.uncheckAll();
          this.selectedBox = this.box2;
          this.radio.setPosition(
            this.box2.x - CIRCLE_POSX,
            this.box2.y + CIRCLE_POSY
          );
        });
        this.box2.on("pointerout", () => {
          this.box2.setStrokeStyle(0, 0x000000);
        });
        this.box3.on("pointerover", () => {
          if (this.selected !== "flash")
            this.box3.setStrokeStyle(2 * devicePixelRatio, 0x000000);
        });
        this.box3.on("pointerup", () => {
          this.selected = this.flash;
          this.selectedType = "flash";
          this.uncheckAll();
          this.selectedBox = this.box3;
          this.radio.setPosition(
            this.box3.x - CIRCLE_POSX,
            this.box3.y + CIRCLE_POSY
          );
        });
        this.box3.on("pointerout", () => {
          this.box3.setStrokeStyle(0, 0x000000);
        });
        this.box4.on("pointerover", () => {
          if (this.selected !== "relaxed")
            this.box4.setStrokeStyle(2 * devicePixelRatio, 0x000000);
        });
        this.box4.on("pointerup", () => {
          this.selected = this.relaxed;
          this.selectedType = "relaxed";

          this.uncheckAll();
          this.selectedBox = this.box4;
          this.radio.setPosition(
            this.box4.x - CIRCLE_POSX,
            this.box4.y + CIRCLE_POSY
          );
        });
        this.box4.on("pointerout", () => {
          this.box4.setStrokeStyle(0, 0x000000);
        });
      },
    });
  }

  uncheckAll() {
    this.box1.setStrokeStyle(0, 0x000000);
    this.box2.setStrokeStyle(0, 0x000000);
    this.box3.setStrokeStyle(0, 0x000000);
    this.box4.setStrokeStyle(0, 0x000000);

    if (this.disableUsing()) {
      this.useBtn.disable();
    } else {
      this.useBtn.enable();
    }
  }

  endViewProject(metaData, btn) {

    this.selectedType = "normal";
    this.selected = this.normal;
    this.parentScene.viewingSomething = false;
    this.parentScene.canView = true;

    let { originalX, originalY, originalScale, originalDepth } = metaData;
    this.box1.destroy();
    this.box2.destroy();
    this.box3.destroy();
    this.box4.destroy();
    this.radio.destroy();
    this.parentScene.add.tween({
      targets: [this.useBtn, this.discardBtn],
      scale: 0,
      duration: 300,
      onComplete: () => {
        this.useBtn.destroy();
        this.discardBtn.destroy();
      },
    });

    if(this.usableOpp) {
      this.usableOpp = false;
      this.usableOppCard.removeInteractive();
      this.usableTickActive = false;
      this.parentScene.add.tween({
        targets: [this.usableOppCard, this.usableOppCardText,this.usableOppTick],
        alpha: 0,
        duration: 300,
        onComplete: () => {
          this.usableOppCard.destroy(true);
          this.usableOppCardText.destroy(true);
          this.usableOppTick.destroy(true);
        },
      });
    }
    

    this.parentScene.add.tween({
      targets: this,
      scale: originalScale,
      duration: 300,
      x: originalX,
      y: originalY,
      ease: Phaser.Math.Easing.Sine.Out,
      onComplete: () => {
        this.setDepth(originalDepth);
        this.setInteractive({ cursor: "pointer" });
      },
    });

    this.parentScene.add.tween({
      targets: this.parentScene.darkness,
      alpha: 0,
      duration: 400,
    });

    this.parentScene.add.tween({
      targets: btn,
      scale: 0,
      duration: 300,
      onComplete: () => {
        btn.destroy();
        this.parentScene.cashPanel.setDepth(30);
        this.parentScene.tokenPanel.setDepth(30);
      },
    });
  }

  validateConnections() {
    if (this.parentScene.connections >= this.selected.connections) return true;

    return false;
  }

  validatePeople() {
    if (this.parentScene.people >= this.selected.people) return true;

    return false;
  }

  validateCash() {
    if (this.parentScene.cash >= this.cost) {
      return true;
    }
    
    if(this.usableOpp && this.usableTickActive) {
      if (this.parentScene.cash + this.usableOpp.money >= this.cost) {
        return true;
      }
    }

    return false;
  }

  disableUsing() {
    let valPeople = this.validatePeople();
    let valConnections = this.validateConnections();
    let valCash = this.validateCash();

    if (valPeople && valConnections && valCash && !this.parentScene.noPlayActive) {
      return false;
    }

    return true;
  }

  async useProject(metaData, btn) {
    this.parentScene.viewingSomething = false;
    this.parentScene.projects.add(this);
    this.parentScene.panelProjects.remove(this);
    this.adjustCards();
    this.box1.destroy();
    this.box2.destroy();
    this.box3.destroy();
    this.box4.destroy();
    this.radio.destroy();

    this.parentScene.add.tween({
      targets: [this.discardBtn, btn, this.backBtn],
      scale: 0,
      duration: 300,
      onComplete: () => {
        btn.destroy();
        this.discardBtn.destroy();
        this.backBtn.destroy();
      },
    });

    let notes;

    if(this.usableOpp) {
      this.usableOppCard.removeInteractive();
    }

    if(this.usableOpp && this.usableTickActive) {
      notes = getMoney(Math.max(0, this.cost - this.usableOpp.money))

    } else {
      notes = getMoney(this.cost);
    }
    if(notes.length > 0) {
      let noteCounter = 0;
      await new Promise((resolve) => {
        let moneyInterval = setInterval(() => {
          let mon = new Money({
            texture: "money",
            frame: moneyFrameMap[notes[noteCounter]],
            playSceneConfig: this.config,
            scene: this.parentScene,
            x: PANEL_POS,
            y: this.config.height - PANEL_POS - SMALL_BOX_HEIGHT,
          });
          mon.lose();
          this.parentScene.cash -= notes[noteCounter];
          this.parentScene.cashAmountText.setText(
            calculateDenomination(this.parentScene.cash)
          );

          noteCounter++;
          if (noteCounter === notes.length) {
            clearInterval(moneyInterval);
            resolve();
          }
        }, 200);
      });
    }

    await this.peopleMovement({
      get: false,
      x:
        this.parentScene.tokenPanel.x -
        SMALL_BOX_WIDTH / 2 -
        48 * devicePixelRatio,
      y: this.parentScene.tokenPanel.y - SMALL_BOX_HEIGHT / 2,
      endX: this.selectedBox.x,
      endY: this.selectedBox.y,
    });
    await this.connectionMovement({
      get: false,
      x: this.parentScene.tokenPanel.x - 40 * devicePixelRatio,
      y: this.parentScene.tokenPanel.y - SMALL_BOX_HEIGHT / 2,
      endX: this.selectedBox.x,
      endY: this.selectedBox.y,
    });

    if(this.usableOpp) {
      if(this.usableTickActive) {
        this.usableOpp.adjustCards();
        updateOpportunity(this.usableOpp.id);
        this.usableOpp.destroy(true);
      }
      this.usableOpp = false;
      this.usableTickActive = false;
      this.parentScene.add.tween({
        targets: [this.usableOppCard, this.usableOppCardText,this.usableOppTick],
        alpha: 0,
        duration: 300,
        onComplete: () => {
          this.usableOppCard.destroy(true);
          this.usableOppCardText.destroy(true);
          this.usableOppTick.destroy(true);
        },
      });
    }
    

    this.parentScene.add.tween({
      targets: this,
      x: this.config.width / 2 - PIPELINE_PLACEMENT_POSX,
      y: this.config.height / 2 - PIPELINE_PLACEMENT_POSY,
      scale: 0.16 * DEV_DPI_RATIO,
      duration: 600,
      delay: 1000,
      ease: Phaser.Math.Easing.Back.In,
      onComplete: () => {
        this.parentScene.add.tween({
          targets: this.parentScene.darkness,
          alpha: 0,
          duration: 200,
          onComplete: () => {
            this.setDepth(1);
            this.setAlpha(0);
            this.parentScene.tokenPanel.setDepth(30);
            this.parentScene.cashPanel.setDepth(30);
            this.parentScene.canView = true;
            startProject(this.id, this.selectedType,this.selected.time);
            updateCash(
              this.parentScene.properties.id,
              this.parentScene.properties.pid,
              this.parentScene.cash
            );
            updateTokens(
              this.parentScene.properties.id,
              this.parentScene.properties.pid,
              this.parentScene.people,
              this.parentScene.connections
            );
            const path = mainPath;
            // const graphics = this.parentScene.add.graphics();
            // graphics.lineStyle(1, 0xffffff, 1);
            // path.draw(graphics, 128);

            this.lemming = this.parentScene.add
              .follower(
                path,
                this.config.width / 2 - PIPELINE_PLACEMENT_POSX,
                this.config.height / 2 - PIPELINE_PLACEMENT_POSY,
                this.flipTexture,
                this.projectFrame
              )
              .setScale(0.16);

            this.lemming.startFollow({
              duration: 1000 * this.selected.time,
              rotateToPath: false,
              verticalAdjust: true,
              onComplete: () => {
                this.setAlpha(1);
                this.setDepth(2);
                this.setPosition(this.lemming.x, this.lemming.y);
                this.lemming.destroy();
                this.cashIn();
              },
            });
          },
        });
      },
    });
  }

  discardProject(metaData, btn) {
    this.parentScene.viewingSomething = false;
    this.parentScene.discardingContainer = true;
    this.parentScene.discarded = this;
    this.parentScene.canView = false;
    this.box1.destroy();
    this.box2.destroy();
    this.box3.destroy();
    this.box4.destroy();
    this.radio.destroy();

    if(this.usableOpp) {
      this.usableOpp = false;
      this.usableOppCard.removeInteractive();
      this.usableTickActive = false;
      this.parentScene.add.tween({
        targets: [this.usableOppCard, this.usableOppCardText,this.usableOppTick],
        alpha: 0,
        duration: 200,
        onComplete: () => {
          this.usableOppCard.destroy(true);
          this.usableOppCardText.destroy(true);
          this.usableOppTick.destroy(true);
        },
      });
    }

    this.parentScene.path = { t: 0, vec: new Phaser.Math.Vector2() };
    const startPoint = new Phaser.Math.Vector2(this.x, this.y);
    const controlPoint1 = new Phaser.Math.Vector2(500, 100);
    const controlPoint2 = new Phaser.Math.Vector2(600, 100);
    const endPoint = new Phaser.Math.Vector2(
      this.config.width + 400 * devicePixelRatio,
      this.y
    );

    this.parentScene.curve = new Phaser.Curves.CubicBezier(
      startPoint,
      controlPoint1,
      controlPoint2,
      endPoint
    );

    this.parentScene.tweens.add({
      targets: this.parentScene.path,
      t: 1,
      ease: Phaser.Math.Easing.Sine.InOut,
      duration: 1500,
      onComplete: () => {
        this.parentScene.discardingContainer = false;
        this.destroy();
        this.adjustCards();
        this.parentScene.canView = true;
        this.parentScene.tokenPanel.setDepth(30);
        this.parentScene.cashPanel.setDepth(30);
        abandonProject(this.id);
      },
    });

    this.parentScene.add.tween({
      targets: this.parentScene.darkness,
      alpha: 0,
      duration: 400,
    });

    this.parentScene.add.tween({
      targets: [this.useBtn, btn, this.backBtn],
      scale: 0,
      duration: 300,
      onComplete: () => {
        this.useBtn.destroy();
        btn.destroy();
        this.backBtn.destroy();
      },
    });
  }

  async cashIn() {
    this.parentScene.projects.remove(this);
    this.parentScene.add.tween({
      targets: this,
      scale: 0.3 * DEV_DPI_RATIO,
      duration: 300,
      ease: Phaser.Math.Easing.Back.Out,
      onComplete: () => {
        this.complete();
      },
    });
  }

  async complete() {
    await this.peopleMovement({
      get: true,
      endX:
        this.parentScene.tokenPanel.x -
        SMALL_BOX_WIDTH / 2 -
        48 * devicePixelRatio,
      endY: this.parentScene.tokenPanel.y - SMALL_BOX_HEIGHT / 2,
      x: this.x - this.displayWidth / 2 + 62 * devicePixelRatio,
      y: this.y + this.displayHeight / 2 - 47 * devicePixelRatio,
    });
    await this.connectionMovement({
      get: true,
      endX: this.parentScene.tokenPanel.x - 40 * devicePixelRatio,
      endY: this.parentScene.tokenPanel.y - SMALL_BOX_HEIGHT / 2,
      x: this.x - this.displayWidth / 2 + 130 * devicePixelRatio,
      y: this.y + this.displayHeight / 2 - 47 * devicePixelRatio,
    });
    if (!this.parentScene.noRevenueActive) {
      await this.moneyMovement();
    }

    updateProject(this.id, this.selectedType);
    updateTokens(
      this.parentScene.properties.id,
      this.parentScene.properties.pid,
      this.parentScene.people,
      this.parentScene.connections
    );

    this.parentScene.add.tween({
      targets: [this],
      scale: 0,
      duration: 300,
      onComplete: () => {
        this.destroy();
      },
    });
  }

  async peopleMovement({ x, y, endX, endY, get }) {
    let pCounter = 0;
    await new Promise((resolve) => {
      let peopleInterval = setInterval(() => {
        if (this.selected.people !== 0) {
          let tkn = new Token({
            texture: "tokens",
            frame: 0,
            playSceneConfig: this.config,
            scene: this.parentScene,
            x: x,
            y: y,
            endX: endX,
            endY: endY,
          });
          if (get) {
            tkn.setDepth(101);
          }
          tkn.animate();

          if (get) this.parentScene.people++;
          else {
            this.parentScene.people--;
          }

          this.parentScene.peopleText.setText(this.parentScene.people);

          pCounter++;

          if (pCounter === this.selected.people) {
            clearInterval(peopleInterval);
            resolve();
          }
        } else {
          clearInterval(peopleInterval);
          resolve();
        }
      }, 50);
    });
  }

  async connectionMovement({ x, y, endX, endY, get }) {
    let cCounter = 0;
    await new Promise((resolve) => {
      let connectionsInterval = setInterval(() => {
        if (this.selected.connections !== 0) {
          let tkn = new Token({
            texture: "tokens",
            frame: 1,
            playSceneConfig: this.config,
            scene: this.parentScene,
            x: x,
            y: y,
            endX: endX,
            endY: endY,
          });
          if (get) {
            tkn.setDepth(101);
          }
          tkn.animate();

          if (get) this.parentScene.connections++;
          else {
            this.parentScene.connections--;
          }

          this.parentScene.connectionsText.setText(
            this.parentScene.connections
          );
          cCounter++;

          if (cCounter === this.selected.connections) {
            clearInterval(connectionsInterval);
            resolve();
          }
        } else {
          clearInterval(connectionsInterval);
          resolve();
        }
      }, 50);
    });
  }

  async moneyMovement() {
    await new Promise((resolve) => {
      let additionalMoney = 0;
      switch (this.selectedType) {
        case "express":
          additionalMoney = 1;
          break;
        case "flash":
          additionalMoney = 3;
          break;
        case "relaxed":
          additionalMoney = -1;
          break;
        default:
      }
      let amount = this.revenue + additionalMoney;
      let moneyText = this.parentScene.add
        .text(this.x, this.y, calculateDenomination(amount), {
          padding: { right: 4 },
          shadow: { offsetX: 2, offsetY: 2, fill: true, blur: 3 },
          fontStyle: 1000,
          fontFamily: "Night",
          fontSize: `${CASHIN_TEXT}px`,
          color: "#1434A4",
        })
        .setScale(0)
        .setDepth(3)
        .setOrigin(0.5, 0.5);

      this.parentScene.add.tween({
        targets: moneyText,
        x: this.x,
        y: this.y,
        duration: 300,
        scale: 1,
        onComplete: () => {
          let notes = getMoney(amount);
          let noteCounter = 0;
          let moneyInterval = setInterval(() => {
            let mon = new Money({
              texture: "money",
              frame: moneyFrameMap[notes[noteCounter]],
              playSceneConfig: this.config,
              scene: this.parentScene,
              x: this.x,
              y: this.y,
              endX: PANEL_POS + SMALL_BOX_WIDTH / 2,
              endY: this.config.height - SMALL_BOX_HEIGHT / 2 - PANEL_POS,
            }).setDepth(101);
            mon.get();
            this.parentScene.cash += notes[noteCounter];
            amount -= notes[noteCounter];
            this.parentScene.cashAmountText.setText(
              calculateDenomination(this.parentScene.cash)
            );
            moneyText.setText(calculateDenomination(amount));
            noteCounter++;
            if (noteCounter === notes.length) {
              updateCash(
                this.parentScene.properties.id,
                this.parentScene.properties.pid,
                this.parentScene.cash
              );
              clearInterval(moneyInterval);
              this.parentScene.add.tween({
                targets: [moneyText],
                alpha: 0,
                duration: 300,
                onComplete: () => {
                  moneyText.destroy();
                  resolve();
                },
              });
            }
          }, 200);
        },
      });
    });
  }

  
}
