import { useState } from "react";
import styles from "../styles/Login.module.css";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../App";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from 'axios';
import Letters from "../components/Letters";
import start from '../assets/logo2.png';

const Login = () => {

    // eslint-disable-next-line no-unused-vars
    const { user,setUser } = useContext(UserContext);

    const navigate = useNavigate();

    // eslint-disable-next-line no-unused-vars
    const [backEndFail, setBackendFail] = useState(false);

    const [incorrectField, setIncorrectField] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [loginDetails, setLoginDetails] = useState({
        loginEmail: "",
        loginPassword: "",
    });

    const handleChange2 = (e) => {
        let temp = { ...loginDetails };
        temp[e.target.id] = e.target.value;
        if (e.target.id === "loginEmail" || e.target.id === "loginPassword") {
            if (incorrectField) setIncorrectField(false);
        }
        setLoginDetails(temp);
    };

    const handleLogin = (e) => {
        e.preventDefault();
        const tempuser = {};
        tempuser.email = loginDetails.loginEmail;
        tempuser.password = loginDetails.loginPassword;

        if (tempuser.email !== "" && tempuser.password !== "") {
            try {
                axios.post(process.env.REACT_APP_API_URL + 'users/login', tempuser)
                .then((result)=>{

                    if (result.data.auth) {
                        let temp = {...result.data}
                        let user = {...temp.data};
                        setUser(user);
                        localStorage.setItem("tkn", JSON.stringify(temp.token));
                        setLoginDetails({
                            loginEmail: "",
                            loginPassword: "",
                        });
                        navigate("/sat-administrator/dashboard");
                     } else {
                         setIncorrectField(true);
                     }
                })
            } 
            catch (error) {
                console.log('Error: ', error);
            }
        } else setIncorrectField(true);
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);

    return (
        <>
            <Letters/>
            <div className={styles.loginBox}>
                <div className={styles.loginLogo}><img style={{width: '50%'}} src={start} alt="START-A-THON" /></div>
                <div className={styles.loginB}>
                    <div className={styles.loginText}>LOGIN</div>
                </div>
                <form className={styles.txtflds}>
                    {backEndFail && (
                        <div className={styles.backEndFail}>ERROR ! SERVER DOWN !</div>
                    )}
                    {incorrectField && (
                        <div className={styles.backEndFail}>
                            Incorrect Login Email or Password
                        </div>
                    )}
                    <TextField
                        name="loginEmail"
                        inputProps={{ style: { height: "20px" } }}
                        error={incorrectField}
                        fullWidth
                        onChange={handleChange2}
                        id="loginEmail"
                        value={loginDetails.loginEmail}
                        margin="dense"
                        type="text"
                        label="Email"
                        variant="outlined"
                    />
                    <TextField
                        name="loginPassword"
                        inputProps={{ style: { height: "20px" } }}
                        error={incorrectField}
                        fullWidth
                        onChange={handleChange2}
                        id="loginPassword"
                        value={loginDetails.loginPassword}
                        margin="dense"
                        type={showPassword ? "text" : "password"}
                        label="Password"
                        variant="outlined"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <button onClick={handleLogin} className={styles.loginBtns} type="submit">
                        Login
                    </button>
                </form>
            </div>
        </>
    );
};

export default Login;
