import { LOSING_RESOURCES_SPEED, MONEY_HEIGHT, MONEY_WIDTH, TOKEN_PANEL_COUNT_FONTSIZE } from "../helpers/dimensions";
import Phaser from "phaser";

export class Money extends Phaser.Physics.Arcade.Image {
    constructor(settings) {
        
        // Constructor of Phaser.Physics.Arcade.Sprite
        super(settings.scene,settings.x,settings.y,settings.texture,settings.frame);

        // Adding Money to the Parent Scene and setting some of its properties
        settings.scene.physics.add.existing(this);
        settings.scene.add.existing(this);
        this.setOrigin(0,0.5).setDisplaySize(MONEY_WIDTH,MONEY_HEIGHT).setDepth(101).setAlpha(0);
        
        // Data Members
        this.parentScene = settings.scene
        this.config = settings.playSceneConfig
        this.endX = settings.endX;
        this.endY = settings.endY;
    }


    lose() {
        let minus = this.parentScene.add.text(this.x - 10*devicePixelRatio,this.y,'-',{fontStyle: '1000',fontFamily: 'Night',fontSize: `${TOKEN_PANEL_COUNT_FONTSIZE}px`}).setOrigin(0.5,0.5).setDepth(101);
        this.parentScene.physics.add.existing(minus); 
        minus.body.velocity.y = LOSING_RESOURCES_SPEED;
        this.setVelocityY(LOSING_RESOURCES_SPEED);
        this.parentScene.add.tween({
            targets: [this,minus],
            alpha: 1,
            duration: 100,
            onComplete: () => {
                let moneyInterval = setInterval(()=> {
                    if(this.y < 400*devicePixelRatio) {
                        this.parentScene.add.tween({
                            targets: [this,minus],
                            alpha: 0,
                            duration : 100,
                            onComplete: () => {
                                this.destroy();
                                minus.destroy();
                                clearInterval(moneyInterval)
                            }
                        })  
                    }
                },1)
            } 
        })
    }

    get() {
        this.setOrigin(0.5,0.5)
        this.alpha = 1;
        this.parentScene.add.tween({
            targets: this,
            duration: 800,
            x: this.endX,
            y: this.endY,
            ease: Phaser.Math.Easing.Sine.Out,
            onComplete: () => {
                this.parentScene.add.tween({
                    targets: this,
                    scale: 0,
                    duration: 100,
                    ease: Phaser.Math.Easing.Sine.In,
                    onComplete: () => {
                        this.destroy();
                    }
                })
                
            }
        })
    }
 
} 