export const bronzeProjects = {
  B1: {
    revenue: 3,
    cost: 1,
    normal: {
      time: 50,
      people: 3,
      connections: 2,
    },
    express: {
      time: 40,
      people: 4,
      connections: 3,
    },
    flash: {
      time: 30,
      people: 5,
      connections: 4,
    },
    relaxed: {
      time: 60,
      people: 2,
      connections: 1,
    },
  },
  B2: {
    revenue: 3,
    cost: 1,
    normal: {
      time: 50,
      people: 2,
      connections: 3,
    },
    express: {
      time: 40,
      people: 3,
      connections: 4,
    },
    flash: {
      time: 30,
      people: 4,
      connections: 5,
    },
    relaxed: {
      time: 60,
      people: 1,
      connections: 2,
    },
  },
  B3: {
    revenue: 4,
    cost: 2,
    normal: {
      time: 50,
      people: 4,
      connections: 4,
    },
    express: {
      time: 40,
      people: 5,
      connections: 5,
    },
    flash: {
      time: 30,
      people: 6,
      connections: 6,
    },
    relaxed: {
      time: 60,
      people: 3,
      connections: 3,
    },
  },
  B4: {
    revenue: 4,
    cost: 2,
    normal: {
      time: 50,
      people: 4,
      connections: 3,
    },
    express: {
      time: 40,
      people: 5,
      connections: 4,
    },
    flash: {
      time: 30,
      people: 6,
      connections: 5,
    },
    relaxed: {
      time: 60,
      people: 3,
      connections: 2,
    },
  },
  B5: {
    revenue: 3,
    cost: 1.5,
    normal: {
      time: 50,
      people: 2,
      connections: 2,
    },
    express: {
      time: 40,
      people: 3,
      connections: 3,
    },
    flash: {
      time: 30,
      people: 4,
      connections: 4,
    },
    relaxed: {
      time: 60,
      people: 1,
      connections: 1,
    },
  },
  B6: {
    revenue: 5,
    cost: 2,
    normal: {
      time: 50,
      people: 5,
      connections: 4,
    },
    express: {
      time: 40,
      people: 6,
      connections: 5,
    },
    flash: {
      time: 30,
      people: 7,
      connections: 6,
    },
    relaxed: {
      time: 60,
      people: 4,
      connections: 3,
    },
  },
  B7: {
    revenue: 5,
    cost: 2,
    normal: {
      time: 50,
      people: 4,
      connections: 5,
    },
    express: {
      time: 40,
      people: 5,
      connections: 6,
    },
    flash: {
      time: 30,
      people: 6,
      connections: 7,
    },
    relaxed: {
      time: 60,
      people: 3,
      connections: 4,
    },
  },
  B8: {
    revenue: 5,
    cost: 2,
    normal: {
      time: 50,
      people: 5,
      connections: 5,
    },
    express: {
      time: 40,
      people: 6,
      connections: 6,
    },
    flash: {
      time: 30,
      people: 7,
      connections: 7,
    },
    relaxed: {
      time: 60,
      people: 4,
      connections: 4,
    },
  },
  B9: {
    revenue: 5,
    cost: 2.5,
    normal: {
      time: 50,
      people: 4,
      connections: 4,
    },
    express: {
      time: 40,
      people: 5,
      connections: 5,
    },
    flash: {
      time: 30,
      people: 6,
      connections: 6,
    },
    relaxed: {
      time: 60,
      people: 3,
      connections: 3,
    },
  },
  B10: {
    revenue: 3,
    cost: 1.5,
    normal: {
      time: 50,
      people: 1,
      connections: 4,
    },
    express: {
      time: 40,
      people: 2,
      connections: 5,
    },
    flash: {
      time: 30,
      people: 3,
      connections: 6,
    },
    relaxed: {
      time: 60,
      people: 0,
      connections: 3,
    },
  },
  B11: {
    revenue: 3,
    cost: 1.5,
    normal: {
      time: 50,
      people: 5,
      connections: 2,
    },
    express: {
      time: 40,
      people: 6,
      connections: 3,
    },
    flash: {
      time: 30,
      people: 7,
      connections: 4,
    },
    relaxed: {
      time: 60,
      people: 4,
      connections: 1,
    },
  },
  B12: {
    revenue: 4,
    cost: 2.5,
    normal: {
      time: 50,
      people: 4,
      connections: 2,
    },
    express: {
      time: 40,
      people: 5,
      connections: 3,
    },
    flash: {
      time: 30,
      people: 6,
      connections: 4,
    },
    relaxed: {
      time: 60,
      people: 3,
      connections: 1,
    },
  },
  B13: {
    revenue: 4,
    cost: 2.5,
    normal: {
      time: 50,
      people: 2,
      connections: 5,
    },
    express: {
      time: 40,
      people: 3,
      connections: 6,
    },
    flash: {
      time: 30,
      people: 4,
      connections: 7,
    },
    relaxed: {
      time: 60,
      people: 1,
      connections: 4,
    },
  },
};

export const silverProjects = {
  S1: {
    revenue: 6,
    cost: 2.5,
    normal: {
      time: 50,
      people: 6,
      connections: 6,
    },
    express: {
      time: 40,
      people: 8,
      connections: 8,
    },
    flash: {
      time: 30,
      people: 10,
      connections: 10,
    },
    relaxed: {
      time: 60,
      people: 4,
      connections: 4,
    },
  },
  S2: {
    revenue: 7,
    cost: 3,
    normal: {
      time: 50,
      people: 8,
      connections: 7,
    },
    express: {
      time: 40,
      people: 10,
      connections: 9,
    },
    flash: {
      time: 30,
      people: 12,
      connections: 11,
    },
    relaxed: {
      time: 60,
      people: 6,
      connections: 5,
    },
  },
  S3: {
    revenue: 9,
    cost: 4,
    normal: {
      time: 50,
      people: 8,
      connections: 8,
    },
    express: {
      time: 40,
      people: 10,
      connections: 10,
    },
    flash: {
      time: 30,
      people: 12,
      connections: 12,
    },
    relaxed: {
      time: 60,
      people: 6,
      connections: 6,
    },
  },
  S4: {
    revenue: 10,
    cost: 4,
    normal: {
      time: 50,
      people: 10,
      connections: 8,
    },
    express: {
      time: 40,
      people: 12,
      connections: 10,
    },
    flash: {
      time: 30,
      people: 14,
      connections: 12,
    },
    relaxed: {
      time: 60,
      people: 8,
      connections: 6,
    },
  },
  S5: {
    revenue: 11,
    cost: 4.5,
    normal: {
      time: 50,
      people: 10,
      connections: 7,
    },
    express: {
      time: 40,
      people: 12,
      connections: 9,
    },
    flash: {
      time: 30,
      people: 14,
      connections: 11,
    },
    relaxed: {
      time: 60,
      people: 8,
      connections: 5,
    },
  },
  S6: {
    revenue: 13,
    cost: 6,
    normal: {
      time: 50,
      people: 10,
      connections: 8,
    },
    express: {
      time: 40,
      people: 12,
      connections: 10,
    },
    flash: {
      time: 30,
      people: 14,
      connections: 12,
    },
    relaxed: {
      time: 60,
      people: 8,
      connections: 6,
    },
  },
  S7: {
    revenue: 8,
    cost: 5,
    normal: {
      time: 50,
      people: 6,
      connections: 8,
    },
    express: {
      time: 40,
      people: 8,
      connections: 10,
    },
    flash: {
      time: 30,
      people: 10,
      connections: 12,
    },
    relaxed: {
      time: 60,
      people: 4,
      connections: 6,
    },
  },
  S8: {
    revenue: 9,
    cost: 4,
    normal: {
      time: 50,
      people: 7,
      connections: 6,
    },
    express: {
      time: 40,
      people: 9,
      connections: 8,
    },
    flash: {
      time: 30,
      people: 11,
      connections: 10,
    },
    relaxed: {
      time: 60,
      people: 5,
      connections: 4,
    },
  },
  S9: {
    revenue: 10,
    cost: 4,
    normal: {
      time: 50,
      people: 9,
      connections: 9,
    },
    express: {
      time: 40,
      people: 11,
      connections: 11,
    },
    flash: {
      time: 30,
      people: 13,
      connections: 13,
    },
    relaxed: {
      time: 60,
      people: 7,
      connections: 7,
    },
  },
  S10: {
    revenue: 12,
    cost: 5,
    normal: {
      time: 50,
      people: 8,
      connections: 10,
    },
    express: {
      time: 40,
      people: 10,
      connections: 12,
    },
    flash: {
      time: 30,
      people: 12,
      connections: 14,
    },
    relaxed: {
      time: 60,
      people: 6,
      connections: 8,
    },
  },
  S11: {
    revenue: 12,
    cost: 5,
    normal: {
      time: 50,
      people: 10,
      connections: 9,
    },
    express: {
      time: 40,
      people: 12,
      connections: 11,
    },
    flash: {
      time: 30,
      people: 14,
      connections: 13,
    },
    relaxed: {
      time: 60,
      people: 8,
      connections: 7,
    },
  },
  S12: {
    revenue: 14,
    cost: 5.5,
    normal: {
      time: 50,
      people: 9,
      connections: 10,
    },
    express: {
      time: 40,
      people: 11,
      connections: 12,
    },
    flash: {
      time: 30,
      people: 13,
      connections: 14,
    },
    relaxed: {
      time: 60,
      people: 7,
      connections: 8,
    },
  },
  S13: {
    revenue: 15,
    cost: 6.5,
    normal: {
      time: 50,
      people: 10,
      connections: 10,
    },
    express: {
      time: 40,
      people: 12,
      connections: 12,
    },
    flash: {
      time: 30,
      people: 14,
      connections: 14,
    },
    relaxed: {
      time: 60,
      people: 8,
      connections: 8,
    },
  },
  S14: {
    revenue: 15,
    cost: 7.5,
    normal: {
      time: 50,
      people: 9,
      connections: 9,
    },
    express: {
      time: 40,
      people: 11,
      connections: 11,
    },
    flash: {
      time: 30,
      people: 13,
      connections: 13,
    },
    relaxed: {
      time: 60,
      people: 7,
      connections: 7,
    },
  },
  S15: {
    revenue: 13,
    cost: 4.5,
    normal: {
      time: 50,
      people: 7,
      connections: 7,
    },
    express: {
      time: 40,
      people: 9,
      connections: 9,
    },
    flash: {
      time: 30,
      people: 11,
      connections: 11,
    },
    relaxed: {
      time: 60,
      people: 5,
      connections: 5,
    },
  },
  S16: {
    revenue: 8,
    cost: 3,
    normal: {
      time: 50,
      people: 6,
      connections: 7,
    },
    express: {
      time: 40,
      people: 8,
      connections: 9,
    },
    flash: {
      time: 30,
      people: 10,
      connections: 11,
    },
    relaxed: {
      time: 60,
      people: 4,
      connections: 5,
    },
  },
  S17: {
    revenue: 7,
    cost: 2.5,
    normal: {
      time: 50,
      people: 7,
      connections: 6,
    },
    express: {
      time: 40,
      people: 9,
      connections: 8,
    },
    flash: {
      time: 30,
      people: 11,
      connections: 10,
    },
    relaxed: {
      time: 60,
      people: 5,
      connections: 4,
    },
  },
  S18: {
    revenue: 14,
    cost: 6,
    normal: {
      time: 50,
      people: 9,
      connections: 9,
    },
    express: {
      time: 40,
      people: 11,
      connections: 11,
    },
    flash: {
      time: 30,
      people: 13,
      connections: 13,
    },
    relaxed: {
      time: 60,
      people: 7,
      connections: 7,
    },
  },
  S19: {
    revenue: 11,
    cost: 4.5,
    normal: {
      time: 50,
      people: 10,
      connections: 7,
    },
    express: {
      time: 40,
      people: 12,
      connections: 9,
    },
    flash: {
      time: 30,
      people: 14,
      connections: 11,
    },
    relaxed: {
      time: 60,
      people: 8,
      connections: 5,
    },
  },
  S20: {
    revenue: 13,
    cost: 6,
    normal: {
      time: 50,
      people: 10,
      connections: 8,
    },
    express: {
      time: 40,
      people: 12,
      connections: 10,
    },
    flash: {
      time: 30,
      people: 14,
      connections: 12,
    },
    relaxed: {
      time: 60,
      people: 8,
      connections: 6,
    },
  },
};

export const goldProjects = {
  G1: {
    revenue: 16,
    cost: 7,
    normal: {
      time: 50,
      people: 11,
      connections: 11,
    },
    express: {
      time: 40,
      people: 14,
      connections: 14,
    },
    flash: {
      time: 30,
      people: 16,
      connections: 16,
    },
    relaxed: {
      time: 60,
      people: 8,
      connections: 8,
    },
  },
  G2: {
    revenue: 17,
    cost: 7.5,
    normal: {
      time: 50,
      people: 12,
      connections: 11,
    },
    express: {
      time: 40,
      people: 15,
      connections: 14,
    },
    flash: {
      time: 30,
      people: 18,
      connections: 17,
    },
    relaxed: {
      time: 60,
      people: 10,
      connections: 9,
    },
  },
  G3: {
    revenue: 18,
    cost: 8,
    normal: {
      time: 50,
      people: 13,
      connections: 12,
    },
    express: {
      time: 40,
      people: 16,
      connections: 15,
    },
    flash: {
      time: 30,
      people: 18,
      connections: 17,
    },
    relaxed: {
      time: 60,
      people: 10,
      connections: 9,
    },
  },
  G4: {
    revenue: 19,
    cost: 9,
    normal: {
      time: 50,
      people: 12,
      connections: 12,
    },
    express: {
      time: 40,
      people: 15,
      connections: 15,
    },
    flash: {
      time: 30,
      people: 17,
      connections: 17,
    },
    relaxed: {
      time: 60,
      people: 9,
      connections: 9,
    },
  },
  G5: {
    revenue: 20,
    cost: 9,
    normal: {
      time: 50,
      people: 11,
      connections: 13,
    },
    express: {
      time: 40,
      people: 14,
      connections: 16,
    },
    flash: {
      time: 30,
      people: 16,
      connections: 18,
    },
    relaxed: {
      time: 60,
      people: 8,
      connections: 10,
    },
  },
  G6: {
    revenue: 21,
    cost: 10,
    normal: {
      time: 50,
      people: 12,
      connections: 13,
    },
    express: {
      time: 40,
      people: 15,
      connections: 16,
    },
    flash: {
      time: 30,
      people: 17,
      connections: 18,
    },
    relaxed: {
      time: 60,
      people: 9,
      connections: 10,
    },
  },
  G7: {
    revenue: 24,
    cost: 11,
    normal: {
      time: 50,
      people: 14,
      connections: 12,
    },
    express: {
      time: 40,
      people: 17,
      connections: 15,
    },
    flash: {
      time: 30,
      people: 19,
      connections: 17,
    },
    relaxed: {
      time: 60,
      people: 11,
      connections: 9,
    },
  },
  G8: {
    revenue: 22,
    cost: 10,
    normal: {
      time: 50,
      people: 13,
      connections: 12,
    },
    express: {
      time: 40,
      people: 16,
      connections: 15,
    },
    flash: {
      time: 30,
      people: 18,
      connections: 17,
    },
    relaxed: {
      time: 60,
      people: 10,
      connections: 9,
    },
  },
  G9: {
    revenue: 26,
    cost: 11,
    normal: {
      time: 50,
      people: 13,
      connections: 14,
    },
    express: {
      time: 40,
      people: 16,
      connections: 17,
    },
    flash: {
      time: 30,
      people: 18,
      connections: 19,
    },
    relaxed: {
      time: 60,
      people: 10,
      connections: 11,
    },
  },
  G10: {
    revenue: 28,
    cost: 12,
    normal: {
      time: 50,
      people: 14,
      connections: 14,
    },
    express: {
      time: 40,
      people: 17,
      connections: 17,
    },
    flash: {
      time: 30,
      people: 19,
      connections: 19,
    },
    relaxed: {
      time: 60,
      people: 11,
      connections: 11,
    },
  },
  G11: {
    revenue: 30,
    cost: 15,
    normal: {
      time: 50,
      people: 15,
      connections: 15,
    },
    express: {
      time: 40,
      people: 18,
      connections: 18,
    },
    flash: {
      time: 30,
      people: 20,
      connections: 20,
    },
    relaxed: {
      time: 60,
      people: 12,
      connections: 12,
    },
  },
  G12: {
    revenue: 18,
    cost: 8,
    normal: {
      time: 50,
      people: 13,
      connections: 11,
    },
    express: {
      time: 40,
      people: 16,
      connections: 14,
    },
    flash: {
      time: 30,
      people: 18,
      connections: 16,
    },
    relaxed: {
      time: 60,
      people: 10,
      connections: 8,
    },
  },
  G13: {
    revenue: 24,
    cost: 9,
    normal: {
      time: 50,
      people: 14,
      connections: 13,
    },
    express: {
      time: 40,
      people: 17,
      connections: 16,
    },
    flash: {
      time: 30,
      people: 19,
      connections: 18,
    },
    relaxed: {
      time: 60,
      people: 11,
      connections: 10,
    },
  },
  G14: {
    revenue: 28,
    cost: 11,
    normal: {
      time: 50,
      people: 13,
      connections: 15,
    },
    express: {
      time: 40,
      people: 16,
      connections: 18,
    },
    flash: {
      time: 30,
      people: 18,
      connections: 20,
    },
    relaxed: {
      time: 60,
      people: 10,
      connections: 12,
    },
  },
  G15: {
    revenue: 20,
    cost: 9,
    normal: {
      time: 50,
      people: 12,
      connections: 12,
    },
    express: {
      time: 40,
      people: 15,
      connections: 15,
    },
    flash: {
      time: 30,
      people: 17,
      connections: 17,
    },
    relaxed: {
      time: 60,
      people: 9,
      connections: 9,
    },
  },
};

export const hurdles = {
  H11: {
    action: "token",
    type: "connections",
    tokens: 1,
    description: "Lose 1",
  },
  H12: {
    action: "token",
    type: "people",
    tokens: 1,
    description: "Lose 1",
  },
  H13: {
    action: "token",
    type: "connections",
    tokens: 2,
    description: "Lose 2",
  },
  H14: {
    action: "token",
    type: "people",
    tokens: 2,
    description: "Lose 2",
  },
  H32: {
    action: "noRevenue",
    description: "No Revenue",
  },
  H31: {
    action: "noPlay",
    description: "No Play",
  },
  H41: {
    action: "move",
    from: "express",
    to: "normal",
    description: "All Projects Moved",
  },
  H42: {
    action: "move",
    from: "flash",
    to: "express",
    description: "All Projects Moved",
  },
  H43: {
    action: "move",
    from: "normal",
    to: "relaxed",
    description: "All Projects Moved",
  },
  H51: {
    action: "delay",
    time: 10,
    description: "All Projects Delayed",
  },
  H52: {
    action: "delay",
    time: 15,
    description: "All Projects Delayed",
  },
};

export const opportunities = {
  O11: {
    action: "token",
    type: "connections",
    tokens: 2,
    description: "Get 2",
  },
  O12: {
    action: "token",
    type: "people",
    tokens: 2,
    description: "Get 2",
  },
  O13: {
    action: "token",
    type: "connections",
    tokens: 3,
    description: "Get 3",
  },
  O14: {
    action: "token",
    type: "people",
    tokens: 3,
    description: "Get 3",
  },
  O31: {
    action: "deploy",
    in: "express",
    for: "normal",
    description: "Deploy",
  },
  O32: {
    action: "deploy",
    in: "flash",
    for: "express",
    description: "Deploy",
  },
  O33: {
    action: "discount",
    money: 1,
    description: "Project Cost Reduced",
  },
  O34: {
    action: "discount",
    money: 2,
    description: "Project Cost Reduced",
  },
  O41: {
    action: "money",
    money: 3,
    description: "Claim 3 L",
  },
  O51: {
    action: "prepone",
    time: -10,
    description: "All Project Completion Time Reduced",
  },
  O52: {
    action: "prepone",
    time: -15,
    description: "All Project Completion Time Reduced",
  },
};

const bronzeMap = [
  { frame: 0, code: "B1" },
  { frame: 1, code: "B2" },
  { frame: 2, code: "B3" },
  { frame: 3, code: "B4" },
  { frame: 4, code: "B5" },
  { frame: 5, code: "B6" },
  { frame: 6, code: "B7" },
  { frame: 7, code: "B8" },
  { frame: 8, code: "B9" },
  { frame: 9, code: "B10" },
  { frame: 10, code: "B11" },
  { frame: 11, code: "B12" },
  { frame: 12, code: "B13" },
];

const silverMap = [
  { frame: 13, code: "S1" },
  { frame: 14, code: "S2" },
  { frame: 15, code: "S3" },
  { frame: 16, code: "S4" },
  { frame: 17, code: "S5" },
  { frame: 18, code: "S6" },
  { frame: 19, code: "S7" },
  { frame: 20, code: "S8" },
  { frame: 21, code: "S9" },
  { frame: 22, code: "S10" },
  { frame: 23, code: "S11" },
  { frame: 24, code: "S12" },
  { frame: 25, code: "S13" },
  { frame: 26, code: "S14" },
  { frame: 27, code: "S15" },
  { frame: 28, code: "S16" },
  { frame: 29, code: "S17" },
  { frame: 30, code: "S18" },
  { frame: 31, code: "S19" },
  { frame: 32, code: "S20" },
];

const goldMap = [
  { frame: 33, code: "G1" },
  { frame: 34, code: "G2" },
  { frame: 35, code: "G3" },
  { frame: 36, code: "G4" },
  { frame: 37, code: "G5" },
  { frame: 38, code: "G6" },
  { frame: 39, code: "G7" },
  { frame: 40, code: "G8" },
  { frame: 41, code: "G9" },
  { frame: 42, code: "G10" },
  { frame: 43, code: "G11" },
  { frame: 44, code: "G12" },
  { frame: 45, code: "G13" },
  { frame: 46, code: "G14" },
  { frame: 47, code: "G15" },
];

const hurdleMap = [
  { frame: 0, code: "H11" },
  { frame: 1, code: "H11" },
  { frame: 2, code: "H11" },
  { frame: 3, code: "H11" },
  { frame: 4, code: "H12" },
  { frame: 5, code: "H12" },
  { frame: 6, code: "H12" },
  { frame: 7, code: "H12" },
  { frame: 8, code: "H13" },
  { frame: 9, code: "H13" },
  { frame: 10, code: "H13" },
  { frame: 11, code: "H14" },
  { frame: 12, code: "H14" },
  { frame: 13, code: "H14" },
  // {frame: 14, code: 'H31'},
  // {frame: 15, code: 'H31'},
  // {frame: 16, code: 'H32'},
  // {frame: 17, code: 'H32'},
  // {frame: 18, code: 'H51'},
  // {frame: 19, code: 'H51'},
  // {frame: 20, code: 'H51'},
  // {frame: 21, code: 'H52'},
  // {frame: 22, code: 'H52'},
  // {frame: 23, code: 'H52'},
  // {frame: 24, code: 'H41'},
  // {frame: 25, code: 'H41'},
  // {frame: 26, code: 'H42'},
  // {frame: 27, code: 'H42'},
  // {frame: 28, code: 'H43'},
  // {frame: 29, code: 'H43'},
];

const opportunityMap = [
  { frame: 0, code: "O11" },
  { frame: 1, code: "O11" },
  { frame: 2, code: "O11" },
  { frame: 3, code: "O11" },
  { frame: 4, code: "O11" },
  { frame: 5, code: "O12" },
  { frame: 6, code: "O12" },
  { frame: 7, code: "O12" },
  { frame: 8, code: "O12" },
  { frame: 9, code: "O12" },
  { frame: 10, code: "O13" },
  { frame: 11, code: "O13" },
  { frame: 12, code: "O13" },
  { frame: 13, code: "O14" },
  { frame: 14, code: "O14" },
  { frame: 15, code: "O14" },
  // {frame: 16, code: 'O31'},
  // {frame: 17, code: 'O31'},
  // {frame: 18, code: 'O32'},
  // {frame: 19, code: 'O32'},
  // {frame: 20, code: 'O33'},
  // {frame: 21, code: 'O33'},
  // {frame: 22, code: 'O34'},
  // {frame: 23, code: 'O34'},
  { frame: 24, code: "O41" },
  { frame: 25, code: "O41" },
  // {frame: 26, code: 'O51'},
  // {frame: 27, code: 'O51'},
  // {frame: 28, code: 'O52'},
  // {frame: 29, code: 'O52'},
];

const randomize = (map) => {
  let arr = [...map];
  let lastElem = map.length - 1;

  while (lastElem !== 0) {
    let selected = Math.floor(Math.random() * lastElem);
    let temp = arr[lastElem];
    arr[lastElem] = arr[selected];
    arr[selected] = temp;
    lastElem--;
  }

  return arr;
};

export const getDeck = (type) => {
  switch (type) {
    case "bronze":
      return randomize(bronzeMap);
    case "silver":
      return randomize(silverMap);
    case "gold":
      return randomize(goldMap);
    case "hurdle":
      return randomize(hurdleMap);
    case "opportunity":
      return randomize(opportunityMap);
    default:
  }
};
