export const DEV_DPI_RATIO = devicePixelRatio / 1.25;
export const PIPELINE_WIDTH = 600 * devicePixelRatio;
export const PIPELINE_HEIGHT = 400 * devicePixelRatio;
export const LIGHTSOUT_WIDTH = 800 * devicePixelRatio;
export const LIGHTSOUT_HEIGHT = 220 * devicePixelRatio;
export const PIPELINE_POSY = 30 * devicePixelRatio;
export const PROJECT_PIPELINE_TEXT_POSY = 260 * devicePixelRatio;
export const USABLE_OPP_TEXT = 25 * devicePixelRatio;
export const PANEL_POS = 25 * devicePixelRatio;
export const GLOBAL_TIMER_POSY = 5 * devicePixelRatio;
export const EXTRA_TIME_POSY = 15 * devicePixelRatio;
export const OUTSIDE_SCREEN = -100 * devicePixelRatio;
export const UPCOMING_CARDS_TEXT_POSY = 190 * devicePixelRatio;
export const UPCOMING_CARDS_CARDS_POSY = -80 * devicePixelRatio;
export const UPCOMING_CARDS_TIMERS_POSY = -52 * devicePixelRatio;
export const UPCOMING_CARDS_B_POSX = -254 * devicePixelRatio;
export const UPCOMING_CARDS_S_POSX = -127 * devicePixelRatio;
export const UPCOMING_CARDS_G_POSX = 0 * devicePixelRatio;
export const UPCOMING_CARDS_H_POSX = 127 * devicePixelRatio;
export const UPCOMING_CARDS_O_POSX = 254 * devicePixelRatio;
export const PANEL_HEADING_FONTSIZE = 20 * devicePixelRatio;
export const ZOOM_BOARD_COMPANY_FONTSIZE = 30 * devicePixelRatio;
export const FONTSIZE_FIXED = 32 * devicePixelRatio;
export const FONTSIZE_DILEMMA = 35 * devicePixelRatio;
export const MARKET_RADIUS = 208 * devicePixelRatio;
export const LONG_BOX_WIDTH = 240 * devicePixelRatio;
export const LONG_BOX_HEIGHT = 500 * devicePixelRatio;
export const SMALL_BOX_WIDTH = 240 * devicePixelRatio;
export const SMALL_BOX_HEIGHT = 108 * devicePixelRatio;
export const PROJECT_PANEL_TEXT_POSX = (LONG_BOX_WIDTH / 2);
export const PROJECT_PANEL_TEXT_POSY = 16 * devicePixelRatio;
export const OPPORTUNITY_PANEL_TEXT_POSX= (-LONG_BOX_WIDTH / 2);
export const OPPORTUNITY_PANEL_TEXT_POSY = 16 * devicePixelRatio;
export const CASH_PANEL_TEXT_POSX = SMALL_BOX_WIDTH / 2;
export const CASH_PANEL_TEXT_POSY = -SMALL_BOX_HEIGHT + 5.6 * devicePixelRatio;
export const TOKEN_PANEL_TEXT_POSX = -SMALL_BOX_WIDTH / 2;
export const TOKEN_PANEL_TEXT_POSY = -SMALL_BOX_HEIGHT + 5.6 * devicePixelRatio;
export const CASH_PANEL_AMOUNT_FONTSIZE = 48 * devicePixelRatio;
export const GLOBAL_TIMER_FONTSIZE = 55 * devicePixelRatio;
export const NO_PLAY_FONTSIZE = 30 * devicePixelRatio;
export const EXTRA_TIME_TEXT = 30 * devicePixelRatio;
export const TITLE_SECTION_FONTSIZE = 25 * devicePixelRatio;
export const UPCOMING_CARDS_TIMERS_FONTSIZE = 25 * devicePixelRatio;
export const TOKEN_PANEL_COUNT_FONTSIZE = 56 * devicePixelRatio;
export const CASH_PANEL_AMOUNT_POSX = SMALL_BOX_WIDTH / 2;
export const CASH_PANEL_AMOUNT_POSY = -SMALL_BOX_HEIGHT / 2 + 16 * devicePixelRatio;
export const PEOPLE_TEXT_POSX = -SMALL_BOX_WIDTH + 16 * devicePixelRatio;
export const PEOPLE_TEXT_POSY = -SMALL_BOX_HEIGHT / 2 + 8 * devicePixelRatio;
export const CONNECTION_TEXT_POSX = -SMALL_BOX_WIDTH / 2 + 16 * devicePixelRatio;
export const CONNECTION_TEXT_POSY = -SMALL_BOX_HEIGHT / 2 + 8 * devicePixelRatio;
export const PROJECT_SLOT_POSX_DEFAULT = LONG_BOX_WIDTH / 2;
export const PROJECT_SLOT_POSY_DEFAULT = 148 * devicePixelRatio;
export const BLANK_PROJECT_WIDTH = 208 * devicePixelRatio;
export const BLANK_PROJECT_HEIGHT = 136 * devicePixelRatio;
export const PROJECT_DICE_END_POSX = -40 * devicePixelRatio;
export const MARKOPPHUR_DICE_END_POSX = 40 * devicePixelRatio;
export const DICE_END_POSY = 72 * devicePixelRatio;
export const BUTTON_DEFAULT_FONTSIZE = 40 * devicePixelRatio;
export const BUTTON_VIEW_PROJ_FONTSIZE = 34 * devicePixelRatio;
export const BUTTON_DEFAULT_WIDTH = 216 * devicePixelRatio;
export const BUTTON_DEFAULT_HEIGHT = 72 * devicePixelRatio;
export const NO_PROJECT_FONTSIZE = 160 * devicePixelRatio;
export const PROJECT_CARD_WIDTH = 672 * devicePixelRatio;
export const PROJECT_CARD_HEIGHT = 440 * devicePixelRatio;
export const UPCOMING_CARD_WIDTH = 112 * devicePixelRatio;
export const UPCOMING_CARD_HEIGHT = 72 * devicePixelRatio;
export const BACK_BTN_TOP_LEFT_FONTSIZE = 24*devicePixelRatio;
export const BACK_BTN_TOP_LEFT_WIDTH = 144 * devicePixelRatio;
export const BACK_BTN_TOP_LEFT_HEIGHT = 48 * devicePixelRatio;
export const BACK_BTN_TOP_LEFT_POSX = 88 * devicePixelRatio;
export const BACK_BTN_TOP_LEFT_POSY = 40 * devicePixelRatio;
export const ENDTURN_BTN_TOP_LEFT_FONTSIZE = 24*devicePixelRatio;
export const ENDTURN_BTN_TOP_LEFT_WIDTH = 144 * devicePixelRatio;
export const ENDTURN_BTN_TOP_LEFT_HEIGHT = 48 * devicePixelRatio;
export const ENDTURN_BTN_TOP_LEFT_POSX = 220 * devicePixelRatio;
export const ENDTURN_BTN_TOP_LEFT_POSY = 40 * devicePixelRatio;
export const TOKEN_SIZE = 58 * devicePixelRatio;
export const MONEY_WIDTH = 240 * devicePixelRatio;
export const MONEY_HEIGHT = 95 * devicePixelRatio;
export const GAME_NAME_HEADING_FONTSIZE = 50 * devicePixelRatio;
export const COMPANY_NAME_HEADING_FONTSIZE = 40 * devicePixelRatio;
export const COMPANY_NAME_HEADING_FONTSIZE2 = 50 * devicePixelRatio;
export const SUMMARY_HEADING_FONTSIZE = 50 * devicePixelRatio;
export const TEXTFIELD_WIDTH = 480 * devicePixelRatio;
export const TEXTFIELD_HEIGHT = 80 * devicePixelRatio;
export const SMALL_TOKEN_SIZE  = 35 * devicePixelRatio;
export const NAMETAG_HEIGHT = 50 * devicePixelRatio;
export const BLINKER_WIDTH = 5 * devicePixelRatio;
export const BLINKER_HEIGHT = 48 * devicePixelRatio;
export const LOSING_RESOURCES_SPEED = -80 * devicePixelRatio;
export const FALLING_CALENDAR_VELOCITY = 600 * devicePixelRatio;
export const RESTRICT_TEXT = 280 * devicePixelRatio;
export const GAME_OVER_TEXT = 200 * devicePixelRatio;
export const NO_REVENUE_TEXT = 45 * devicePixelRatio;
export const CASHIN_TEXT = 50 * devicePixelRatio;
export const FRAME_END_PROJECT_WIDTH = 103 * devicePixelRatio;
export const FRAME_END_PROJECT_HEIGHT = 36 * devicePixelRatio;
export const CHOOSETEXT_FONTSIZE = 100 * devicePixelRatio;
export const STEAL_TEXT_FONTSIZE = 70 * devicePixelRatio;
export const CHOOSE_HURDLE_CARD_WIDTH = 436 * devicePixelRatio;
export const CHOOSE_HURDLE_CARD_HEIGHT = 180 * devicePixelRatio;
export const MONTH_FONTSIZE = 150 * devicePixelRatio;
export const CALENDAR_WIDTH = 180 * devicePixelRatio;
export const CALENDAR_HEIGHT = 260 * devicePixelRatio;
export const SUMMARY_WIDTH = 1000 * devicePixelRatio;
export const SUMMARY_HEIGHT = 560 * devicePixelRatio;
export const SUMMARY_TABLE_HEADING_WIDTH = 400 * devicePixelRatio;
export const SUMMARY_TABLE_HEADING_HEIGHT = 32 * devicePixelRatio;
export const SUMMARY_TABLE_CELL_LEFT = 280 * devicePixelRatio;
export const SUMMARY_TABLE_CELL_RIGHT = 120 * devicePixelRatio;
export const UNCERTAINTY_CELL_LEFT = 220 * devicePixelRatio;
export const UNCERTAINTY_CELL_MIDDLE = 90 * devicePixelRatio;
export const UNCERTAINTY_CELL_RIGHT = 90 * devicePixelRatio;
export const OTHER_CELL_LEFT = 130 * devicePixelRatio;
export const OTHER_CELL_MIDDLE1 = 90 * devicePixelRatio;
export const OTHER_CELL_MIDDLE2 = 90 * devicePixelRatio;
export const OTHER_CELL_RIGHT = 90 * devicePixelRatio;
export const SUMMARY_MAIN_CELL_FONTSIZE = 18 * devicePixelRatio;
export const SUMMARY_CELL_FONTSIZE = 18 * devicePixelRatio;
export const VAL_FONTSIZE = 18 * devicePixelRatio;
export const ERROR_FONTSIZE = 30 * devicePixelRatio;
export const MOMTH_FONTSIZE_SUMMARY = 35 * devicePixelRatio;
export const VAL_PANEL_WIDTH = 480 * devicePixelRatio;
export const VAL_PANEL_HEIGHT = 60 * devicePixelRatio;
export const ERROR_BLOB_WIDTH = 352 * devicePixelRatio;
export const ERROR_BLOB_HEIGHT = 208 * devicePixelRatio;
export const NO_REVENUE_WIDTH = 336 * devicePixelRatio;
export const NO_REVENUE_HEIGHT = 220 * devicePixelRatio;
export const FULLSCREEN_BTN_SIDE = 50 * devicePixelRatio;
export const FULLSCREEN_FONT = 20 * devicePixelRatio;
export const NORMAL_BOX_POSX = 55.5 * devicePixelRatio;
export const NORMAL_BOX_POSY = 47.5 * devicePixelRatio;
export const EXPRESS_BOX_POSX = 176.5 * devicePixelRatio;
export const EXPRESS_BOX_POSY = 47.5 * devicePixelRatio;
export const FLASH_BOX_POSX = 55.5 * devicePixelRatio;
export const FLASH_BOX_POSY = 80 * devicePixelRatio;
export const RELAXED_BOX_POSX = 176.5 * devicePixelRatio;
export const RELAXED_BOX_POSY = 80 * devicePixelRatio;
export const CARD_BOX_WIDTH = 97 * devicePixelRatio;
export const CARD_BOX_HEIGHT = 48 * devicePixelRatio;
export const CIRCLE_POSX = 58 * devicePixelRatio;
export const CIRCLE_POSY = 16 * devicePixelRatio;
export const CIRCLE_RADIUS = 32 * devicePixelRatio;
export const PIPELINE_PLACEMENT_POSX = 230 * devicePixelRatio;
export const PIPELINE_PLACEMENT_POSY = 180 * devicePixelRatio;
export const PIPELINE_CARD_WIDTH = 77 * devicePixelRatio;
export const PIPELINE_CARD_HEIGHT = 50 * devicePixelRatio;




