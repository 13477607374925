import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../App";

const Protected = () => {

    // eslint-disable-next-line no-unused-vars
    const {user,setUser} = useContext(UserContext);
    
    return(
       user !== '' ? <Outlet/> : <Navigate to='/login'/>
    );
}

export default Protected;