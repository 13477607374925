import React from "react";
import styles from "../styles/Results.module.css";
import { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import {
  calculateDenomination,
  getMoney,
} from "../phaser/helpers/calculations";
import people from "../assets/people.png";
import connections from "../assets/connections.png";
import money50 from "../assets/50L.png";
import money10 from "../assets/10L.png";
import money2 from "../assets/2L.png";
import money1 from "../assets/1L.png";
import money05 from "../assets/0.5L.png";
import { bronzeProjects, goldProjects, silverProjects } from "../phaser/helpers/cards";

const Results = () => {
  const notes = {
    50: money50,
    10: money10,
    2: money2,
    1: money1,
    0.5: money05,
  };

  const boxStyle = {
    width: "90%",
    marginLeft: "5%",
    boxSizing: "border-box",
    backgroundColor: "#E8C88F",
    boxShadow: 24,
    marginTop: "50px",
    top: "50%",
    left: "50%",
    borderRadius: "10px",
    p: 4,
  };

  const findImageByFrame = (frame) => {
    let xMult = frame % 6;
    let yMult = Math.floor(frame / 6);

    let temp = {
      backgroundPosition: `${-300 * xMult}px ${-195 * yMult}px`,
    };

    return temp;
  };

  const getProjectRevenue = (code) => {
    
    switch(code[0]) {
      case "B" :
        return bronzeProjects[code].revenue;
      case "S":
        return silverProjects[code].revenue;
      case "G":
        return goldProjects[code].revenue;
      default:
    }
  };

  const getPtype = (ptype) => {
    switch(ptype) {
      case 0:
        return "NORMAL";
      case 1:
        return "IMPORTANT"
      case 2:
        return "URGENT";
      default:
        return "RELAXED"
    }
  };

  const getTotalRevenue = (code,ptype) => {
    switch(ptype) {
      case 0:
        return getProjectRevenue(code);
      case 1:
        return getProjectRevenue(code) + 1;
      case 2:
        return getProjectRevenue(code) + 3;
      default:
        return getProjectRevenue(code) - 1;
    }
  }

  const getPtypeStyle = (ptype) => {
    switch(ptype) {
      case 0:
        return {
          color: "#00a0e3"
        };
      case 1:
        return {
          color: "#b0cb1f"
        };
      case 2:
        return {
          color: "#ae4a84"
        };
      default:
        return {
          color: "#ef7f1a"
        };
    }
  }

  const getPlayerAndMatchDetails = () => {
    let params = new URLSearchParams(window.location.search);
    axios
      .get(`${process.env.REACT_APP_API_URL}results/playerAndMatchDetails`, {
        params: {
          game: params.get("game"),
          pl: params.get("pl"),
        },
      })
      .then((response) => {
        let temp = { ...response.data.data };
        setPlayerAndMatchDetails(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllPlayedProjects = async () => {
    let temp = [];
    let params = new URLSearchParams(window.location.search);
    await axios
      .get(`${process.env.REACT_APP_API_URL}cards/allPlayedProjects`, {
        params: {
          game: params.get("game"),
          pl: params.get("pl"),
        },
      })
      .then((response) => {
        temp = response.data.data;
        setAllPlayedProjects(temp);
      })
      .catch((err) => {
        console.log(err);
      });

    return temp;
  };

  const getAllPlayedHurdles = async () => {
    let temp = [];
    let params = new URLSearchParams(window.location.search);
    await axios
      .get(`${process.env.REACT_APP_API_URL}cards/allPlayedHurdles`, {
        params: {
          game: params.get("game"),
          pl: params.get("pl"),
        },
      })
      .then((response) => {
        temp = response.data.data;
        setAllPlayedHurdles(temp);
      })
      .catch((err) => {
        console.log(err);
      });

    return temp;
  };

  const getAllPlayedOpportunities = async () => {
    let temp = [];
    let params = new URLSearchParams(window.location.search);
    await axios
      .get(`${process.env.REACT_APP_API_URL}cards/allPlayedOpportunities`, {
        params: {
          game: params.get("game"),
          pl: params.get("pl"),
        },
      })
      .then((response) => {
        temp = response.data.data;
        setAllPlayedOpportunities(temp);
      })
      .catch((err) => {
        console.log(err);
      });

    return temp;
  };

  const getResources = async () => {
    let temp = [];
    let params = new URLSearchParams(window.location.search);
    await axios
      .get(`${process.env.REACT_APP_API_URL}resources/getResources`, {
        params: {
          game: params.get("game"),
          pl: params.get("pl"),
        },
      })
      .then((response) => {
        temp = response.data.data;
        setResources(temp);
      })
      .catch((err) => {
        console.log(err);
      });

    return temp;
  };

  const getInitialResources = async () => {
    let temp = [];
    let params = new URLSearchParams(window.location.search);
    await axios
      .get(`${process.env.REACT_APP_API_URL}resources/getInitialResources`, {
        params: {
          game: params.get("game"),
        },
      })
      .then((response) => {
        temp = response.data.data;
        setInitialResources(temp);
      })
      .catch((err) => {
        console.log(err);
      });

    return temp;
  };

  const [playerAndMatchDetails, setPlayerAndMatchDetails] = useState({
    title: "",
    start_time: new Date(),
    name: "",
    email: "",
    cash: "",
    people: "",
    connections: "",
  });

  const [allPlayedProjects, setAllPlayedProjects] = useState([]);
  const [allPlayedHurdles, setAllPlayedHurdles] = useState([]);
  const [allPlayedOpportunities, setAllPlayedOpportunities] = useState([]);
  const [resources, setResources] = useState({
    cash: "",
    connections: "",
    people: "",
  });

  const [initialResources, setInitialResources] = useState({
    cash: "",
    connections: "",
    people: "",
  });

  useEffect(() => {
    getPlayerAndMatchDetails();
    getAllPlayedProjects();
    getAllPlayedHurdles();
    getAllPlayedOpportunities();
    getResources();
    getInitialResources();
  }, []);

  return (
    <div>
      <Box sx={boxStyle}>
        <Typography
          variant="h2"
          component="h2"
          textAlign={"center"}
          fontWeight={"bold"}
          letterSpacing={"2px"}
          fontFamily={"Fjalla"}
          sx={{ marginBottom: "5px" }}
        >
          RESULTS
        </Typography>
        <div className={styles.belowInfo}>
          Given below are all the decisions you made along with the cards you
          received !
        </div>
        <div className={styles.mainBox}>
          <div className={styles.gameHeading}>
            {playerAndMatchDetails?.title.toUpperCase()}
          </div>

          <div className={styles.attris}>
            Date:
            <span className={styles.attrisMail}>
              {format(playerAndMatchDetails?.start_time, "do MMMM u")}
            </span>
          </div>
          <div className={styles.attris}>
            Time:
            <span className={styles.attrisMail}>
              {format(playerAndMatchDetails?.start_time, "hh:mm b")}
            </span>
          </div>
          <div className={styles.attris}>
            Player :
            <span className={styles.attrisMail}>
              {playerAndMatchDetails?.name}
            </span>
          </div>
          <div className={styles.attris}>
            Email:
            <span className={styles.attrisMail}>
              {playerAndMatchDetails?.email}
            </span>
          </div>
        </div>
        <Divider />
        <div className={styles.mainTitle}>Revenue</div>
        <div className={styles.cardDivider}>
          <div className={styles.cardDividerSecondary}>
            <div className={styles.secondaryTitle}>Initial</div>
            <div className={styles.cashWrapper}>
              {calculateDenomination(parseInt(initialResources?.cash))}
            </div>
            <div className={styles.moneyStackWrapper}>
              {getMoney(parseInt(initialResources?.cash)).map((item, i) => {
                return (
                  <div className={styles.moneyStack} key={i}>
                    <img
                      className={styles.money}
                      src={notes[item]}
                      alt="Money"
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className={styles.cardDividerSecondary}>
            <div className={styles.secondaryTitle}>Final</div>
            <div className={styles.cashWrapper}>
              {calculateDenomination(parseInt(resources?.cash))}
            </div>
            <div className={styles.moneyStackWrapper}>
              {getMoney(parseInt(resources?.cash)).map((item, i) => {
                return (
                  <div className={styles.moneyStack} key={i}>
                    <img
                      className={styles.money}
                      src={notes[item]}
                      alt="Money"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <Divider />
        <div className={styles.mainTitle}>Tokens</div>
        <div className={styles.cardDivider}>
          <div className={styles.cardDividerSecondary}>
          <div className={styles.secondaryTitle}>Initial</div>
            <div className={styles.tokenWrapper}>
              <div className={styles.tokenBox}>
                <div>
                  {initialResources?.people}
                  <img className={styles.token} src={people} alt="People" />
                </div>
              </div>
              <div className={styles.tokenBox}>
                <span>
                  {initialResources?.connections}
                  <img
                    className={styles.token}
                    src={connections}
                    alt="Connections"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className={styles.cardDividerSecondary}>
          <div className={styles.secondaryTitle}>Final</div>
            <div className={styles.tokenWrapper}>
              <div className={styles.tokenBox}>
                <div>
                  {resources?.people}
                  <img className={styles.token} src={people} alt="People" />
                </div>
              </div>
              <div className={styles.tokenBox}>
                <span>
                  {resources?.connections}
                  <img
                    className={styles.token}
                    src={connections}
                    alt="Connections"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className={styles.mainTitle}>Projects Played</div>
        <div className={styles.cardDivider}>
          {allPlayedProjects
            ?.sort((a, b) => parseInt(a.frame) - parseInt(b.frame)) // Sort by frame
            .map((item, i) => (
              <div key={i} className={styles.projectDeets}>
              <div style={getPtypeStyle(item.ptype)} className={styles.projectDeetsSub}>{getPtype(item.ptype)}</div>
                <div
                style={findImageByFrame(parseInt(item.frame))}
                className={styles.projectSprite}
              ></div>
              <div className={styles.projectDeetsSub}>{`${getTotalRevenue(item.code,item.ptype)} L`}</div>
              </div>
              
            ))}
        </div>
        <Divider />
        <div className={styles.mainTitle}>Opportunities Played</div>
        <div className={styles.cardDivider}>
          {allPlayedOpportunities
            ?.sort((a, b) => parseInt(a.frame) - parseInt(b.frame)) // Sort by frame
            .map((item, i) => (
              <div
                key={i}
                style={findImageByFrame(parseInt(item.frame))}
                className={styles.opportunitySprite}
              ></div>
            ))}
        </div>
        <Divider />
        <div className={styles.mainTitle}>Hurdles Played</div>
        <div className={styles.cardDivider}>
          {allPlayedHurdles
            ?.sort((a, b) => parseInt(a.frame) - parseInt(b.frame)) // Sort by frame
            .map((item, i) => (
              <div
                key={i}
                style={findImageByFrame(parseInt(item.frame))}
                className={styles.hurdleSprite}
              ></div>
            ))}
        </div>
      </Box>
    </div>
  );
};

export default Results;
