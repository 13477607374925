import { useEffect, useState } from "react";
import styles from "../styles/ResetPassword.module.css";
import TextField from "@mui/material/TextField";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import Letters from "../components/Letters";
import validator from 'validator';

const ResetPassword = () => {

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  
  const navigate = useNavigate();
  const [backEndFail, setBackendFail] = useState(false);
  const [incorrectField, setIncorrectField] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [session, setSession] = useState(false);
  const [sessionExists, setSessionExists] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [resetDetails, setResetDetails] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const passErrors = {
    empty: "Please fill both the fields !",
    match: "Passwords do not match !",
    min: "Password should have minimum 8 characters",
    have: "Password should include atleast 1 Uppercase letter, 1 Lowercase letter, 1 Digit and 1 Symbol",
  };

  const handleChange = (e) => {
    let temp = { ...resetDetails };
    temp[e.target.id] = e.target.value;
    if (e.target.id === "newPassword" || e.target.id === "confirmPassword") {
      if (incorrectField) {
        setBackendFail(false);
        setIncorrectField(false);
      }
    }
    setResetDetails(temp);
  };

  const checkIfActive = () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}users/session`,
          {
            params: {
              token: searchParams.get("token"),
            },
          }
        )
        .then((result) => {
          setSession(result.data.active);
          setSessionExists(result.data.exists);
          setFetched(true);
        })
        .catch((err) => {
          setSessionExists(false);
          setFetched(true);
        });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    checkIfActive();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validatePasswords = () => {
    let flag = true;
    if (
      resetDetails.newPassword === "" &&
      resetDetails.confirmPassword === ""
    ) {
      flag = false;
      setPasswordError(passErrors.empty);
    } else if (resetDetails.newPassword !== resetDetails.confirmPassword) {
      flag = false;
      setPasswordError(passErrors.match);
    } else if (resetDetails.newPassword.length < 8) {
        flag = false;
        setPasswordError(passErrors.min);
    } else if (!validator.isStrongPassword(resetDetails.newPassword)) {
        flag = false;
        setPasswordError(passErrors.have);
    }

    if (!flag) {
      setIncorrectField(true);
    }

    return flag;
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    const obj = {};
    obj.token = searchParams.get("token");
    obj.password = resetDetails.newPassword;
    checkIfActive();
    let valPass = validatePasswords();

    if (valPass && session) {
      try {
        axios
          .put(`${process.env.REACT_APP_API_URL}users/resetPassword`, obj)
          .then((result) => {
            if(result.data.success) {
                navigate('/sat-administrator/login')
            } else {
                setBackendFail(true);
            }
          });
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  if (fetched)
    return (
      <>
        <Letters />
        <div className={styles.resetBox}>
          {session ? (
            <>
              <div className={styles.resetB}>
                <div className={styles.resetText}>RESET PASSWORD</div>
                <div className={styles.belowInfo}>
                  to gain access to the START-A-THON admin panel.
                </div>
              </div>
              <form className={styles.txtflds}>
                {backEndFail && (
                  <div className={styles.backEndFail}>
                    ERROR ! SERVER DOWN !
                  </div>
                )}
                {incorrectField && (
                  <div className={styles.backEndFail}>{passwordError}</div>
                )}
                <TextField
                  name="newPassword"
                  error={incorrectField}
                  fullWidth
                  onChange={handleChange}
                  id="newPassword"
                  value={resetDetails.newPassword}
                  margin="dense"
                  type={showPassword ? "text" : "password"}
                  label="New Password"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  name="confirmPassword"
                  error={incorrectField}
                  fullWidth
                  onChange={handleChange}
                  id="confirmPassword"
                  value={resetDetails.confirmPassword}
                  margin="dense"
                  type={showConfirmPassword ? "text" : "password"}
                  label="Password"
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <button
                  onClick={handleConfirm}
                  className={styles.loginBtns}
                  type="submit"
                >
                  Confirm
                </button>
              </form>
            </>
          ) : sessionExists ? (
            <div className={styles.resetB}>
              <div className={styles.resetText}>THE SESSION HAS EXPIRED !</div>
              <div className={styles.belowInfo}>
                Please contact the administrator to try again.
              </div>
            </div>
          ) : (
            <div className={styles.resetB}>
              <div className={styles.resetText}>404 NOT FOUND</div>
              <div className={styles.belowInfo}>
                Oops ! This web page does not exist !
              </div>
            </div>
          )}
        </div>
      </>
    );
};

export default ResetPassword;
