import React, { useState } from "react";
import Tutorial from "./Tutorial";
import RoomCode from "./RoomCode";
import logo from "../assets/logo2.png";
import { Box } from "@mui/material";
import styles from "../styles/Selector.module.css";
import Letters from "./Letters";

const Selector = ({ setCorrectCode }) => {
  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    backgroundColor: "#FFFFFF",
    boxShadow: 24,
    outline: "none",
    borderRadius: "10px",
    p: 5,
  };
  const [chosenOption, setChosenOption] = useState("");

  return (
    <div>
      <Letters />
      {chosenOption === "" && <Box sx={boxStyle}>
        <div className={styles.mainContainer}>
          <div className={styles.imgContainer}>
            <img className={styles.mainImg} src={logo} alt="START-A-THON" />
          </div>
          <div className={styles.btnContainer}>
            <button
              onClick={() => setChosenOption(0)}
              className={styles.menuBtn}
            >
              <span className={styles.menuBtnShadow}></span>
              <span className={styles.menuBtnEdge}></span>
              <span className={styles.menuBtnFront}>PLAY</span>
            </button>
          </div>
          <div className={styles.btnContainer}>
            <button
              onClick={() => setChosenOption(1)}
              className={styles.menuBtn}
            >
              <span className={styles.menuBtnShadow}></span>
              <span className={styles.menuBtnEdge}></span>
              <span className={styles.menuBtnFront}>HOW TO PLAY</span>
            </button>
          </div>
        </div>
      </Box>}
      {chosenOption === 0 && (
            <RoomCode
              setChosenOption={setChosenOption}
              setCorrectCode={setCorrectCode}
            />
          )}
        {chosenOption === 1 && <Tutorial setChosenOption={setChosenOption} />}
    </div>
  );
};

export default Selector;
