import React from 'react'
import back from '../assets/back.png';
import styles from '../styles/Back.module.css';

const Back2 = ({setChosenOption}) => {

    const handleBack = () => {
        setChosenOption("");
    }
 
  return (
    <div className={styles.mainBack} onClick={handleBack}>
        <img className={styles.backImg} src={back} alt='Back'/>
        <div className={styles.backText}>BACK</div>
    </div>
  )
}

export default Back2;