import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Typography,
} from "@mui/material";
import styles from "../styles/History.module.css";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import axios from "axios";
import { LoadingContext } from "../App";
import Navbar from "../components/Navbar";
import Back from "../components/Back";
import CollapsibleHistoryRow from "../components/CollapsibleHistoryRow";

const boxStyle = {
  width: "90%",
  backgroundColor: "white",
  boxShadow: 24,
  marginLeft: "5%",
  marginTop: "100px",
  position: "absolute",
  top: "0%",
  boxSizing: "border-box",
  borderRadius: "10px",
  p: 4,
};

const History = () => {
  const { setIsLoading } = useContext(LoadingContext);

  const columns = [
    {id: "#", label: "#"},
    { id: "title", label: "Title" },
    {
      id: "start_time",
      label: "Date",
    },
    {
      id: "players",
      label: "Players",
    },
    {
      id: "creator_name",
      label: "Created By",
    }
  ];

  const [allHistoryGames, setAllHistoryGames] = useState([]);

  const getAllHistoryGames = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}games/history`)
      .then((response) => {

        setAllHistoryGames(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getAllHistoryGames();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <>
    <Navbar/>
      <div className={styles.mainHistory}>
        <Back/>
        <Box sx={boxStyle}>
          <Typography
            variant="h4"
            component="h4"
            textAlign={"center"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
            fontFamily={"Fjalla"}
            sx={{ marginBottom: "5px" }}
          >
            HISTORY
          </Typography>
          <div className={styles.belowInfo}>
            All completed games are listed below along with their respective details.
          </div>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((column, i) => (
                      <TableCell
                        key={i}
                        style={{
                          fontFamily: "Poppins",
                          letterSpacing: "1px",
                          fontWeight: "bold",
                          textAlign: "center",
                          backgroundColor: "#B33951",
                          color: "white",
                        }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allHistoryGames
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((row, i) => {
                      return (
                          <CollapsibleHistoryRow key={i} columns={columns} row={row}/>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={allHistoryGames?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      </div>
    </>
  );
};

export default History;
