import React, { useEffect, useState } from "react";
import PhaserGame from "../components/PhaserGame";
import NotFound from "./NotFound";
import axios from "axios";
import Results from "../components/Results";
import Selector from "../components/Selector";

const GameRouter = () => {
  const [render, setRender] = useState("");
  const [correctCode, setCorrectCode] = useState(false);

  const isAccessible = async () => {
    let params = new URLSearchParams(window.location.search);

    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}games/access`, {
          params: {
            game: params.get("game"),
            pl: params.get("pl"),
          },
        })
        .then((result) => {
          if (result.data.accessible) {
            if (!result.data.expired) {
              if (result.data.open) {
                setRender("alreadyOpen");
              } else {
                setRender("active");
              }
            } else setRender("expired");
          } else {
            setRender("inactive");
          }
        });
    } catch (error) {
      console.log("Error: ", error);
      setRender("inactive");
    }
  };

  useEffect(() => {
    isAccessible();
  }, []);

  return render === "" ? (
    <></>
  ) : render === "active" ? (
    correctCode ? (
      <PhaserGame setRender={setRender}/>
    ) : (
      <Selector setCorrectCode={setCorrectCode} />
    )
  ) : render === "expired" ? (
    <Results setRender={setRender}/>
  ) : render === "alreadyOpen" ? (
    <NotFound
      text={"Multiple Instances Active"}
      subText={
        "Please ensure that multiple tabs are not open !"
      }
    />
  ) :(
    <NotFound
      text={"404 GAME NOT FOUND"}
      subText={
        "Please check the entered link or contact the admin for more details !"
      }
    />
  );
};

export default GameRouter;
