import { Box, Fade, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import styles from '../styles/AdminFunctionsModal.module.css';
import axios from 'axios';
import { useContext } from 'react';
import { LoadingContext } from '../App';

const AdminFunctionsModal = ({openAdminModal,setOpenAdminModal,details,adding,getUsers}) => {

    const {setIsLoading} = useContext(LoadingContext);
    const [adminDetails,setAdminDetails] = useState(details);
    const handleCloseAdminModal = () => {
        setOpenAdminModal(false);
    };

    useEffect(() => {
        setAdminDetails(details);
    }, [details])

    const handleChange = (e) => {
        let temp = { ...adminDetails };
        temp[e.target.id] = e.target.value;
        setAdminDetails(temp);
    };

    const handleChange1 = (event) => {
        let temp = { ...adminDetails };
        temp.role = event.target.value
        setAdminDetails(temp);
    };

    const boxStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "40%",
        backgroundColor: "#FFFFFF",
        border: "2px solid #000",
        boxShadow: 24,
        outline: "none",
        p: 4,
    };

    const handleAdminFunction = () => {
      if(adding) {
        addAdmin();
      } else {
        editAdmin();
      }
    }

    const assignRole = (role) => {
      if(role === "Admin") {
        return 2;
      } else 
      return 1;
    }

    const addAdmin = async () => {

      const obj = {...adminDetails};
      delete obj.id;
      obj.role = assignRole(obj.role);
      obj.created_at = new Date().toISOString();
      
      handleCloseAdminModal();
      setIsLoading(true);
      await axios
        .post(`${process.env.REACT_APP_API_URL}users/create`,obj)
        .then((response) => {
          getUsers();  
        })
        .catch((err) => {
          console.log(err);
        });
      setIsLoading(true);
    };

    const editAdmin = async () => {

      const obj = {...adminDetails};
      obj.role = assignRole(obj.role);
      handleCloseAdminModal();
      setIsLoading(true);
      await axios
        .put(`${process.env.REACT_APP_API_URL}users/edit`,obj)
        .then((response) => {
          getUsers();
        })
        .catch((err) => {
          console.log(err);
        });
      setIsLoading(true);
    };

    const roles = ["Super Admin", "Admin"];

  return (
    <Modal
        open={openAdminModal}
        onClose={handleCloseAdminModal}
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAdminModal}>
          <Box sx={boxStyle}>
            <Typography
              variant="h4"
              component="h4"
              textAlign={"center"}
              fontWeight={"bold"}
              letterSpacing={"3px"}
              fontFamily={"Fjalla"}
              sx={{ marginBottom: "5px" }}
            >
              {adding ? "ADD ADMIN" : "EDIT ADMIN"}
            </Typography>
            <div className={styles.belowInfo}>{adding ? "Please fill all the details for the new admin." : "Update the details of the admin and save changes."}</div>
            <div className={styles.txtflds}>
                <TextField
                inputProps={{ style: { height: "20px" } }}
                value={adminDetails.name}
                onChange={handleChange}
                id="name"
                margin="dense"
                type="text"
                label="Name"
                variant="outlined"
                sx={{marginBottom:"10px"}}
                />
                <TextField
                inputProps={{ style: { height: "20px" } }}
                value={adminDetails.email}
                onChange={handleChange}
                id="email"
                margin="dense"
                type="text"
                label="Email"
                variant="outlined"
                sx={{marginBottom:"10px"}}
                />
                <TextField
                    inputProps={{ style: { height: "20px" } }}
                    value={adminDetails.mobile}
                    onChange={handleChange}
                    id="mobile"
                    margin="dense"
                    type="number"
                    label="Mobile"
                    variant="outlined"
                    sx={{marginBottom:"10px"}}
                />
                <FormControl fullWidth>
                <InputLabel>
                    Role
                </InputLabel>
                <Select
                    sx={{
                    transition: "all 0.2s",
                    letterSpacing: "1px",
                    marginBottom:"10px"
                    }}
                    value={adminDetails.role}
                    label="Role"
                    onChange={handleChange1}
                >
                    {roles.map((role, i) => {
                    return (
                        <MenuItem
                        key={i}
                        value={role}
                        sx={{
                            transition: "all 0.2s",
                            letterSpacing: "1px",
                        }}
                        >
                        {role}
                        </MenuItem>
                    );
                    })}
                </Select>
                </FormControl>
            </div>
            <div onClick={handleAdminFunction} className={styles.butts}>{adding ? "Add and Send Email" : "Save Changes"}</div>
          </Box>
        </Fade>
      </Modal>
  )
}

export default AdminFunctionsModal