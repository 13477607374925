import React, { useContext, useState } from "react";
import Navbar from "../components/Navbar";
import Back from "../components/Back";
import styles from "../styles/CreateRoom.module.css";
import {
  Alert,
  AlertTitle,
  Box,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import axios from "axios";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import styled from '@emotion/styled'
import { LoadingContext, UserContext } from "../App";
import { useNavigate } from "react-router-dom";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 400,
    border: "1px solid #dadde9",
    fontWeight: "bold",
    fontSize: "16px",
    fontFamily: "Inter",
    letterSpacing: "1.5px",
  },
}));

const boxStyle = {
  width: "60%",
  backgroundColor: "white",
  boxShadow: 24,
  marginLeft: "20%",
  marginTop: "50px",
  position: "absolute",
  top: "0%",
  borderRadius: "10px",
  p: 4,
};

const CreateGame = () => {

  const {setIsLoading} = useContext(LoadingContext);
  
  // eslint-disable-next-line no-unused-vars
  const {user,setUser} = useContext(UserContext);

  const navigate = useNavigate();
  const settingStartTime = () => {
    let dt = new Date()
    dt.setSeconds(0);
    dt.setHours(new Date().getHours() + 1)

    return dt;
  }

  const [gameDetails, setGameDetails] = useState({
    title: "",
    start_time: dayjs(settingStartTime()),
  });

  

  const [gameRules, setGameRules] = useState({
    game_duration: 10,
    bronze_trigger: 10,
    silver_trigger: 40,
    gold_trigger: 70,
    opportunity_trigger: 10,
    hurdle_trigger: 80,
    bronze_interval: 55,
    silver_interval: 80,
    gold_interval: 100,
    opportunity_interval: 40,
    hurdle_interval: 100,
    people: 12,
    connections: 12,
    cash: 12,
  });

  const [titleError,setTitleError] = useState(false);

  const handleDatePick = (val) => {
    const obj = { ...gameDetails };
    obj.start_time = dayjs(new Date(val));

    setGameDetails(obj);
  };

  const handleChange = (e) => {
    let temp = { ...gameDetails };
    temp[e.target.id] = e.target.value;

    if(e.target.id === "title") {
      setTitleError(false);
    }

    setGameDetails(temp);
  };

  const handleGameRules = (event) => {
    let temp = { ...gameRules };
    temp[event.target.name] = parseInt(event.target.value);
    setGameRules(temp);
  }

  const validateTitle = () => {
    if(gameDetails.title === "") {
      setTitleError(true);
      return false;
    }

    return true;
  }

  const validateNums = (num) => {
    if(isNaN(num))
    return false;

    return true;
  } 


  const handleCreate = async () => {
    let valTitle = validateTitle();

    if(valTitle) {

      const obj = {...gameDetails,...gameRules};
      obj.creator = user.id;
      obj.start_time = obj.start_time.toISOString();

      setIsLoading(true);
      await axios.post(`${process.env.REACT_APP_API_URL}games/create`,obj)
      .then((response)=>{
        setGameCreationSuccess(true);

        setGameDetails({
          title: "",
          start_time: dayjs(new Date().setHours(new Date().getHours() + 1)),
        })
        setGameRules({
          game_duration: 10,
          bronze_trigger: 10,
          silver_trigger: 40,
          gold_trigger: 70,
          opportunity_trigger: 10,
          hurdle_trigger: 80,
          bronze_interval: 55,
          silver_interval: 80,
          gold_interval: 100,
          opportunity_interval: 40,
          hurdle_interval: 100,
          people: 12,
          connections: 12,
          cash: 12,
        })
      })
      .catch((err)=>{
        console.log(err)
      });
      setIsLoading(false);
      navigate("/sat-administrator/dashboard")
    }
  }

  const [gameCreationSuccess,setGameCreationSuccess] = useState();

  const handleCloseDown = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setGameCreationSuccess(false);
	};


  return (
    <>
      <Navbar />
      <div className={styles.mainCreateRoom}>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          open={gameCreationSuccess}
          autoHideDuration={3000}
          onClose={handleCloseDown}
        >
          <Alert
            onClose={handleCloseDown}
            variant="filled"
            severity='success'
            sx={{ width: "100%" }}
          >
            <AlertTitle style={{verticalAlign: 'top'}}>A New Game was successfully created !</AlertTitle>
          </Alert>
        </Snackbar>
        <Back />
        <Box sx={boxStyle}>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h4"
            textAlign={"center"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
            fontFamily={"Fjalla"}
            sx={{ marginBottom: "5px" }}
          >
            CREATE A NEW GAME
          </Typography>
          <div className={styles.belowInfo}>
            Please fill the information of the game (Editable Later).
          </div>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            letterSpacing={"1px"}
            fontFamily={"Fjalla"}
            sx={{ marginLeft: "20%", marginTop: "30px" }}
          >
            General Details
          </Typography>
          <div className={styles.txtflds}>
            <TextField
              error={titleError}
              helperText={titleError && "Please enter a title"}
              inputProps={{ style: { height: "20px" } }}
              value={gameDetails.title}
              onChange={handleChange}
              id="title"
              margin="dense"
              type="text"
              label="Title"
              variant="outlined"
              sx={{ marginBottom: "20px", marginTop: "10px" }}
            />
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
            <HtmlTooltip
              title={
                <div style={{ padding: "10px",textAlign: "center" }}>
                    Default : 1 Hour From Now
                </div>
              }
              placement="left"
              >
              <div style={{width: '100%'}}>
                <DateTimePicker
                  disablePast
                  format="DD/MM/YYYY | hh:mm a"
                  label="Start Date and Time"
                  value={gameDetails.start_time}
                  onChange={handleDatePick}
                  sx={{marginBottom: "10px", width: '100%'  }}
                />
              </div>
            </HtmlTooltip>
            </LocalizationProvider>
          </div>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            letterSpacing={"1px"}
            fontFamily={"Fjalla"}
            sx={{ marginLeft: "20%", marginTop: "20px" }}
          >
            Game Rules
          </Typography>
          <div className={styles.txtflds}>
            <div className={styles.gameRules}>
              <TextField
                value={gameRules.game_duration}
                name="game_duration"
                onChange={handleGameRules}
                error={!validateNums(gameRules.game_duration)}
                helperText={!validateNums(gameRules.game_duration) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Game Duration"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%'}}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">min</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.cash}
                name="cash"
                onChange={handleGameRules}
                error={!validateNums(gameRules.cash)}
                helperText={!validateNums(gameRules.cash) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Initial Cash"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%'}}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">L</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.people}
                name="people"
                onChange={handleGameRules}
                error={!validateNums(gameRules.people)}
                helperText={!validateNums(gameRules.people) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Initial People"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">P</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.connections}
                name="connections"
                onChange={handleGameRules}
                error={!validateNums(gameRules.connections)}
                helperText={!validateNums(gameRules.connections) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Initial Connections"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">C</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.bronze_trigger}
                name="bronze_trigger"
                onChange={handleGameRules}
                error={!validateNums(gameRules.bronze_trigger)}
                helperText={!validateNums(gameRules.bronze_trigger) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Bronze Trigger (First Card)"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.bronze_interval}
                name="bronze_interval"
                onChange={handleGameRules}
                error={!validateNums(gameRules.bronze_interval)}
                helperText={!validateNums(gameRules.bronze_interval) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Bronze Appear Interval"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              
              <TextField
                value={gameRules.silver_trigger}
                name="silver_trigger"
                onChange={handleGameRules}
                error={!validateNums(gameRules.silver_trigger)}
                helperText={!validateNums(gameRules.silver_trigger) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Silver Trigger (First Card)"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.silver_interval}
                name="silver_interval"
                onChange={handleGameRules}
                error={!validateNums(gameRules.silver_interval)}
                helperText={!validateNums(gameRules.silver_interval) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Silver Appear Interval"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.gold_trigger}
                name="gold_trigger"
                onChange={handleGameRules}
                error={!validateNums(gameRules.gold_trigger)}
                helperText={!validateNums(gameRules.gold_trigger) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Gold Trigger (First Card)"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%'  }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.gold_interval}
                name="gold_interval"
                onChange={handleGameRules}
                error={!validateNums(gameRules.gold_interval)}
                helperText={!validateNums(gameRules.gold_interval) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Gold Appear Interval"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.opportunity_trigger}
                name="opportunity_trigger"
                onChange={handleGameRules}
                error={!validateNums(gameRules.opportunity_trigger)}
                helperText={!validateNums(gameRules.opportunity_trigger) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Opportunity Trigger (First Card)"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.opportunity_interval}
                name="opportunity_interval"
                onChange={handleGameRules}
                error={!validateNums(gameRules.opportunity_interval)}
                helperText={!validateNums(gameRules.opportunity_interval) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Opportunity Appear Interval"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.hurdle_trigger}
                name="hurdle_trigger"
                onChange={handleGameRules}
                error={!validateNums(gameRules.hurdle_trigger)}
                helperText={!validateNums(gameRules.hurdle_trigger) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Hurdle Trigger (First Card)"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.hurdle_interval}
                name="hurdle_interval"
                onChange={handleGameRules}
                error={!validateNums(gameRules.hurdle_interval)}
                helperText={!validateNums(gameRules.hurdle_interval) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Hurdle Appear Interval"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
            </div>
          </div>
          <div onClick={handleCreate} className={styles.butts}>
            Create
          </div>
        </Box>
      </div>
    </>
  );
};

export default CreateGame;
