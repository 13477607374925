import { LOSING_RESOURCES_SPEED, TOKEN_PANEL_COUNT_FONTSIZE, TOKEN_SIZE } from "../helpers/dimensions";
import Phaser from "phaser";

export class Token extends Phaser.Physics.Arcade.Image {
    constructor(settings) {
        
        // Constructor of Phaser.GameObjects.Sprite
        super(settings.scene,settings.x,settings.y,settings.texture,settings.frame);

        // Adding Token to the Parent Scene and setting some of its properties
        settings.scene.physics.add.existing(this);
        settings.scene.add.existing(this);
        this.setOrigin(0.5,0.5).setDisplaySize(TOKEN_SIZE,TOKEN_SIZE).setDepth(101);
        // this.postFX.addShadow(-20, 20, 0.001, 1, 0x000000, 3);

        // Data Members
        this.parentScene = settings.scene
        this.config = settings.playSceneConfig
        this.endX = settings.endX
        this.endY = settings.endY
        this.type = settings.texture;
    }

    animate() {
        this.parentScene.add.tween({
            targets: this,
            duration: 500,
            x: this.endX,
            y: this.endY,
            ease: Phaser.Math.Easing.Sine.Out,
            onComplete: () => {
                this.parentScene.add.tween({
                    targets: this,
                    scale: 0,
                    duration: 100,
                    ease: Phaser.Math.Easing.Sine.In,
                    onComplete: () => {
                        this.destroy();
                    }
                })
                
            }
        })
    }

    lose() {
        let minus = this.parentScene.add.text(this.x - TOKEN_SIZE / 1.5,this.y,'-',{fontStyle: '1000',fontFamily: 'Night',fontSize: `${TOKEN_PANEL_COUNT_FONTSIZE}px`}).setOrigin(0.5,0.5).setDepth(102);
        this.parentScene.physics.add.existing(minus); 
        minus.body.velocity.y = LOSING_RESOURCES_SPEED;
        this.setVelocityY(LOSING_RESOURCES_SPEED);

        this.parentScene.add.tween({
            targets: [this,minus],
            duration: 100,
            onComplete: () => {
                let tokenLoseInterval = setInterval(()=> {
                    if(this.y < 500*devicePixelRatio) {
                        this.parentScene.add.tween({
                            targets: [this,minus],
                            alpha: 0,
                            duration : 100,
                            onComplete: () => {
                                this.destroy();
                                minus.destroy()
                                clearInterval(tokenLoseInterval)
                            }
                        })  
                    }
                },1)
            } 
        })
        

    }

} 