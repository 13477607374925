import { BUTTON_DEFAULT_FONTSIZE, BUTTON_DEFAULT_HEIGHT, BUTTON_DEFAULT_WIDTH, SMALL_TOKEN_SIZE } from "../helpers/dimensions";
import Phaser from "phaser";
import eventsCenter from "../helpers/eventsCenter";


export class Button extends Phaser.GameObjects.Container {
    constructor({disabled = false,fontSize = `${BUTTON_DEFAULT_FONTSIZE}px`,width = BUTTON_DEFAULT_WIDTH,height = BUTTON_DEFAULT_HEIGHT,...settings}) {

        let posX = settings.x || settings.playSceneConfig.width / 2
        let posY = settings.y || settings.playSceneConfig.height - 80 * devicePixelRatio

        let btnTxt = settings.scene.add.text(0,0,settings.text,{padding:{right: 4},shadow:{offsetX: 2,offsetY: 2,fill:true,blur:3},fontStyle: '1000',fontFamily: 'Night',fontSize: fontSize}).setOrigin(0.5,0.5);
        let btnImg = settings.image;
        let btnInnards = settings.scene.add.container(0,0,[btnTxt]);

        if(btnImg !== undefined) {
            btnTxt.x = -SMALL_TOKEN_SIZE + 10* devicePixelRatio;
            btnImg.x = SMALL_TOKEN_SIZE + 25*devicePixelRatio;
            btnInnards.add(btnImg)
        }

        let btnBx = settings.scene.add.image(0,0, settings.texture,disabled ? 6 : settings.frame).setDisplaySize(width,height)
        !disabled && btnBx.setInteractive({cursor:'pointer'});
        // btnTxt.preFX.addShadow(-30, -40, 0.001, 1, 0x000000, 10);

        super(settings.scene,posX,posY,[btnBx,btnInnards]);
        

        settings.scene.add.existing(this);
        this.setDepth(101).setScale(0);

        this.disabled = disabled;
        this.parentScene = settings.scene
        this.config = settings.playSceneConfig
        this.texture = settings.texture
        this.frame = settings.frame
        this.frameActive = settings.frameActive
        this.text = btnTxt
        this.box = btnBx
        this.item = settings.item
        this.action = settings.action
        this.fontSize = fontSize
        this.width = width
        this.height = height
        this.x = posX
        this.y = posY
        this.metaData = settings.metaData;
        this.appeared = false;
        this.stay = settings.stay || false;
        this.pulsatable = settings.pulsate || false;
        this.gameOverBtn = settings.gameOverBtn || false;
    }  

    appear() {
        this.appeared = true;
        this.parentScene.add.tween({
            targets: this,
            scale: 1,
            duration: 300,
            ease: Phaser.Math.Easing.Back.Out,
            onComplete: () => {
                this.box.on('pointerover',() => {
                    if(!this.pulsatable) {
                        this.parentScene.add.tween({
                            targets: this,
                            scale: 1.05,
                            duration: 100
                        })
                    }
                })
        
                this.box.on('pointerout',() => {
                    if(!this.pulsatable) {
                        this.parentScene.add.tween({
                            targets: this,
                            scale: 1,
                            duration: 100
                        })
                    }
                    this.box.setFrame(this.frame);
                })
        
                this.box.on('pointerdown',() => {
                    this.box.setFrame(this.frameActive);
                })
        
                this.box.on('pointerup',() => {
                    if(!this.disabled) {
                        this.scale = 1;
                        this.box.setFrame(this.frame);
                        if(!this.stay) {
                            this.box.removeAllListeners();
                        }
                        this.action.call(this.item,this.metaData,this)    
                    }
                })
            }
        })
    }
    appearGameOver() {
        clearInterval(this.parentScene.countDown)
        clearInterval(this.parentScene.extraTimeCountDown)
        this.appeared = true;
        this.parentScene.add.tween({
            targets: this,
            scale: 1,
            duration: 300,
            ease: Phaser.Math.Easing.Back.Out,
            onComplete: () => {
                this.box.on('pointerover',() => {
                    if(!this.pulsatable) {
                        this.parentScene.add.tween({
                            targets: this,
                            scale: 1.05,
                            duration: 100
                        })
                    }
                })
        
                this.box.on('pointerout',() => {
                    if(!this.pulsatable) {
                        this.parentScene.add.tween({
                            targets: this,
                            scale: 1,
                            duration: 100
                        })
                    }
                    this.box.setFrame(this.frame);
                })
        
                this.box.on('pointerdown',() => {
                    this.box.setFrame(this.frameActive);
                })
        
                this.box.on('pointerup',() => {
                    if(!this.disabled) {
                        this.scale = 1;
                        this.box.setFrame(this.frame);
                        if(!this.stay) {
                            this.box.removeAllListeners();
                        }

                        eventsCenter.emit('results');
                    }
                })
            }
        })
        
    } 

    enable() {
        this.box.setInteractive({cursor: 'pointer'})
        this.box.setFrame(this.frame);
        this.disabled = false;
    }

    disable() {
        this.box.removeInteractive();
        this.box.setFrame(6);
        this.disabled = true;
    }

    pulsate() {
        if(!this.disabled) {
            this.pulsateTween = this.parentScene.add.tween({
                targets: this,
                scale: 1.2,
                yoyo: true,
                repeat: -1,
                duration: 600,
            })
        }
    }

    unpulsate() {
        if(this.disabled) {
            this.pulsateTween.remove();
        }
    }
} 