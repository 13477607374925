import Phaser from "phaser";
import { DEV_DPI_RATIO, UPCOMING_CARD_HEIGHT, UPCOMING_CARD_WIDTH } from "../helpers/dimensions";


export class Loader extends Phaser.GameObjects.Graphics {
    constructor({...settings}) {

        
        super(settings.scene);

        settings.scene.add.existing(this);
        this.endAngle = 270;
        this.posX = settings.x
        this.posY = settings.y

        this.lineStyle(4.8*devicePixelRatio , 0xffffff, 1);
        this.arc(this.posX, this.posY, 22 * devicePixelRatio, Phaser.Math.DegToRad(270), Phaser.Math.DegToRad(this.endAngle), false);
        this.strokePath();
        this.parentScene = settings.scene;
        this.type = settings.type;
        this.waiting = false;
        this.ended = settings.ended;

        if(this.ended) {
            this.crossOutLoader();
        }
    } 

    updateLoader(val) {
        this.clear();
        this.lineStyle(4.8*devicePixelRatio , 0xffffff, 1);
        this.endAngle = 270 + val;
        this.arc(this.posX,this.posY, 22 * devicePixelRatio, Phaser.Math.DegToRad(270), Phaser.Math.DegToRad(this.endAngle), false);
        this.strokePath();
    }

    crossOutLoader() {
        this.clear();
        this.crossedLoader = this.parentScene.add.image(this.posX,this.posY,'cross').setDisplaySize(UPCOMING_CARD_WIDTH,UPCOMING_CARD_HEIGHT).setAlpha(0).setAngle(-20).setScale(4);
        this.parentScene.add.tween({
            targets: [this.crossedLoader],
            scale: 1 * DEV_DPI_RATIO,
            alpha: 1,
            angle:0,
            delay: 1000,
            duration: 300,
        });

    }


} 