import styles from "../styles/Loading.module.css";
import { useEffect } from "react";
import loader from '../assets/loader.gif';

const Loading = ({isLoading}) => {

    const disableEvents = () => {
        if(isLoading) {
            document.body.style.pointerEvents = 'none';
            document.body.style.userSelect = 'none';
        } else {
            document.body.style.pointerEvents = 'auto';
            document.body.style.userSelect = 'auto';
        }
    }

    useEffect(()=>{
        disableEvents();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isLoading])

    if(isLoading)
    return (
        <div className={styles.mainLoader}>
            <img className={styles.loaderImg} src={loader} alt="Loading..." />
        </div>
    );
}

export default Loading;