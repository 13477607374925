import React from 'react'
import { useNavigate } from 'react-router-dom'
import back from '../assets/back.png';
import styles from '../styles/Back.module.css';

const Back = () => {

    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    }
 
  return (
    <div className={styles.mainBack} onClick={handleBack}>
        <img className={styles.backImg} src={back} alt='Back'/>
        <div className={styles.backText}>BACK</div>
    </div>
  )
}

export default Back