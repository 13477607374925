import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../App";

const AdminPrivate = () => {

  // eslint-disable-next-line no-unused-vars
  const { user, setUser } = useContext(UserContext);

  return user.role === "Super Admin" || user.role === "Admin" ? (
    <Navigate to="/sat-administrator/dashboard" />
  ) : (
    <Outlet />
  );
};

export default AdminPrivate;
