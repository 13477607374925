import React, { useContext, useEffect, useRef } from "react";
import Phaser from "phaser";
import { PlayScene } from "../phaser/scenes/PlayScene";
import { Preload } from "../phaser/scenes/Preload";
import { WaitingScene } from "../phaser/scenes/WaitingScene";
import { SocketContext } from "../App";
import eventsCenter from "../phaser/helpers/eventsCenter";
import axios from "axios";

const PhaserGame = ({setRender}) => {

  const {socket} = useContext(SocketContext);
  const game = useRef("");

  const SHARED_CONFIG = {
    width: 1200 * devicePixelRatio,
    height: 700 * devicePixelRatio,
  };

  const Scenes = [Preload, WaitingScene, PlayScene];
  const sceneMapper = (Scene) => new Scene(SHARED_CONFIG);
  const allScenes = Scenes.map(sceneMapper);

  const mainConfig = {
    type: Phaser.AUTO,
    parent: "canvas-container",
    width: SHARED_CONFIG.width,
    height: SHARED_CONFIG.height,
    physics: {
      default: "arcade",
      arcade: {
        debug: false,
      },
    },
    render: {
      antialias: true,
      roundPixels: true,
      powerPreference: 'high-performance',
    },
    scene: allScenes,
  };

  function resize() {
    var canvas = document.querySelector("canvas");
    var windowWidth = document.documentElement.clientWidth;
    var windowHeight = document.documentElement.clientHeight;
    console.log(game.current.config);
    var windowRatio = windowWidth / windowHeight;
    var gameRatio = game.current.config.width / game.current.config.height;
    if (windowRatio < gameRatio) {
      canvas.style.width = windowWidth + "px";
      canvas.style.height = windowWidth / gameRatio + "px";
    } else {
      canvas.style.width = windowHeight * gameRatio + "px";
      canvas.style.height = windowHeight + "px";
    }
  }

  useEffect(() => {
    game.current = new Phaser.Game(mainConfig);
    const canvas = game.current.canvas;
    canvas.style.width = `1200px`;
    canvas.style.height = `700px`;
    canvas.width = Math.floor(SHARED_CONFIG.width);
    canvas.height = Math.floor(SHARED_CONFIG.height);
    resize();
    window.addEventListener("resize", resize, false);
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    socket.on('BSpawn',async (data)=>{
      await receivedProject(data);
    })

    socket.on('SSpawn',async (data)=>{
      await receivedProject(data);
    })

    socket.on('GSpawn',async (data)=>{
      await receivedProject(data);
    })

    socket.on('HSpawn',async (data)=>{
      await receivedHurdle(data);
    })

    socket.on('OSpawn',async (data)=>{
      await appearedOpportunity(data);
    })

    socket.on('start',(data)=>{
      eventsCenter.emit("start");
    })

    socket.on('BTimer',(data)=>{
      eventsCenter.emit("BTimer",data);
    })

    socket.on('STimer',(data)=>{
      eventsCenter.emit("STimer",data);
    })

    socket.on('GTimer',(data)=>{
      eventsCenter.emit("GTimer",data);
    })

    socket.on('OTimer',(data)=>{
      eventsCenter.emit("OTimer",data);
    })

    socket.on('HTimer',(data)=>{
      eventsCenter.emit("HTimer",data);
    })

    socket.on("BEmpty",()=>{
      eventsCenter.emit("BEmpty");
    })

    socket.on("SEmpty",()=>{
      eventsCenter.emit("SEmpty");

    })

    socket.on("GEmpty",()=>{
      eventsCenter.emit("GEmpty");

    })

    socket.on("OEmpty",()=>{
      eventsCenter.emit("OEmpty");

    })

    socket.on("HEmpty",()=>{
      eventsCenter.emit("HEmpty");

    });

    eventsCenter.on("noRevenuePlayed",async ()=>{
      await noRevenuePlayed();
    });

    eventsCenter.on("noPlayPlayed",async ()=>{
      await noPlayPlayed();
    });

    socket.on("noRevenueCountDown",(data)=>{
      eventsCenter.emit("noRevenueCountDown",data);

    });

    socket.on("noRevenueCountDownEnd",()=>{
      eventsCenter.emit("noRevenueCountDownEnd");
    });

    socket.on("noPlayCountDown",(data)=>{
      eventsCenter.emit("noPlayCountDown",data);

    });

    socket.on("noPlayCountDownEnd",()=>{
      eventsCenter.emit("noPlayCountDownEnd");
    });

    socket.on("countdown",(data)=>{
      eventsCenter.emit("countdown",data)
    });

    socket.on("start-a-thon",()=>{
      eventsCenter.emit("start-a-thon")
  });

  socket.on("projectStarted",(data)=>{
    let params = new URLSearchParams(window.location.search);

    if(params.get("pl") !== data.player) {
      eventsCenter.emit("ghostProject",data)
    }
});

  socket.on("alterProject",(data)=>{
    let params = new URLSearchParams(window.location.search);

    if(params.get("pl") === data) {
      eventsCenter.emit("alterProject");
    }
  });

  socket.on("projectInPipeline",(data)=>{
    eventsCenter.emit("projectInPipeline",data);
  });
  
 

  eventsCenter.on("results",()=>{
    game.current.destroy(true,false);
    setRender('expired')
  });


  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const appearedOpportunity = async (data) => {
    let params = new URLSearchParams(window.location.search);
    let game = params.get("game");
    let pl = params.get("pl");

    let obj1 = {
      pl: pl,
      game: game,
      code: data.card1.code,
      frame: data.card1.frame
    }

    let obj2 = {
      pl: pl,
      game: game,
      code: data.card2.code,
      frame: data.card2.frame
    }

    await axios
      .post(`${process.env.REACT_APP_API_URL}cards/appearedOpportunity`,[obj1,obj2])
      .then((response) => {
        let temp = {...data}
        temp.id1 = response.data.data.id1;
        temp.id2 = response.data.data.id2;
        eventsCenter.emit("OSpawn", temp)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const receivedHurdle = async (data) => {
    let params = new URLSearchParams(window.location.search);
    let game = params.get("game");
    let pl = params.get("pl");

    let obj1 = {
      pl: pl,
      game: game,
      code: data.card1.code,
      frame: data.card1.frame
    }

    let obj2 = {
      pl: pl,
      game: game,
      code: data.card2.code,
      frame: data.card2.frame
    }

    await axios
      .post(`${process.env.REACT_APP_API_URL}cards/receivedHurdle`,[obj1,obj2])
      .then((response) => {
        let temp = {...data}
        temp.id1 = response.data.data.id1;
        temp.id2 = response.data.data.id2;
        eventsCenter.emit("HSpawn", temp)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const receivedProject = async (data) => {
    let params = new URLSearchParams(window.location.search);
    let game = params.get("game");
    let pl = params.get("pl");

    let obj = {
      pl: pl,
      game: game,
      code: data.card.code,
      frame: data.card.frame
    }

    await axios
      .post(`${process.env.REACT_APP_API_URL}cards/receivedProject`,obj)
      .then((response) => {
        let temp = {...data}
        temp.id = response.data.data;
        eventsCenter.emit(`${obj.code[0]}Spawn`, temp)
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const noRevenuePlayed = async (data) => {
    let params = new URLSearchParams(window.location.search);
    let game = params.get("game");
    let pl = params.get("pl");

    let obj1 = {
      game: game,
      pl: pl,
    }

    await axios
      .post(`${process.env.REACT_APP_API_URL}cards/playedNoRevenue`,obj1)
      .then((response) => {
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const noPlayPlayed = async (data) => {
    let params = new URLSearchParams(window.location.search);
    let game = params.get("game");
    let pl = params.get("pl");

    let obj1 = {
      game: game,
      pl: pl,
    }

    await axios
      .post(`${process.env.REACT_APP_API_URL}cards/playedNoPlay`,obj1)
      .then((response) => {
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return <></>;
};

export default PhaserGame;
