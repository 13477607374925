import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Protected from "./components/Protected";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import './App.css';
import AdminProtected from "./components/AdminProtected";
import {io} from 'socket.io-client';
import Dashboard from "./pages/Dashboard";
import AdminFunctions from "./pages/AdminFunctions";
import History from "./pages/History";
import CreateRoom from "./pages/CreateGame";
import Ongoing from "./pages/Ongoing";
import ResetPassword from "./pages/ResetPassword";
import Loading from "./components/Loading";
import axios from "axios";
import AdminPrivate from "./components/AdminPrivate";
import GameRouter from "./pages/GameRouter";

const socket = io(process.env.REACT_APP_API_URL,{ transports: ["websocket"] });
export const UserContext = createContext();
export const LoadingContext = createContext();
export const SocketContext = createContext();

const App = () => {
  const [user, setUser] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const isJsonString = (obj) => {
    if(obj === undefined || obj === null) {
      return false;
    }
    try {
        JSON.parse(obj);
    } catch (e) {
        return false;
    }
    return true;
}

  const authenticateUser = () => {
    const loggedInUser = localStorage.getItem("tkn");
    if(isJsonString(loggedInUser)) {

      const tkn = JSON.parse(loggedInUser);
      try {
        axios
          .get(`${process.env.REACT_APP_API_URL}users/info`, {
            headers: { Authorization: `Bearer ${tkn}` },
          })
          .then((result) => {
            if (result.data.auth) {
              let temp = { ...result.data };
              let user = { ...temp.data };
              setUser(user);
            }
          });
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  }

  

  useEffect(() => {
    authenticateUser();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <UserContext.Provider value={{user,setUser}}>
        <LoadingContext.Provider value={{setIsLoading}}>
          <SocketContext.Provider value={{socket}}>
            <Loading isLoading={isLoading}/>
            <Routes>
              
              <Route element={<AdminProtected />}>
                <Route path="/sat-administrator/dashboard" element={<Dashboard />} />
                <Route path="/sat-administrator/admin-functions" element={<AdminFunctions />} />
                <Route path="/sat-administrator/create-room" element={<CreateRoom />} />
                <Route path="/sat-administrator/ongoing" element={<Ongoing />} />
                <Route path="/sat-administrator/history" element={<History />} />
              </Route>
              <Route element={<AdminPrivate />}>
                <Route path="/sat-administrator/login" element={<Login />} />
              </Route>
                <Route path="/sat-administrator/reset" element={<ResetPassword />} />
                <Route path="/play" element={<GameRouter />} />
              <Route element={<Protected />}>
                
              </Route>
              
              <Route path="/*" element={<NotFound text={"404 Not Found"} subText={"Oops ! This web page does not exist !"} />} />
            </Routes>
          </SocketContext.Provider>
        </LoadingContext.Provider>
      </UserContext.Provider>
    </BrowserRouter>
  );
};

export default App;
