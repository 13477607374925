import {
  Alert,
  AlertTitle,
  Box,
  Collapse,
  IconButton,
  InputAdornment,
  LinearProgress,
  linearProgressClasses,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Delete,
  Email,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@mui/icons-material";
import { format } from "date-fns";
import styles from "../styles/CollapsibleScheduledRow.module.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import dayjs from "dayjs";
import { LoadingContext, UserContext } from "../App";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Papa from "papaparse";
import AddPlayersModal from "./AddPlayersModal";
import AddSinglePlayerModal from "./AddSinglePlayerModal";
import ConfirmationModal from "./ConfirmationModal";

const CollapsibleScheduledRow = ({ columns, row, getAllScheduledGames, open,setOpen }) => {
  const [openAddPlayersModal, setOpenAddPlayersModal] = useState(false);
  const [openSPModal, setOpenSPModal] = useState(false);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    marginRight: "10px",
    marginBottom: "10px",
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "grey",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: row["players"] > 0 ? "#228B22" : "#edb525",
    },
  }));

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 400,
      border: "1px solid #dadde9",
      fontWeight: "bold",
      fontSize: "16px",
      fontFamily: "Inter",
      letterSpacing: "1.5px",
    },
  }));


  const { setIsLoading } = useContext(LoadingContext);
  
  // eslint-disable-next-line no-unused-vars
  const { user, setUser } = useContext(UserContext);

  const [gameDetails, setGameDetails] = useState({
    id: row.id,
    title: row.title,
    start_time: dayjs(new Date(row.start_time)),
  });

  // eslint-disable-next-line no-unused-vars
  const [gameRules, setGameRules] = useState({
    game_duration: row.game_duration ,
    bronze_trigger: row.bronze_trigger ,
    silver_trigger: row.silver_trigger ,
    gold_trigger: row.gold_trigger ,
    opportunity_trigger: row.opportunity_trigger ,
    hurdle_trigger: row.hurdle_trigger ,
    bronze_interval: row.bronze_interval ,
    silver_interval: row.silver_interval ,
    gold_interval: row.gold_interval ,
    opportunity_interval: row.opportunity_interval ,
    hurdle_interval: row.hurdle_interval ,
    people: row.people ,
    connections: row.connections ,
    cash: row.cash ,
  });

  const [allGamePlayers, setAllGamePlayers] = useState([]);

  const [csvData, setCsvData] = useState([]);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file !== undefined) {
      Papa.parse(file, {
        complete: (result) => {
          setCsvData(result.data);
          setOpenAddPlayersModal(true);
        },
        header: true,
      });
    }
  };

  const validateNums = (num) => {
    if(isNaN(num))
    return false;

    return true;
  } 

  const [titleError, setTitleError] = useState(false);

  const handleDatePick = (val) => {
    const obj = { ...gameDetails };
    obj.start_time = dayjs(new Date(val));
    setGameDetails(obj);
  };

  const handleChange = (e) => {
    let temp = { ...gameDetails };
    temp[e.target.id] = e.target.value;

    if (e.target.id === "title") {
      setTitleError(false);
    }

    setGameDetails(temp);
  };

  const getGamePlayers = async () => {
    setIsLoading(true);
    await axios
      .get(`${process.env.REACT_APP_API_URL}players/allOfAGame`, {
        params: {
          game: row.id,
        },
      })
      .then((response) => {
        setAllGamePlayers(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getGamePlayers();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const validateTitle = () => {
    if (gameDetails.title === "") {
      setTitleError(true);
      return false;
    }

    return true;
  };

  const handleGameRules = (event) => {
    let temp = { ...gameRules };
    temp[event.target.name] = parseInt(event.target.value);
    setGameRules(temp);
  }

  const validateDuration = () => {
    if (isNaN(gameRules.game_duration)) return false;

    return true;
  };

  const handleEdit = async () => {
    let valTitle = validateTitle();
    let valDur = validateDuration();

    if (valTitle && valDur) {
      const obj = { ...gameDetails, ...gameRules };
      obj.creator = user.id;
      obj.start_time = obj.start_time.toISOString();

      setIsLoading(true);
      await axios
        .put(`${process.env.REACT_APP_API_URL}games/edit`, obj)
        .then((response) => {
          setGameEditedSuccess(true);
        })
        .catch((err) => {
          console.log(err);
        });
      setIsLoading(false);
    }
  };

  const [gameEditedSuccess, setGameEditedSuccess] = useState();

  const handleCloseDown = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setGameEditedSuccess(false);
  };

  const playerColumns = [
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "mobile", label: "Mobile" },
    { id: "sendMail", label: "Send Link" },
    { id: "delete", label: "Remove" },
  ];

  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleOpenDeleteModal = (deets) => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const deleteGame = async () => {
    let obj = { id: row.id };
    handleCloseDeleteModal();
    setIsLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_API_URL}games/delete`, {
        data: obj,
      })
      .then((response) => {
        getAllScheduledGames();
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  const [openDeleteAllPlayersModal, setOpenDeleteAllPlayersModal] =
    useState(false);

  const handleOpenDeleteAllPlayersModal = (player) => {
    setPlayerToDelete(player);
    setOpenDeleteAllPlayersModal(true);
  };

  const handleCloseDeleteAllPlayersModal = () => {
    setOpenDeleteAllPlayersModal(false);
  };

  const deleteAllPlayers = async () => {
    let obj = { id: row.id };
    handleCloseDeleteAllPlayersModal();
    setIsLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_API_URL}players/deleteAll`, {
        data: obj,
      })
      .then((response) => {
        getGamePlayers();
        getAllScheduledGames();
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  const [openDeletePlayerModal, setOpenDeletePlayerModal] = useState(false);
  const [playerToDelete, setPlayerToDelete] = useState();

  const handleOpenDeletePlayerModal = (player) => {
    setPlayerToDelete(player);
    setOpenDeletePlayerModal(true);
  };

  const handleCloseDeletePlayerModal = () => {
    setOpenDeletePlayerModal(false);
  };

  const deletePlayer = async () => {
    let obj = { id: playerToDelete.id, game: row.id };
    handleCloseDeletePlayerModal();
    setIsLoading(true);
    await axios
      .delete(`${process.env.REACT_APP_API_URL}players/delete`, {
        data: obj,
      })
      .then((response) => {
        getGamePlayers();
        getAllScheduledGames();
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  const [openMailModal, setOpenMailModal] = useState(false);
  const [playerToMail, setPlayerToMail] = useState();

  const handleOpenMailPlayerModal = (player) => {
    setPlayerToMail(player);
    setOpenMailModal(true);
  };

  const handleCloseMailPlayerModal = () => {
    setOpenMailModal(false);
  };

  const mailPlayer = async () => {
    let obj = {
      id: playerToMail?.id,
      game: row.id,
      name: playerToMail?.name,
      email: playerToMail?.email,
      title: row.title
    };
    handleCloseMailPlayerModal();
    setIsLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}players/mailLinkToOne`,obj)
      .then((response) => {
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  const [openAllMailModal, setOpenAllMailModal] = useState(false);

  const handleOpenAllMailPlayerModal = (player) => {
    setOpenAllMailModal(true);
  };

  const handleCloseAllMailPlayerModal = () => {
    setOpenAllMailModal(false);
  };

  const mailAllPlayers = async () => {
    let obj = {
      id: row.id,
    };
    handleCloseAllMailPlayerModal();
    setIsLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}players/mailLink`,obj)
      .then((response) => {
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={gameEditedSuccess}
        autoHideDuration={3000}
        onClose={handleCloseDown}
      >
        <Alert
          onClose={handleCloseDown}
          variant="filled"
          severity="success"
          sx={{ width: "100%" }}
        >
          <AlertTitle style={{ verticalAlign: "top" }}>
            The Game was successfully edited !
          </AlertTitle>
        </Alert>
      </Snackbar>
      <AddPlayersModal
        getAllScheduledGames={getAllScheduledGames}
        getGamePlayers={getGamePlayers}
        row={row}
        setCsvData={setCsvData}
        csvData={csvData}
        openAddPlayersModal={openAddPlayersModal}
        setOpenAddPlayersModal={setOpenAddPlayersModal}
      />
      <AddSinglePlayerModal
        getAllScheduledGames={getAllScheduledGames}
        getGamePlayers={getGamePlayers}
        row={row}
        openSPModal={openSPModal}
        setOpenSPModal={setOpenSPModal}
      />
      <ConfirmationModal
        text1={"Are you sure you want to delete the game "}
        text2={" ?"}
        highlight={row.title}
        openFn={openDeleteModal}
        closeFn={handleCloseDeleteModal}
        action={deleteGame}
      />
      <ConfirmationModal
        text1={"Are you sure you want to remove "}
        text2={` from ${row.title} ?`}
        highlight={playerToDelete?.name}
        openFn={openDeletePlayerModal}
        closeFn={handleCloseDeletePlayerModal}
        action={deletePlayer}
      />
      <ConfirmationModal
        text1={"Are you sure you want to remove "}
        text2={` from ${row.title} ?`}
        highlight={"ALL PLAYERS"}
        openFn={openDeleteAllPlayersModal}
        closeFn={handleCloseDeleteAllPlayersModal}
        action={deleteAllPlayers}
      />
      <ConfirmationModal
        text1={"Are you sure you want to send the game link to "}
        text2={` ?`}
        highlight={playerToMail?.name}
        openFn={openMailModal}
        closeFn={handleCloseMailPlayerModal}
        action={mailPlayer}
      />
      <ConfirmationModal
        text1={"Are you sure you want to send the game link to "}
        highlight={"ALL PLAYERS"}
        text2={` ?`}
        openFn={openAllMailModal}
        closeFn={handleCloseAllMailPlayerModal}
        action={mailAllPlayers}
      />

      <TableRow hover tabIndex={-1}>
        {columns.map((column, j) => {
          const value = row[column.id];
          return (
            <TableCell
              key={j}
              align={"center"}
              style={{
                fontFamily: "Poppins",
                textAlign: "center",
                position: "relative",
              }}
            >
              {column.id === "progress" ? (
                <>
                  <HtmlTooltip
                    title={
                      <div style={{ padding: "10px", textAlign: "center" }}>
                        {row["players"] > 0 ? (
                          <span>
                            <span style={{ color: "green" }}>
                              {row["players"]}
                            </span>{" "}
                            Player(s) added to the game!
                          </span>
                        ) : (
                          <span style={{ color: "red" }}>
                            Yet to add players to the game
                          </span>
                        )}
                      </div>
                    }
                    placement="top"
                  >
                    <div>
                      <Box sx={{ width: "100%" }}>
                        <BorderLinearProgress
                          variant="determinate"
                          value={row["players"] > 0 ? 100 : 50}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            letterSpacing: "3px",
                            fontWeight: "800",
                            fontFamily: "Poppins",
                            fontSize: "14px",
                          }}
                          variant="body2"
                        >
                          {row["players"] > 0 ? "100%" : "50%"}
                        </Typography>
                      </Box>
                    </div>
                  </HtmlTooltip>
                </>
              ) : column.id === "#" ? (
                <>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      if(open === row.id) {
                        setOpen("")
                      } else {
                        setOpen(row.id)
                      }
                    }}
                  >
                    {open === row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </>
              ) : column.id === "delete" ? (
                <IconButton onClick={handleOpenDeleteModal} aria-label="delete">
                  <Delete />
                </IconButton>
              ) : column.id === "start_time" ? (
                format(value, "do MMM u")
              ) : (
                value
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse
            collapsedSize={"100%"}
            in={open === row.id}
            timeout="auto"
            unmountOnExit
          >
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h5"
              fontWeight={"bold"}
              letterSpacing={"1px"}
              fontFamily={"Fjalla"}
              sx={{ marginLeft: "20%", marginTop: "20px" }}
            >
              General Details
            </Typography>
            <div className={styles.txtflds}>
              <TextField
                error={titleError}
                helperText={titleError && "Please enter a title"}
                inputProps={{ style: { height: "20px" } }}
                value={gameDetails.title}
                onChange={handleChange}
                id="title"
                margin="dense"
                type="text"
                label="Title"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "10px" }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div style={{ width: "100%" }}>
                  <DateTimePicker
                    disablePast
                    format="DD/MM/YYYY | hh:mm a"
                    label="Start Date and Time"
                    value={gameDetails.start_time}
                    onChange={handleDatePick}
                    sx={{ marginBottom: "10px", width: "100%" }}
                  />
                </div>
              </LocalizationProvider>
            </div>
            <Typography
            id="modal-modal-title"
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            letterSpacing={"1px"}
            fontFamily={"Fjalla"}
            sx={{ marginLeft: "20%", marginTop: "20px" }}
          >
            Game Rules
          </Typography>
          <div className={styles.txtflds}>
            <div className={styles.gameRules}>
              <TextField
                value={gameRules.game_duration}
                name="game_duration"
                onChange={handleGameRules}
                error={!validateNums(gameRules.game_duration)}
                helperText={!validateNums(gameRules.game_duration) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Game Duration"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%'}}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">min</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.cash}
                name="cash"
                onChange={handleGameRules}
                error={!validateNums(gameRules.cash)}
                helperText={!validateNums(gameRules.cash) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Initial Cash"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%'}}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">L</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.people}
                name="people"
                onChange={handleGameRules}
                error={!validateNums(gameRules.people)}
                helperText={!validateNums(gameRules.people) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Initial People"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">P</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.connections}
                name="connections"
                onChange={handleGameRules}
                error={!validateNums(gameRules.connections)}
                helperText={!validateNums(gameRules.connections) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Initial Connections"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">C</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.bronze_trigger}
                name="bronze_trigger"
                onChange={handleGameRules}
                error={!validateNums(gameRules.bronze_trigger)}
                helperText={!validateNums(gameRules.bronze_trigger) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Bronze Trigger (First Card)"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.bronze_interval}
                name="bronze_interval"
                onChange={handleGameRules}
                error={!validateNums(gameRules.bronze_interval)}
                helperText={!validateNums(gameRules.bronze_interval) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Bronze Appear Interval"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              
              <TextField
                value={gameRules.silver_trigger}
                name="silver_trigger"
                onChange={handleGameRules}
                error={!validateNums(gameRules.silver_trigger)}
                helperText={!validateNums(gameRules.silver_trigger) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Silver Trigger (First Card)"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.silver_interval}
                name="silver_interval"
                onChange={handleGameRules}
                error={!validateNums(gameRules.silver_interval)}
                helperText={!validateNums(gameRules.silver_interval) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Silver Appear Interval"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.gold_trigger}
                name="gold_trigger"
                onChange={handleGameRules}
                error={!validateNums(gameRules.gold_trigger)}
                helperText={!validateNums(gameRules.gold_trigger) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Gold Trigger (First Card)"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%'  }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.gold_interval}
                name="gold_interval"
                onChange={handleGameRules}
                error={!validateNums(gameRules.gold_interval)}
                helperText={!validateNums(gameRules.gold_interval) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Gold Appear Interval"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.opportunity_trigger}
                name="opportunity_trigger"
                onChange={handleGameRules}
                error={!validateNums(gameRules.opportunity_trigger)}
                helperText={!validateNums(gameRules.opportunity_trigger) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Opportunity Trigger (First Card)"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.opportunity_interval}
                name="opportunity_interval"
                onChange={handleGameRules}
                error={!validateNums(gameRules.opportunity_interval)}
                helperText={!validateNums(gameRules.opportunity_interval) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Opportunity Appear Interval"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.hurdle_trigger}
                name="hurdle_trigger"
                onChange={handleGameRules}
                error={!validateNums(gameRules.hurdle_trigger)}
                helperText={!validateNums(gameRules.hurdle_trigger) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Hurdle Trigger (First Card)"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%',marginRight:'5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
              <TextField
                value={gameRules.hurdle_interval}
                name="hurdle_interval"
                onChange={handleGameRules}
                error={!validateNums(gameRules.hurdle_interval)}
                helperText={!validateNums(gameRules.hurdle_interval) && "Please enter a valid number"}
                id="title"
                margin="dense"
                type="number"
                label="Hurdle Appear Interval"
                variant="outlined"
                sx={{ marginBottom: "20px", marginTop: "0px", width: '47.5%' }}
                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                onPaste={(e)=> { e.preventDefault();return false;}}
                InputProps={{
                  endAdornment: <InputAdornment position="end">sec</InputAdornment>,
                }}
              />
            </div>
          </div>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h5"
              fontWeight={"bold"}
              letterSpacing={"1px"}
              fontFamily={"Fjalla"}
              sx={{ marginLeft: "20%", marginTop: "20px" }}
            >
              Player Details
            </Typography>

            <div className={styles.buttsContainer}>
              <input
                onChange={handleFileUpload}
                style={{ display: "none" }}
                id="csvfpl"
                type="file"
                accept=".csv"
              />
              <label htmlFor="csvfpl" className={styles.butts2}>
                Upload Players CSV
              </label>
              <div
                onClick={() => {
                  setOpenSPModal(true);
                }}
                className={styles.butts2}
              >
                Add Player
              </div>
              <div
                onClick={handleOpenAllMailPlayerModal}
                className={styles.butts2}
              >
                Send Link To All
              </div>
              <div
                onClick={handleOpenDeleteAllPlayersModal}
                style={{ background: "#b20000" }}
                className={styles.butts2}
              >
                Remove All Players
              </div>
            </div>
            <Paper
              sx={{
                width: "70%",
                marginLeft: "15%",
                marginBottom: "30px",
                overflow: "hidden",
              }}
            >
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {playerColumns.map((column, i) => (
                        <TableCell
                          key={i}
                          style={{
                            fontFamily: "Poppins",
                            letterSpacing: "1px",
                            fontWeight: "bold",
                            textAlign: "center",
                            backgroundColor: "#4C4CB7",
                            color: "white",
                          }}
                        >
                          {column.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allGamePlayers
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((player, i) => {
                        return (
                          <TableRow key={i} hover tabIndex={-1}>
                            {playerColumns.map((column, j) => {
                              const value = player[column.id];
                              return (
                                <TableCell
                                  key={j}
                                  align={"center"}
                                  style={{
                                    fontFamily: "Poppins",
                                    textAlign: "center",
                                    position: "relative",
                                  }}
                                >
                                  {column.id === "delete" ? (
                                    <IconButton
                                      onClick={() => {
                                        handleOpenDeletePlayerModal(player);
                                      }}
                                      aria-label="delete"
                                    >
                                      <Delete />
                                    </IconButton>
                                  ) : column.id === "start_time" ? (
                                    format(value, "do MMM u")
                                  ) : column.id === "sendMail" ? (
                                    <IconButton
                                      onClick={() => {
                                        handleOpenMailPlayerModal(player);
                                      }}
                                      aria-label="delete"
                                    >
                                      <Email />
                                    </IconButton>
                                  ) : column.id === "start_time" ? (
                                    format(value, "do MMM u")
                                  ) : (
                                    value
                                  )}
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 20]}
                component="div"
                count={allGamePlayers?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
            <div className={styles.buttsContainer}>
              <div onClick={handleEdit} className={styles.butts}>
                Save Changes
              </div>
              <div
                onClick={() => {
                  setOpen(false);
                }}
                className={styles.butts}
              >
                Cancel
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapsibleScheduledRow;
