import {
  Box,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { format } from "date-fns";
import styles from "../styles/CollapsibleOngoingRow.module.css";
import PlayerDataTable from "./PlayerDataTable";

const CollapsibleOngoingRow = ({ columns, row,open,setOpen,allGamePlayers,getGamePlayers }) => { 

  return (
    <>
      <TableRow hover tabIndex={-1}>
        {columns.map((column, j) => {
          const value = row[column.id];
          return (
            <TableCell
              key={j}
              align={"center"}
              style={{
                fontFamily: "Poppins",
                textAlign: "center",
                position: "relative",
              }}
            >
              {column.id === "status" ? (
                <div className={styles.outerLive}>
                  <div className={styles.liveContainer}>
                    <div
                      className={styles.liveCircle}
                      style={{ animationDelay: "-3s" }}
                    ></div>
                    <div
                      className={styles.liveCircle}
                      style={{ animationDelay: "-2s" }}
                    ></div>
                    <div
                      className={styles.liveCircle}
                      style={{ animationDelay: "-1s" }}
                    ></div>
                    <div
                      className={styles.liveCircle}
                      style={{ animationDelay: "0s" }}
                    ></div>
                  </div>
                </div>
              ) : column.id === "#" ? (
                <>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      if(open === row.id) {
                        setOpen("")
                      } else {
                        setOpen(row.id)
                      }
                    }}
                  >
                    {open === row.id ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </>
              ) : column.id === "start_time" ? (
                format(value, "do MMM u")
              ) : (
                value
              )}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell sx={{borderBottom: '1px solid black',backgroundColor: "#eeeeee"}} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open === row.id} timeout="auto" unmountOnExit>
            <Box >
            <div className={styles.timesFlex}>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h5"
                fontWeight={"bold"}
                letterSpacing={"1px"}
                fontFamily={"Fjalla"}
                sx={{ margin: "2%",marginTop: "20px"}}
              >
                Start Time : {format(row.start_time,"do MMM  u , hh:mm b")}
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h5"
                fontWeight={"bold"}
                letterSpacing={"1px"}
                fontFamily={"Fjalla"}
                sx={{ margin: "2%", marginTop: "20px"}}
              >
                End Time : {format(row.end_time,"do MMM  u , hh:mm b")}
              </Typography>
            </div>
            <Typography
                id="modal-modal-title"
                variant="h4"
                component="h4"
                fontWeight={"bold"}
                letterSpacing={"1px"}
                fontFamily={"Fjalla"}
                sx={{ margin: "2%",textAlign: 'center'}}
              >
                Current Standings
              </Typography>
            <PlayerDataTable row={row} allGamePlayers={allGamePlayers} getGamePlayers={getGamePlayers}/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapsibleOngoingRow;
