import { PIPELINE_PLACEMENT_POSX, PIPELINE_PLACEMENT_POSY } from "./dimensions";
import Phaser from "phaser";

const config = {
    width: 1200 * devicePixelRatio,
    height: 700 * devicePixelRatio,
  };

export const mainPath = new Phaser.Curves.Path(
    config.width / 2 - PIPELINE_PLACEMENT_POSX,
    config.height / 2 - PIPELINE_PLACEMENT_POSY
  );
  mainPath.lineTo(
    config.width / 2 +
      PIPELINE_PLACEMENT_POSX -
      20 * devicePixelRatio,
    config.height / 2 - PIPELINE_PLACEMENT_POSY
  );
  mainPath.lineTo(
    config.width / 2 +
      PIPELINE_PLACEMENT_POSX -
      20 * devicePixelRatio,
    config.height / 2 - 35 * devicePixelRatio
  );
  mainPath.lineTo(
    config.width / 2 -
      PIPELINE_PLACEMENT_POSX +
      20 * devicePixelRatio,
    config.height / 2 - 35 * devicePixelRatio
  );
  mainPath.lineTo(
    config.width / 2 -
      PIPELINE_PLACEMENT_POSX +
      20 * devicePixelRatio,
    config.height / 2 + 117 * devicePixelRatio
  );
  mainPath.lineTo(
    config.width / 2 + PIPELINE_PLACEMENT_POSX,
    config.height / 2 + 117 * devicePixelRatio
  );

  export const dynamicPath = (remaining) => {
    let obj = {
      x: 0,
      y: 0,
      done: false
    }
    if(remaining > 45) {
      obj.x = config.width / 2 - PIPELINE_PLACEMENT_POSX;
      obj.y = config.height / 2 - PIPELINE_PLACEMENT_POSY;
      obj.path = mainPath;
      obj.done = false;
    } else if(remaining > 30) {
      obj.x = config.width / 2;
      obj.y = config.height / 2 - PIPELINE_PLACEMENT_POSY;
      obj.done = false;
      const path30 = new Phaser.Curves.Path(
        config.width / 2,
        config.height / 2 - PIPELINE_PLACEMENT_POSY
      );
      path30.lineTo(
        config.width / 2 +
          PIPELINE_PLACEMENT_POSX -
          20 * devicePixelRatio,
        config.height / 2 - PIPELINE_PLACEMENT_POSY
      );
      path30.lineTo(
        config.width / 2 +
          PIPELINE_PLACEMENT_POSX -
          20 * devicePixelRatio,
        config.height / 2 - 35 * devicePixelRatio
      );
      path30.lineTo(
        config.width / 2 -
          PIPELINE_PLACEMENT_POSX +
          20 * devicePixelRatio,
        config.height / 2 - 35 * devicePixelRatio
      );
      path30.lineTo(
        config.width / 2 -
          PIPELINE_PLACEMENT_POSX +
          20 * devicePixelRatio,
        config.height / 2 + 117 * devicePixelRatio
      );
      path30.lineTo(
        config.width / 2 + PIPELINE_PLACEMENT_POSX,
        config.height / 2 + 117 * devicePixelRatio
      );
      obj.path = path30;
    }else if(remaining > 15) {
      obj.x = config.width / 2;
      obj.y = config.height / 2 - 35 * devicePixelRatio;
      obj.done = false;
      const path15 = new Phaser.Curves.Path(
        config.width / 2,
        config.height / 2 - 35 * devicePixelRatio
      );
      path15.lineTo(
        config.width / 2 -
          PIPELINE_PLACEMENT_POSX +
          20 * devicePixelRatio,
        config.height / 2 - 35 * devicePixelRatio
      );
      path15.lineTo(
        config.width / 2 -
          PIPELINE_PLACEMENT_POSX +
          20 * devicePixelRatio,
        config.height / 2 + 117 * devicePixelRatio
      );
      path15.lineTo(
        config.width / 2 + PIPELINE_PLACEMENT_POSX,
        config.height / 2 + 117 * devicePixelRatio
      );
      obj.path = path15;

    } else if(remaining > 0) {

      obj.x = config.width / 2;
      obj.y = config.height / 2 + 117 * devicePixelRatio;
      obj.done = false;
      const path0 = new Phaser.Curves.Path(
        config.width / 2,
        config.height / 2 + 117 * devicePixelRatio
      );
      path0.lineTo(
        config.width / 2 + PIPELINE_PLACEMENT_POSX,
        config.height / 2 + 117 * devicePixelRatio
      );
      obj.path = path0;

    } else {
      obj.x = config.width / 2 + PIPELINE_PLACEMENT_POSX;
      obj.y = config.height / 2 + 117 * devicePixelRatio;
      obj.done = true;
      obj.path = "";
    }
    
    return obj;
  }

  export const fromCurrPath = (pX,pY) => {
    const currPath = new Phaser.Curves.Path(
      pX,
      pY
    );
    if(pX >= config.width / 2 - PIPELINE_PLACEMENT_POSX && pX <= config.width / 2 + PIPELINE_PLACEMENT_POSX - 20*devicePixelRatio && pY === config.height / 2 - PIPELINE_PLACEMENT_POSY) {
        currPath.lineTo(
          config.width / 2 +
            PIPELINE_PLACEMENT_POSX -
            20 * devicePixelRatio,
          config.height / 2 - PIPELINE_PLACEMENT_POSY
        );
        currPath.lineTo(
          config.width / 2 +
            PIPELINE_PLACEMENT_POSX -
            20 * devicePixelRatio,
          config.height / 2 - 35 * devicePixelRatio
        );
        currPath.lineTo(
          config.width / 2 -
            PIPELINE_PLACEMENT_POSX +
            20 * devicePixelRatio,
          config.height / 2 - 35 * devicePixelRatio
        );
        currPath.lineTo(
          config.width / 2 -
            PIPELINE_PLACEMENT_POSX +
            20 * devicePixelRatio,
          config.height / 2 + 117 * devicePixelRatio
        );
        currPath.lineTo(
          config.width / 2 + PIPELINE_PLACEMENT_POSX,
          config.height / 2 + 117 * devicePixelRatio
        );
    } else if(pX === config.width / 2 + PIPELINE_PLACEMENT_POSX - 20 * devicePixelRatio && pY >= config.height / 2 - PIPELINE_PLACEMENT_POSY && pY <= config.height / 2 - 35*devicePixelRatio) {
      currPath.lineTo(
        config.width / 2 +
          PIPELINE_PLACEMENT_POSX -
          20 * devicePixelRatio,
        config.height / 2 - 35 * devicePixelRatio
      );
      currPath.lineTo(
        config.width / 2 -
          PIPELINE_PLACEMENT_POSX +
          20 * devicePixelRatio,
        config.height / 2 - 35 * devicePixelRatio
      );
      currPath.lineTo(
        config.width / 2 -
          PIPELINE_PLACEMENT_POSX +
          20 * devicePixelRatio,
        config.height / 2 + 117 * devicePixelRatio
      );
      currPath.lineTo(
        config.width / 2 + PIPELINE_PLACEMENT_POSX,
        config.height / 2 + 117 * devicePixelRatio
      );
    } if(pX >= config.width / 2 - PIPELINE_PLACEMENT_POSX + 20 * devicePixelRatio && pX <= config.width / 2 + PIPELINE_PLACEMENT_POSX - 20*devicePixelRatio && pY === config.height / 2 - 35*devicePixelRatio) {
      currPath.lineTo(
        config.width / 2 -
          PIPELINE_PLACEMENT_POSX +
          20 * devicePixelRatio,
        config.height / 2 - 35 * devicePixelRatio
      );
      currPath.lineTo(
        config.width / 2 -
          PIPELINE_PLACEMENT_POSX +
          20 * devicePixelRatio,
        config.height / 2 + 117 * devicePixelRatio
      );
      currPath.lineTo(
        config.width / 2 + PIPELINE_PLACEMENT_POSX,
        config.height / 2 + 117 * devicePixelRatio
      );
    } else if(pX === config.width / 2 - PIPELINE_PLACEMENT_POSX + 20 * devicePixelRatio && pY >= config.height / 2 - 35*devicePixelRatio && pY <= config.height / 2 + 117*devicePixelRatio) {
      currPath.lineTo(
        config.width / 2 -
          PIPELINE_PLACEMENT_POSX +
          20 * devicePixelRatio,
        config.height / 2 + 117 * devicePixelRatio
      );
      currPath.lineTo(
        config.width / 2 + PIPELINE_PLACEMENT_POSX,
        config.height / 2 + 117 * devicePixelRatio
      );
    } if(pX >= config.width / 2 - PIPELINE_PLACEMENT_POSX + 20 * devicePixelRatio && pX <= config.width / 2 + PIPELINE_PLACEMENT_POSX - 20*devicePixelRatio && pY === config.height / 2 + 117*devicePixelRatio) {
      currPath.lineTo(
        config.width / 2 + PIPELINE_PLACEMENT_POSX,
        config.height / 2 + 117 * devicePixelRatio
      );
    } 
    

    return currPath;
  }