import { DICE_END_POSY, MARKOPPHUR_DICE_END_POSX, PROJECT_DICE_END_POSX } from "../helpers/dimensions";
import Phaser from 'phaser';

export class Dice extends Phaser.GameObjects.Container {
    constructor(settings) {

        // Making the Die
        const diceMsh = settings.scene.add.mesh(0,0,settings.diceName);
        // diceMsh.postFX.addShadow(-5, 5, 0.006, 1, 0x111111, 10, .2);  
        diceMsh.addVerticesFromObj("dice-obj", 0.25);
        diceMsh.panZ(2);
        diceMsh.setScale(0.2)
        diceMsh.modelRotation.x = Phaser.Math.DegToRad(0);
        diceMsh.modelRotation.y = Phaser.Math.DegToRad(-90);
        diceMsh.modelRotation.z = Phaser.Math.DegToRad(20);

        // Constructor of Phaser.GameObjects.Container
        super(settings.scene,settings.diceName === 'projectDice' ? settings.playSceneConfig.width / 2 + PROJECT_DICE_END_POSX : settings.playSceneConfig.width / 2 + MARKOPPHUR_DICE_END_POSX,settings.playSceneConfig.height / 2 + DICE_END_POSY,[diceMsh]);

        // Adding Die to the Parent Scene
        settings.scene.add.existing(this);
        
        // Data Members
        this.parentScene = settings.scene
        this.config = settings.playSceneConfig
        this.rolled = false;
        this.isRolling = false;
        this.name = settings.diceName;
        this.diceMesh = diceMsh;
        if(this.name === 'projectDice') {
            this.setPosition(this.config.width / 2 - 55 * devicePixelRatio, this.config.height - 100* devicePixelRatio)
        } else {
            this.setPosition(this.config.width / 2 + 55 * devicePixelRatio, this.config.height - 100* devicePixelRatio)
        }
    }

    // Randomizes the Position of the Die when it falls

    // Throws the Die onto the Table
    throwDice() {
        this.setDepth(101);
        this.diceMesh.modelRotation.x = Phaser.Math.DegToRad(Phaser.Math.Between(-90,90));
        this.diceMesh.modelRotation.y = Phaser.Math.DegToRad(Phaser.Math.Between(-90,90));
        this.diceMesh.alpha = 0;
        this.diceMesh.scale = 1;
        const diceRoll = Phaser.Math.Between(1,6);
        // const diceRoll = 6
        this.isRolling = true;
        this.parentScene.add.tween({
            targets: this.diceMesh,
            from: 0,
            to: 1,
            duration: 1500,
            onComplete: () => {
                this.isRolling = false;
                switch (diceRoll) {
                    case 1:
                        this.diceMesh.modelRotation.x = Phaser.Math.DegToRad(0);
                        this.diceMesh.modelRotation.y = Phaser.Math.DegToRad(-90);
                        this.diceMesh.modelRotation.z = Phaser.Math.DegToRad(20);
                        break;
                    case 2:
                        this.diceMesh.modelRotation.x = Phaser.Math.DegToRad(70);
                        this.diceMesh.modelRotation.y = Phaser.Math.DegToRad(0);
                        this.diceMesh.modelRotation.z = Phaser.Math.DegToRad(0);
                        break;
                    case 3:
                        this.diceMesh.modelRotation.x = Phaser.Math.DegToRad(160);
                        this.diceMesh.modelRotation.y = Phaser.Math.DegToRad(0);
                        this.diceMesh.modelRotation.z = Phaser.Math.DegToRad(0);
                        break;
                    case 4:
                        this.diceMesh.modelRotation.x = Phaser.Math.DegToRad(200);
                        this.diceMesh.modelRotation.y = Phaser.Math.DegToRad(180);
                        this.diceMesh.modelRotation.z = Phaser.Math.DegToRad(0);
                        break;
                    case 5:
                        this.diceMesh.modelRotation.x = Phaser.Math.DegToRad(-110);
                        this.diceMesh.modelRotation.y = Phaser.Math.DegToRad(0);
                        this.diceMesh.modelRotation.z = Phaser.Math.DegToRad(0);
                        break;
                    case 6:
                        this.diceMesh.modelRotation.x = Phaser.Math.DegToRad(0);
                        this.diceMesh.modelRotation.y = Phaser.Math.DegToRad(90);
                        this.diceMesh.modelRotation.z = Phaser.Math.DegToRad(-20);
                        break;
                    default:
                }
                setTimeout(()=>{this.throwDice()},2000);
                
            },
            ease: Phaser.Math.Easing.Back.In
        });

        this.parentScene.add.tween({
            targets: [this.diceMesh],
            scale: 0.2,
            alpha: 2,
            duration: 1700,
            ease: Phaser.Math.Easing.Bounce.Out,
        });
    }
} 