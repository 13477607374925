import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";

const PlayerDataTable = ({row,allGamePlayers,getGamePlayers}) => {

  const columns = [
    { id: "rank", label: "Rank" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    {
      id: "mobile",
      label: "Mobile",
    },
    {
      id: "people",
      label: "People",
    },
    {
        id: "connections",
        label: "Connections",
    },
    {
      id: "cash",
      label: "Cash",
    },
    
  ];

  

  useEffect(()=>{
    getGamePlayers(row.id);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{width: "100%", overflow: "hidden",marginBottom: '50px' }}>
      <TableContainer sx={{}}>
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((column, i) => (
                <TableCell
                  key={i}
                  style={{
                    fontFamily: "Poppins",
                    letterSpacing: "1px",
                    fontWeight: "bold",
                    textAlign: "center",
                    backgroundColor: "rgb(76, 76, 183)",
                    color: "white",
                  }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {allGamePlayers
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, i) => {
                return (
                    <TableRow key={i} hover tabIndex={-1}>
                    {columns.map((column, j) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={j}
                          align={"center"}
                          style={{
                            fontFamily: "Poppins",
                            textAlign: "center",
                            position: "relative",
                          }}
                        >
                          {column.id === 'rank' ? i + 1 : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20]}
        component="div"
        count={allGamePlayers?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default PlayerDataTable;
