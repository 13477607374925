import Phaser from 'phaser';

export class BaseScene extends Phaser.Scene
{
    constructor (key,config)
    {
        super(key);
        this.config = config;
    }

    create() {
        
    }

}
