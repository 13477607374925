import Phaser from 'phaser';
import cashPanel from '../assets/cashBox.png';
import longBox from '../assets/longBox.png';
import tokenPanel from '../assets/tokenBox.png';
import darkness from '../assets/darkenFilter.png';
import hurdleCards from '../assets/Hurdles.png'
import opportunityCards from '../assets/Opportunities.png'
import fullscreen from '../assets/fullscreen.png';
import windowed from '../assets/windowed.png';
import pipeline from '../assets/Pipeline3.png';
import pipelineNoRevenue from '../assets/PipelineNoRevenue.png';
import diceAlbedo from '../assets/rebuilt.png';
import diceAlbedo2 from '../assets/maropphur.png';
import diceObj from '../assets/dice.obj';
import projectCards from '../assets/Projects2.png';
import radio from '../assets/radio.png';
import cross from '../assets/cross.png';
import longBoxCross from '../assets/longBoxCross.png';
import lights from '../assets/lights.png';
import cardBack from '../assets/backsideCards.png';
import cardBackSmall from '../assets/backsideCardsSmall.png';
import normal from '../assets/counter.mp3';
import final from '../assets/final.mp3';
import buttons from '../assets/Buttons.png';
import tokens from '../assets/tokens.png';
import money from '../assets/money.png';
import selected from '../assets/selected.png';
import boot from '../assets/boot.png';
import snail from '../assets/snail.png';
export class Preload extends Phaser.Scene
{
    constructor ()
    {
        super();
        this.bottomlineAssets = {
            'background' : 'images/gradient.png',
            'menuBackground' : 'images/menuBg2.png',
            'darkness' : darkness,
            'cashPanel' : cashPanel,
            'tokenPanel' : tokenPanel,
            'projectPanel' : longBox,
            'opportunityPanel' : longBox,
            'fullscreen' : fullscreen,
            'windowed' : windowed,
            'projectDice' : diceAlbedo,
            'marOppHurDice' : diceAlbedo2,
            'radio' : radio,
            'cross' : cross,
            'longBoxCross' : longBoxCross,
            'selected' : selected,
            "boot" : boot,
            "snail" : snail
        }
    }

    preload ()
    {    
        for(let key of Object.keys(this.bottomlineAssets)) {
            this.load.image(key,this.bottomlineAssets[key]);
        }
        this.load.obj('dice-obj',diceObj);
        this.load.spritesheet('projectCard',projectCards,{frameWidth: 682.666,frameHeight: 439});
        this.load.spritesheet('hurdleCard',hurdleCards,{frameWidth: 682.666,frameHeight: 439.2});
        this.load.spritesheet('opportunityCard',opportunityCards,{frameWidth: 682.666,frameHeight: 439.2});
        this.load.spritesheet('pipeline',pipeline,{frameWidth: 600,frameHeight: 400});
        this.load.spritesheet('pipelineNoRevenue',pipelineNoRevenue,{frameWidth: 600,frameHeight: 400});
        this.load.spritesheet('lights',lights,{frameWidth: 603,frameHeight: 159});
        this.load.spritesheet('cardBack',cardBack,{frameWidth: 686,frameHeight: 442});
        this.load.spritesheet('buttons',buttons,{frameWidth: 300,frameHeight: 100});
        this.load.spritesheet('cardBackSmall',cardBackSmall,{frameWidth: 140,frameHeight: 90});
        this.load.spritesheet('tokens',tokens,{frameWidth: 200,frameHeight: 200});
        this.load.spritesheet('money',money,{frameWidth: 1920,frameHeight: 760});
        this.load.audio('normal',normal);
        this.load.audio('final',final);
    }

    create() {
        this.scene.start('WaitingScene');
    }

}
