import React, { useContext } from 'react'
import Navbar from '../components/Navbar'
import room from '../assets/room.png';
import ongoing from '../assets/ongoing.png';
import administrator from '../assets/administrator.png';
import history from '../assets/history.png';
import RoundedBox from '../components/RoundedBox';
import styles from "../styles/Dashboard.module.css";
import ScheduledRooms from '../components/ScheduledRooms';
import { UserContext } from '../App';

const Dashboard = () => {

  const {user} = useContext(UserContext);

  const boxes = [
    {image: room, text: "Create Game", path: "/sat-administrator/create-room", admin : true},
    {image: ongoing, text: "Ongoing Games", path: "/sat-administrator/ongoing",admin : true},
    {image: administrator, text: "Admin Functions", path: "/sat-administrator/admin-functions",admin : false},
    {image: history, text: "History", path: "/sat-administrator/history",admin : true},
  ]

  return (
    <>
      <Navbar selected="dashboard" />
      <div className={styles.mainDashboard}> 
        <div className={styles.mainBoxes}>
          {boxes.map((box,i)=>{
              return(
                (box.admin === true || user.role === "Super Admin") && <RoundedBox key={i} {...box} />
              );
          })}
        </div>
        <ScheduledRooms />
          
      </div>
    </>
  )
}

export default Dashboard