import { alterProjects, updateCash, updateOpportunity, updateTokens } from "../apis/put";
import {
  calculateDenomination,
  getMoney,
  moneyFrameMap,
} from "../helpers/calculations";
import {
  DEV_DPI_RATIO,
  LONG_BOX_WIDTH,
  PANEL_POS,
  SMALL_BOX_HEIGHT,
  SMALL_BOX_WIDTH,
  UPCOMING_CARD_HEIGHT,
  UPCOMING_CARD_WIDTH,
} from "../helpers/dimensions";
import { Button } from "./Button";
import { Money } from "./Money";
import { Token } from "./Token";
import Phaser from "phaser";

export class Opportunity extends Phaser.GameObjects.Sprite {
  constructor(settings) {
    // Constructor of Phaser.GameObjects.Sprite
    super(
      settings.scene,
      settings.x,
      settings.y,
      settings.texture,
      settings.frame
    );

    // Adding Opportunity to the Parent Scene and setting some of its properties
    settings.scene.add.existing(this);
    this.setOrigin(0.5, 0.5)
      .setDepth(101)
      .setDisplaySize(UPCOMING_CARD_WIDTH, UPCOMING_CARD_HEIGHT);

    // Data Members
    this.code = settings.code;
    this.id = settings.id;
    this.parentScene = settings.scene;
    this.config = settings.playSceneConfig;
    this.action = settings.action;
    this.tokens = settings.tokens;
    this.money = settings.money;
    this.type = settings.type;
    this.description = settings.description;
    this.flipTexture = settings.flipTexture;
    this.opportunityFrame = settings.flipFrame;
    this.time = settings.time;
  }

  spawn() {
    this.parentScene.add.tweenchain({
      targets: this,
      tweens: [
        {
          scale: 0.8 * DEV_DPI_RATIO,
          duration: 400,
          ease: Phaser.Math.Easing.Back.Out,
        },
        {
          scaleY: 0.0001,
          duration: 200,
          onComplete: () => {
            this.setTexture(this.flipTexture);
            this.setFrame(this.opportunityFrame);
          },
        },
        {
          scale: 0.8 * DEV_DPI_RATIO,
          duration: 200,
        },
      ],
      onComplete: () => {
        this.setInteractive({ cursor: "pointer" });
      },
    });
  }

  placeOnPanel(dness) {
    this.parentScene.add.tween({
      targets: dness, 
      alpha: 0,
      duration: 200,
    })

    let opps = [...this.parentScene.opportunities.getChildren()];
    this.parentScene.opportunities.add(this);
    let slotY;

    slotY =
      PANEL_POS + 120 * devicePixelRatio + opps.length * 25 * devicePixelRatio;
    

    let opportunitySpawnTween = this.parentScene.add.tweenchain({
      targets: this,
      tweens: [
        {
          x: this.config.width - LONG_BOX_WIDTH / 2 - PANEL_POS,
          y: slotY,
          scale: 0.38 * DEV_DPI_RATIO,
          duration: 500,
          onComplete: () => {
            this.setDepth(opps.length + 1);
            this.hoverStartY = this.y - 40 * devicePixelRatio;
            this.hoverEndY = this.y;
            this.setInteractive({ cursor: "pointer" });
            this.on("pointerup", () => {
              if (this.parentScene.canView) {
                this.viewOpportunity();
              }
            });
            this.on("pointerover", () => {
              this.parentScene.add.tween({
                targets: this,
                scale: 0.39 * DEV_DPI_RATIO,
                duration: 50,
              });
            });
            this.on("pointerout", () => {
              this.parentScene.add.tween({
                targets: this,
                scale: 0.38 * DEV_DPI_RATIO,
                duration: 50,
              });
            });
          },
        },
      ],
      onComplete: () => {
        opportunitySpawnTween.destroy();
      },
    });
  }

  viewOpportunity() {
    switch (this.action) {
      case "token":
        this.parentScene.tokenPanel.setDepth(101);
        break;
      case "money":
        this.parentScene.cashPanel.setDepth(101);
        break;
      default:
    }

    this.parentScene.canView = false;
    this.removeInteractive();

    this.parentScene.darkness.setAlpha(0.8);
    
    let obj = {
      originalX: this.x,
      originalY: this.y,
      originalScale: 0.38 * DEV_DPI_RATIO,
      originalDepth: this.depth,
    };

    this.useBtn = new Button({
      playSceneConfig: this.config,
      scene: this.parentScene,
      texture: "buttons",
      frame: 2,
      frameActive: 3,
      text: "USE",
      item: this,
      action: this.executeOpportunity,
      x: this.config.width / 2 - 150 * devicePixelRatio,
    });

    this.backBtn = new Button({
      playSceneConfig: this.config,
      scene: this.parentScene,
      texture: "buttons",
      frame: 0,
      frameActive: 1,
      text: "RESERVE",
      item: this,
      metaData: obj,
      action: this.endViewOpportunity,
      x: this.code !== "O34" ? this.config.width / 2 + 150 * devicePixelRatio : this.config.width / 2,
    });

    this.parentScene.viewingSomething = true;

    this.parentScene.viewing = {
      type: "O",
      card: this,
      metaData: obj,
      btn: this.backBtn,
    };
    
    

    this.setDepth(101);

    this.parentScene.add.tween({
      targets: this,
      scale: 1.12 * DEV_DPI_RATIO,
      duration: 400,
      x: this.config.width / 2,
      y: this.config.height / 2 - 56 * devicePixelRatio,
      ease: Phaser.Math.Easing.Sine.Out,
      onComplete: () => {
        if(this.code !== "O34") {
          
          this.useBtn.appear();
        }
        
        this.backBtn.appear();
        
      },
    });
  }

  adjustCards() {
    let opps = [
      ...this.parentScene.opportunities
        .getChildren()
        .filter((opp) => opp !== this),
    ];
    let slotY;

    opps.forEach((opp, i) => {
      if (i === 0) {
        slotY = PANEL_POS + 120 * devicePixelRatio;
      } else {
        slotY = PANEL_POS + 120 * devicePixelRatio + 25 * devicePixelRatio * i;
      }
      opp.setDepth(i + 1);
      this.parentScene.add.tween({
        targets: opp,
        y: slotY,
        duration: 200,
      });
    });
  }

  endViewOpportunity(metaData, btn) {
    this.parentScene.viewingSomething = false;
    let { originalX, originalY, originalScale, originalDepth } = metaData;

    if(this.code !== "O34") {
      this.parentScene.add.tween({
        targets: [this.useBtn],
        scale: 0,
        duration: 300,
        onComplete: () => {
          this.useBtn.destroy();
        },
      });
    }

    this.parentScene.add.tween({
      targets: this,
      scale: originalScale,
      duration: 300,
      x: originalX,
      y: originalY,
      ease: Phaser.Math.Easing.Sine.Out,
      onComplete: () => {
        this.setDepth(originalDepth);
        this.parentScene.tokenPanel.setDepth(30);
        this.parentScene.cashPanel.setDepth(30);
        this.parentScene.canView = true;
        this.setInteractive({ cursor: "pointer" });
      },
    });

    this.parentScene.add.tween({
      targets: this.parentScene.darkness,
      alpha: 0,
      duration: 400,
    });

    this.parentScene.add.tween({
      targets: btn,
      scale: 0,
      duration: 300,
      onComplete: () => {
        btn.destroy();
      },
    });
  }

  executeOpportunity(metaData, btn) {
    this.parentScene.viewingSomething = false;
    updateOpportunity(this.id);

    this.parentScene.canView = false;
    this.opportunityChooser();

    this.parentScene.add.tween({
      targets: [btn, this.backBtn],
      scale: 0,
      duration: 300,
      onComplete: () => {
        btn.destroy();
        this.backBtn.destroy();
      },
    });
  }

  opportunityChooser() {
    switch (this.action) {
      case "token":
        if (this.type === "people")
          this.getPeople({ startX: this.x, startY: this.y });
        else
          this.getConnections({
            startX: this.x,
            startY: this.y,
          });
        break;
      case "money":
        this.claimMoney();
        break;

        case "prepone":
            this.preponeProject();
        break;
      default:
    }
  }

  getPeople({ startX, startY }) {
    let pCounter = 0;

    let peopleInterval = setInterval(() => {
      let tkn = new Token({
        texture: "tokens",
        frame: 0,
        playSceneConfig: this.config,
        scene: this.parentScene,
        x: startX,
        y: startY,
        endX:
          this.parentScene.tokenPanel.x -
          SMALL_BOX_WIDTH / 2 -
          48 * devicePixelRatio,
        endY: this.parentScene.tokenPanel.y - SMALL_BOX_HEIGHT / 2,
      });
      tkn.animate();

      this.parentScene.people++;

      this.parentScene.peopleText.setText(this.parentScene.people);

      pCounter++;
      if (pCounter === this.tokens) {
        updateTokens(
          this.parentScene.properties.id,
          this.parentScene.properties.pid,
          this.parentScene.people,
          this.parentScene.connections
        );
        clearInterval(peopleInterval);
        this.parentScene.add.tween({
          targets: this,
          scale: 0,
          duration: 600,
          ease: Phaser.Math.Easing.Back.In,
          onComplete: () => {
            this.adjustCards();
            this.parentScene.add.tween({
              targets: this.parentScene.darkness,
              alpha: 0,
              duration: 200,
              onComplete: () => {
                this.destroy();

                this.parentScene.canView = true;
                this.parentScene.tokenPanel.setDepth(30);
              },
            });
            // this.parentScene.canRoll = true;
          },
        });
      }
    }, 100);
  }

  getConnections({ startX, startY }) {
    let cCounter = 0;
    let connectionsInterval = setInterval(() => {
      let tkn = new Token({
        texture: "tokens",
        frame: 1,
        playSceneConfig: this.config,
        scene: this.parentScene,
        x: startX,
        y: startY,
        endX: this.parentScene.tokenPanel.x - 40 * devicePixelRatio,
        endY: this.parentScene.tokenPanel.y - SMALL_BOX_HEIGHT / 2,
      });
      tkn.animate();
      this.parentScene.connections++;

      this.parentScene.connectionsText.setText(this.parentScene.connections);
      cCounter++;

      if (cCounter === this.tokens) {
        updateTokens(
          this.parentScene.properties.id,
          this.parentScene.properties.pid,
          this.parentScene.people,
          this.parentScene.connections
        );
        clearInterval(connectionsInterval);
        this.parentScene.add.tween({
          targets: this,
          scale: 0,
          duration: 600,
          ease: Phaser.Math.Easing.Back.In,
          onComplete: () => {
            this.adjustCards();
            this.parentScene.add.tween({
              targets: this.parentScene.darkness,
              alpha: 0,
              duration: 200,
              onComplete: () => {
                this.destroy();
                this.parentScene.canView = true;
                this.parentScene.tokenPanel.setDepth(30);
              },
            });
          },
        });
      }
    }, 100);
  }

  claimMoney() {
    let notes = getMoney(this.money);
    let noteCounter = 0;
    let moneyInterval = setInterval(() => {
      let mon = new Money({
        texture: "money",
        frame: moneyFrameMap[notes[noteCounter]],
        playSceneConfig: this.config,
        scene: this.parentScene,
        x: this.x,
        y: this.y,
        endX: PANEL_POS + SMALL_BOX_WIDTH / 2,
        endY: this.config.height - SMALL_BOX_HEIGHT / 2 - PANEL_POS,
      }).setDepth(101);
      mon.get();
      this.parentScene.cash += notes[noteCounter];
      this.parentScene.cashAmountText.setText(
        calculateDenomination(this.parentScene.cash)
      );
      noteCounter++;
      if (noteCounter === notes.length) {
        updateCash(
          this.parentScene.properties.id,
          this.parentScene.properties.pid,
          this.parentScene.cash
        );
        clearInterval(moneyInterval);
        this.parentScene.add.tween({
          targets: this,
          scale: 0,
          duration: 600,
          delay: 1000,
          ease: Phaser.Math.Easing.Back.In,
          onComplete: () => {
            this.adjustCards();
            this.parentScene.add.tween({
              targets: this.parentScene.darkness,
              alpha: 0,
              duration: 200,
              onComplete: () => {
                this.destroy();
                this.parentScene.canView = true;
                this.parentScene.cashPanel.setDepth(30);
              },
            });
            // this.parentScene.canRoll = true;
          },
        });
      }
    }, 200);
  }

  async preponeProject() {
    await alterProjects(this.time);
    this.parentScene.allProjectsAlterPathTime(this.time);
    this.parentScene.add.tween({
      targets: this,
      scale: 0,
      duration: 600,
      ease: Phaser.Math.Easing.Back.In,
      onComplete: () => {
        this.adjustCards();
        this.parentScene.add.tween({
          targets: this.parentScene.darkness,
          alpha: 0,
          duration: 200,
          onComplete: () => {
            this.destroy();
            this.parentScene.canView = true;
            this.parentScene.tokenPanel.setDepth(30);
          },
        });
      },
    });
  }
}
