import {
  Alert,
  AlertTitle,
  Box,
  Snackbar,
  Typography,
} from "@mui/material";
import styles from "../styles/RoomCode.module.css";
import { useContext, useEffect, useState } from "react";
import { LoadingContext, SocketContext } from "../App";
import { MuiOtpInput } from "mui-one-time-password-input";
import axios from "axios";
import Back2 from "./Back2";

const RoomCode = ({setCorrectCode,setChosenOption}) => {
  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, 50%)",
    width: "70%",
    backgroundColor: "#FFFFFF",
    border: "2px solid #000",
    boxShadow: 24,
    outline: "none",
    p: 4,
  };

  const { socket } = useContext(SocketContext);
  const { setIsLoading } = useContext(LoadingContext);
  const [roomCode, setRoomCode] = useState("");
  const [roomCodeError, setRoomCodeError] = useState(false);

  const handleRoomCode = (newValue) => {
    setRoomCode(newValue);
  };

  const validateChar = (value, index) => {
    return !isNaN(value);
  };

  const verifyRoomCode = async (e) => {
    e.preventDefault();
    let params = new URLSearchParams(window.location.search);
    const obj = { game: params.get("game"), pid: params.get("pl"), code: roomCode, s: socket.id };
    setIsLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_URL}games/roomcode`, obj)
      .then((response) => {
        if(response.data.valid) {
          setCorrectCode(true);
        } else {
          setRoomCodeError(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setIsLoading(false);
  };

  const [gameName,setGameName] = useState();

  const getGameName = () => {
    let params = new URLSearchParams(window.location.search);
    axios
      .get(`${process.env.REACT_APP_API_URL}games/gameName`, {
        params: {
          id: params.get("game"),
        },
      })
      .then((response) => {
        let temp = { ...response.data.data };
        setGameName(temp.title);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCloseDown = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setRoomCodeError(false);
  };

  useEffect(()=>{
    getGameName();
  },[])

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={roomCodeError}
        autoHideDuration={5000}
        onClose={handleCloseDown}
      >
        <Alert
          onClose={handleCloseDown}
          variant="filled"
          severity="error"
          sx={{ width: "100%" }}
        >
          <AlertTitle style={{ verticalAlign: "top" }}>
            Please enter the correct room code !
          </AlertTitle>
        </Alert>
      </Snackbar>
      <div className={styles["main-forgotten"]}>
        <Box sx={boxStyle}>
          <Back2 setChosenOption={setChosenOption}/>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h4"
            textAlign={"center"}
            fontWeight={"bold"}
            letterSpacing={"2px"}
            fontFamily={"Fjalla"}
            sx={{ marginBottom: "5px" }}
          >
            ROOM CODE
          </Typography>
          <div className={styles.belowInfo}>
            Enter the room code which has been sent on your registered email
            address.
          </div>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h5"
            fontWeight={"bold"}
            letterSpacing={"1px"}
            fontFamily={"Fjalla"}
            sx={{
              textAlign: "center",
              marginTop: "40px",
              textDecoration: "underline",
            }}
          >
            {gameName?.toUpperCase()}
          </Typography>
          <form className={styles.txtflds}>
            <MuiOtpInput
              length={4}
              validateChar={validateChar}
              value={roomCode}
              onChange={handleRoomCode}
            />
          </form>
          <div onClick={verifyRoomCode} className={styles.butts}>
            Join
          </div>
        </Box>
      </div>
    </>
  );
};

export default RoomCode;
