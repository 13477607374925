import { Box, Collapse, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import React, { useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { format } from "date-fns";
import styles from '../styles/CollapsibleHistoryRow.module.css';
import PlayerDataTable from "./PlayerDataTable";
import axios from "axios";

const CollapsibleHistoryRow = ({columns,row}) => {

  const [open, setOpen] = useState(false);
  const [allGamePlayers, setAllGamePlayers] = useState([]);

  const getGamePlayers = async (id) => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}players/allOfAGame`,{
        params: {
          game: id,
        },
      })
      .then((response) => {
        setAllGamePlayers(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <TableRow hover tabIndex={-1}>
        {columns.map((column, j) => {
          const value = row[column.id];
          return (
            <TableCell
              key={j}
              align={"center"}
              style={{
                fontFamily: "Poppins",
                textAlign: "center",
                position: "relative",
              }}
            >
              {column.id === "#" ? (
                <>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>
                </>
              ) : column.id === "start_time" ? (
                format(value,"do MMM u")
              ): value}
            </TableCell>
          );
        })}
      </TableRow>
      <TableRow>
        <TableCell sx={{borderBottom: '1px solid black',backgroundColor: "#eeeeee"}} style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box >
            <div className={styles.timesFlex}>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h5"
                fontWeight={"bold"}
                letterSpacing={"1px"}
                fontFamily={"Fjalla"}
                sx={{ margin: "2%",marginTop: "20px"}}
              >
                Start Time : {format(row.start_time,"do MMM  u , hh:mm b")}
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h5"
                component="h5"
                fontWeight={"bold"}
                letterSpacing={"1px"}
                fontFamily={"Fjalla"}
                sx={{ margin: "2%", marginTop: "20px"}}
              >
                End Time : {format(row.end_time,"do MMM  u , hh:mm b")}
              </Typography>
            </div>
            <Typography
                id="modal-modal-title"
                variant="h4"
                component="h4"
                fontWeight={"bold"}
                letterSpacing={"1px"}
                fontFamily={"Fjalla"}
                sx={{ margin: "2%",textAlign: 'center'}}
              >
                Final Standings
              </Typography>
            <PlayerDataTable allGamePlayers={allGamePlayers} getGamePlayers={getGamePlayers} row={row}/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default CollapsibleHistoryRow;
