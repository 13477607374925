import axios from "axios";

export const getRoomStartTime = async () => {
  
    let temp = [];
    let params = new URLSearchParams(window.location.search);
    await axios
      .get(`${process.env.REACT_APP_API_URL}games/startTime`,{
        params: {
          game: params.get("game"),
          pl: params.get("pl")
        }
      })
      .then((response) => {
        temp = response.data;
      })
      .catch((err) => {
        console.log(err);
      });

      return temp;
};

export const getGameStatus = async () => {
  
  let temp = [];
  let params = new URLSearchParams(window.location.search);
  await axios
    .get(`${process.env.REACT_APP_API_URL}games/status`,{
      params: {
        game: params.get("game"),
        pl: params.get("pl")
      }
    })
    .then((response) => {
      temp = response.data;
    })
    .catch((err) => {
      console.log(err);
    });

    return temp;
};

export const getResources = async () => {
  
  let temp = [];
  let params = new URLSearchParams(window.location.search);
  await axios
    .get(`${process.env.REACT_APP_API_URL}resources/getResources`,{
      params: {
        game: params.get("game"),
        pl: params.get("pl")
      }
    })
    .then((response) => {
      temp = response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });

    return temp;
};

export const getAllOpportunities = async () => {
  
  let temp = [];
  let params = new URLSearchParams(window.location.search);
  await axios
    .get(`${process.env.REACT_APP_API_URL}cards/allReceivedOpportunities`,{
      params: {
        game: params.get("game"),
        pl: params.get("pl")
      }
    })
    .then((response) => {
      temp = response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });

    return temp;
};

export const getAllHurdles = async () => {
  
  let temp = [];
  let params = new URLSearchParams(window.location.search);
  await axios
    .get(`${process.env.REACT_APP_API_URL}cards/allReceivedHurdles`,{
      params: {
        game: params.get("game"),
        pl: params.get("pl")
      }
    })
    .then((response) => {
      temp = response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });

    return temp;
};

export const getAllProjects = async () => {
  
  let temp = [];
  let params = new URLSearchParams(window.location.search);
  await axios
    .get(`${process.env.REACT_APP_API_URL}cards/allReceivedProjects`,{
      params: {
        game: params.get("game"),
        pl: params.get("pl")
      }
    })
    .then((response) => {
      temp = response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });

    return temp;
};

export const getAllInProgressProjects = async () => {
  
  let temp = [];
  let params = new URLSearchParams(window.location.search);
  await axios
    .get(`${process.env.REACT_APP_API_URL}cards/allInProgressProjects`,{
      params: {
        game: params.get("game"),
        pl: params.get("pl")
      }
    })
    .then((response) => {

      temp = response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });

    return temp;
};

export const ifNoRevenueActive = async () => {
  
  let temp;
  let params = new URLSearchParams(window.location.search);
  await axios
    .get(`${process.env.REACT_APP_API_URL}cards/isNoRevenueActive`,{
      params: {
        game: params.get("game"),
        pl: params.get("pl")
      }
    })
    .then((response) => {

      temp = response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });

    return temp;
};

export const ifNoPlayActive = async () => {
  
  let temp;
  let params = new URLSearchParams(window.location.search);
  await axios
    .get(`${process.env.REACT_APP_API_URL}cards/isNoPlayActive`,{
      params: {
        game: params.get("game"),
        pl: params.get("pl")
      }
    })
    .then((response) => {
      temp = response.data.data;
    })
    .catch((err) => {
      console.log(err);
    });

    return temp;
};