import { alterProjects, updateHurdle, updateTokens } from "../apis/put";
import {
  calculateDenomination,
  getMoney,
  moneyFrameMap,
} from "../helpers/calculations";
import {
  DEV_DPI_RATIO,
  PANEL_POS,
  SMALL_BOX_HEIGHT,
  SMALL_BOX_WIDTH,
} from "../helpers/dimensions";
import eventsCenter from "../helpers/eventsCenter";
import { Money } from "./Money";
import { Token } from "./Token";
import Phaser from "phaser";

export class Hurdle extends Phaser.GameObjects.Sprite {
  constructor(settings) {
    // Constructor of Phaser.GameObjects.Sprite
    super(
      settings.scene,
      settings.x,
      settings.y,
      settings.texture,
      settings.frame
    );

    // Adding Hurdle to the Parent Scene and setting some of its properties
    settings.scene.add.existing(this);
    this.setOrigin(0.5, 0.5).setScale(0);

    // Data Members
    this.id = settings.id;
    this.parentScene = settings.scene;
    this.config = settings.playSceneConfig;
    this.action = settings.action;
    this.tokens = settings.tokens;
    this.money = settings.money;
    this.type = settings.type;
    this.description = settings.description;
    this.flipTexture = settings.flipTexture;
    this.hurdleFrame = settings.flipFrame;
    this.time = settings.time;
  }

  // Spawn an Button with a Hurdle Card (Back First) then Flip it to reveal the content
  spawn() {
    this.parentScene.add.tweenchain({
      targets: this,
      tweens: [
        {
          scale: 0.8 * DEV_DPI_RATIO,
          duration: 400,
          ease: Phaser.Math.Easing.Back.Out,
        },
        {
          scaleY: 0.0001,
          duration: 200,
          onComplete: () => {
            this.setTexture(this.flipTexture);
            this.setFrame(this.hurdleFrame);
          },
        },
        {
          scale: 0.8 * DEV_DPI_RATIO,
          duration: 200,
        },
      ],
      onComplete: () => {
        this.setInteractive({ cursor: "pointer" });
      },
    });
  }

  executeHurdle(dness) {
    this.parentScene.add.tween({
      targets: this,
      scale: 0,
      duration: 600,
      ease: Phaser.Math.Easing.Back.In,
      onComplete: () => {
        this.parentScene.add.tween({
          targets: dness,
          alpha: 0,
          duration: 200,
          onComplete: () => {
            this.hurdleChooser();
            this.parentScene.canView = true;
          },
        });
      },
    });
  }

  hurdleChooser() {
    switch (this.action) {
      case "token":
        this.loseTokens();
        break;
      case "noRevenue":
        this.initiateNoRevenue();
        break;
      case "noPlay":
        this.initiateNoPlay();
        break;
      case "delay":
        this.addDelay();
        break;
      default:
    }
  }

  async loseTokens(btn) {
    await new Promise((resolve) => {
      let cntr = this.tokens;
      let tokenInterval = setInterval(() => {
        let tkn;
        if (this.type === "people") {
          if (this.parentScene.people > 0) {
            tkn = new Token({
              texture: "tokens",
              frame: 0,
              playSceneConfig: this.config,
              scene: this.parentScene,
              x:
                this.parentScene.tokenPanel.x -
                SMALL_BOX_WIDTH / 2 -
                48 * devicePixelRatio,
              y: this.parentScene.tokenPanel.y - SMALL_BOX_HEIGHT / 2,
            });
            this.parentScene.people--;
            this.parentScene.peopleText.setText(this.parentScene.people);
            tkn.lose();
          }
        } else {
          if (this.parentScene.connections > 0) {
            tkn = new Token({
              texture: "tokens",
              frame: 1,
              playSceneConfig: this.config,
              scene: this.parentScene,
              x: this.parentScene.tokenPanel.x - 40 * devicePixelRatio,
              y: this.parentScene.tokenPanel.y - SMALL_BOX_HEIGHT / 2,
            });
            this.parentScene.connections--;
            this.parentScene.connectionsText.setText(
              this.parentScene.connections
            );
            tkn.lose();
          }
        }

        cntr--;
        if (cntr === 0) {
          updateTokens(
            this.parentScene.properties.id,
            this.parentScene.properties.pid,
            this.parentScene.people,
            this.parentScene.connections
          );
          updateHurdle(this.id);
          clearInterval(tokenInterval);
          resolve();
        }
      }, 200);

      if (btn !== undefined) {
        this.parentScene.add.tween({
          targets: btn,
          scale: 0,
          duration: 300,
          onComplete: () => {
            btn.destroy();
          },
        });
      }
    });
  }

  async loseMoney(btn) {
    let notes = getMoney(this.money);
    let noteCounter = 0;
    await new Promise((resolve) => {
      let moneyInterval = setInterval(() => {
        let mon = new Money({
          texture: "money",
          frame: moneyFrameMap[notes[noteCounter]],
          playSceneConfig: this.config,
          scene: this.parentScene,
          x: PANEL_POS,
          y: this.config.height - PANEL_POS - SMALL_BOX_HEIGHT,
        });
        mon.lose();
        this.parentScene.cash -= notes[noteCounter];
        this.parentScene.cashAmountText.setText(
          calculateDenomination(this.parentScene.cash)
        );

        noteCounter++;
        if (noteCounter === notes.length) {
          updateHurdle(this.id);
          clearInterval(moneyInterval);
          resolve();
        }
      }, 200);

      if (btn !== undefined) {
        this.parentScene.add.tween({
          targets: btn,
          scale: 0,
          duration: 300,
          onComplete: () => {
            btn.destroy();
          },
        });
      }
    });
  }

  initiateNoRevenue() {
    eventsCenter.emit("noRevenuePlayed");
    updateHurdle(this.id);
    if (!this.parentScene.noRevenueActive) {
      this.parentScene.noRevenueActive = true;
      this.parentScene.noRevenueCountDown.setAlpha(1);
      this.parentScene.pipes.stop("arrows");
      this.parentScene.pipes.setTexture("pipelineNoRevenue");
      this.parentScene.pipes.play("caution");
    }
  }

  initiateNoPlay() {
    eventsCenter.emit("noPlayPlayed");
    updateHurdle(this.id);
    if (!this.parentScene.noPlayActive) {
      this.parentScene.noPlay();
    }
  }

  async addDelay() {
    await alterProjects(this.time);
    this.parentScene.allProjectsAlterPathTime(this.time);
  }

}
