import React from 'react'
import refresh from '../assets/refresh.png';
import styles from '../styles/Refresh.module.css';

const Refresh = ({getAllOngoingGames,open,getGamePlayers}) => {

    const handleRefresh = () => {
        getAllOngoingGames();
        if(open !== "") {
            getGamePlayers(open);
        }
    }
 
  return (
    <div className={styles.mainRefresh} onClick={handleRefresh}>
        <img className={styles.backImg} src={refresh} alt='refresh'/>
        <div className={styles.backText}>REFRESH</div>
    </div>
  )
}

export default Refresh;